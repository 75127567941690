import React from 'react';
import spinner from '../img/Tissue-Flower-Loading.svg';

const Spinner = () => {
  return (
    <div
      style={{
        height: '100vh',
        zIndex: 999999,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100vw',
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        backgroundColor: ' #f3e6e8',
        backgroundImage: 'radial-gradient(#d5d0e5,#f3e6e8)',
      }}
    >
      <img src={spinner} style={{ width: '10rem' }} />
    </div>
  );
};

export default Spinner;
