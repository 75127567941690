import React, { useState } from 'react';
import Base from '../core/Base';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';

import { forgotpassword, authenticate, resetpassword } from '../auth/helper';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import { themeColor } from '../utility/ColorPallete';
import signinBg from '../img/signin.png';
import groupLogo from '../img/groupIcon/circle_translucent.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = (theme) => ({
  root: {
    height: '92vh',
  },
  image: {
    backgroundImage: `url(${signinBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#FFF',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: themeColor.primary,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  links: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: themeColor.primary,
    },
  },
});

const ResetPassword = ({ classes }) => {
  const history = useHistory();
  const { resetKey } = useParams();
  const [values, setValues] = useState({
    email: '',
    error: '', //redirect to user dash
  });

  const { password } = values;

  const handleChange = (key) => (event) => {
    setValues({
      ...values,
      error: '',
      [key]: event.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      error: false,
    });
    if (
      values.password.match(
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,64}$/
      )
    ) {
      toast.error(
        'Enter password which has atleast one capital letter, one small letter, one digit, one charecter and has minimum 8 charecters'
      );
      return null;
    }
    resetpassword({ resetKey, password })
      .then((data) => {
        if (data)
          if (data.error) {
            toast.error(data.error);
            setValues({
              ...values,
              error: data.error,
            });
          } else {
            if (data.success) {
              toast.success('Password Changed Sucessfully');
              setTimeout(() => history.push('/'), 2000);
            }
          }
      })
      .catch((err) => {
        toast.error('Unable to change password');
      });
  };

  return (
    <Base>
      <ToastContainer position="bottom-right" autoClose={5000} />
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar} />
            <Typography component="h1" variant="h5">
              RESET PASSWORD
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                placeholder="Enter Password"
                value={password}
                onChange={handleChange('password')}
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={onSubmit}
              >
                Submit
              </Button>
            </form>
          </div>
        </Grid>
      </Grid>
    </Base>
  );
};

export default withStyles(styles, { withTheme: true })(ResetPassword);
