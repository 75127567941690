import React, { useState } from 'react';
import { isAuthenticated } from '../../auth/helper';
import { addAuthor } from '../helper/adminapicall';

const AddAuthor = ({ reload = undefined, setReload = (f) => f }) => {
  const { user, token } = isAuthenticated();
  const [values, setValues] = useState({
    photo: '',
    name: '',
    tag: '',
    facebook: '',
    instagram: '',
    tweeter: '',
    linkedIn: '',
    error: '',
    loading: false,
    authCreated: false,
    getaRedirect: false,
    formData: new FormData(),
  });

  const {
    photo,
    name,
    tag,
    facebook,
    instagram,
    tweeter,
    linkedIn,
    error,
    loading,
    authCreated,
    formData,
  } = values;

  if (loading) {
    return <h1>Loading</h1>;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    setValues({ ...values, error: '', loading: true });
    addAuthor(user._id, token, formData).then((data) => {
      if (data)
        if (data.error) {
          setValues({ ...values, error: data.error });
        } else {
          setValues({
            ...values,
            photo: '',
            name: '',
            tag: '',
            facebook: '',
            instagram: '',
            tweeter: '',
            linkedIn: '',
            error: '',
            authCreated: true,
            loading: false,
          });
          setReload(!reload);
        }
    });
  };

  const handleChange = (name) => (event) => {
    const value = name === 'photo' ? event.target.files[0] : event.target.value;
    formData.set(name, value);
    setValues({ ...values, [name]: value });
  };
  const successMessage = () => {
    return (
      <div
        className="alert alert-primary "
        style={{ display: authCreated ? '' : 'none' }}
      >
        <h6>{name} Successfully Created</h6>
      </div>
    );
  };
  const errorMessage = () => {
    return (
      <div
        className="alert alert-danger mt-3"
        style={{ display: error ? '' : 'none' }}
      >
        <h4>
          {name} Failed To Create {error}
        </h4>
      </div>
    );
  };

  const createBlogForm = () => {
    return (
      <form>
        {successMessage()}
        {errorMessage()}
        <span>Author Photo</span>
        <div className="form-group">
          <div class="custom-file">
            <input
              onChange={handleChange('photo')}
              type="file"
              name="photo"
              accept="image"
              class="custom-file-input"
              id="inputGroupFile01"
              aria-describedby="inputGroupFileAddon01"
              placeholder="choose a file"
            />
            <label class="custom-file-label" for="inputGroupFile01">
              Upload Author Photo
            </label>
          </div>
        </div>
        <div className="form-group">
          <input
            onChange={handleChange('name')}
            type="text"
            name="name"
            className="form-control"
            placeholder="Author Name"
            value={name}
          />
        </div>
        <div className="form-group">
          <input
            onChange={handleChange('tag')}
            name="tag"
            className="form-control"
            placeholder="About Author"
            value={tag}
          />
        </div>

        <div className="form-group">
          <input
            onChange={handleChange('facebook')}
            type="text"
            name="facebook"
            className="form-control"
            placeholder="Facebook Link"
            value={facebook}
          />
        </div>
        <div className="form-group">
          <textarea
            onChange={handleChange('tweeter')}
            name="tweeter"
            className="form-control"
            placeholder="Twitter Link"
            value={tweeter}
          />
        </div>

        <div className="form-group">
          <textarea
            onChange={handleChange('instagram')}
            name="instagram"
            className="form-control"
            placeholder="instagramgram Link"
            value={instagram}
          />
        </div>

        <div className="form-group">
          <textarea
            onChange={handleChange('linkedIn')}
            name="linkedIn"
            className="form-control"
            placeholder="LinkedIn Link"
            value={linkedIn}
          />
        </div>

        <button
          type="submit"
          onClick={onSubmit}
          className="btn btn-outline-primary mb-3 mx-auto"
        >
          Add name
        </button>
      </form>
    );
  };

  return (
    <div className="card py-3">
      <h3 className="text-center">Add name</h3>
      <div className="row text-dark rounded">
        <div className="col-md-8 offset-md-2">{createBlogForm()}</div>
      </div>
    </div>
  );
};

export default AddAuthor;
