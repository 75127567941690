import React, { useState, useEffect } from 'react';
import {
  getAllCategories,
  createProduct,
  getAllBrands,
  getAllSubtags,
} from './helper/adminapicall';
import { isAuthenticated } from '../auth/helper';
import Dashboard from './Dashboard';
import { WithContext as ReactTags } from 'react-tag-input';
import { ToastContainer, toast } from 'react-toastify';

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const AddProduct = () => {
  const { user, token } = isAuthenticated();
  const [keywords, setKeywords] = useState([]);
  const [values, setValues] = useState({
    name: '',
    description: '',
    price: '',
    keywords: [],
    stock: '',
    hsn: '',
    weight: '',
    height: '',
    length: '',
    breadth: '',
    photo: '',
    photo2: '',
    photo3: '',
    photo4: '',
    photo5: '',
    sku: '',
    categories: [],
    brands: [],
    subtags: [],
    category: '',
    brand: '',
    subtag: '',
    loading: false,
    error: '',
    createdProduct: '', //created product used to give a popup that the product has been created
    getaRedirect: false,
    formData: new FormData(),
  });

  const {
    name,
    description,
    price,
    stock,
    hsn,
    weight,
    height,
    length,
    breadth,
    categories,
    brands,
    subtags,
    error,
    createdProduct,
    formData,
    sku,
  } = values;

  const handleDelete = (i) => {
    setKeywords(keywords.splice(i, 1) !== undefined ? keywords : keywords);
    setValues({
      ...values,
      keywords:
        values.keywords.splice(i, 1) !== undefined
          ? values.keywords
          : values.keywords,
    });
  };

  const handleAddition = async (newTag) => {
    setKeywords(keywords.concat([newTag]));
    setValues({ ...values, keywords: values.keywords.concat([newTag.text]) });
  };

  const preload = () => {
    getAllCategories().then((data1) => {
      if (data1)
        if (data1.error) {
          setValues({ ...values, error: data1.error });
        } else {
          getAllBrands().then((data2) => {
            if (data2.error) {
              setValues({ ...values, error: data2.error });
            } else {
              setValues({
                ...values,
                categories: data1,
                brands: data2,
                formData: new FormData(),
              });
            }
          });
        }
    });
  };

  //We have to call these preload in useEffect() by react Hooks
  useEffect(() => {
    preload();
  }, []);

  //FIXME: Handle the image upload same as in add product
  const onSubmit = (e) => {
    e.preventDefault();

    formData.set('keys', values.keywords);
    setValues({ ...values, error: '', loading: true });
    createProduct(user._id, token, formData).then((data) => {
      if (data)
        if (data.error) {
          setValues({ ...values, error: data.error });
          toast.error(`${data.error}`);
        } else {
          toast.success(`${data.name} Created Successfully`);
          setValues({
            ...values,
            name: '',
            description: '',
            price: '',
            stock: '',
            hsn: '',
            weight: '',
            height: '',
            length: '',
            breadth: '',
            error: '',
            photo: '',
            photo2: '',
            photo3: '',
            photo4: '',
            photo5: '',
            sku: '',
            loading: false,
            createdProduct: data.name,
          });
        }
    });
  };

  const handleChange = (name) => (event) => {
    const value = ['photo', 'photo2', 'photo3', 'photo4', 'photo5'].includes(
      name
    )
      ? event.target.files[0]
      : event.target.value;

    //it means we will have a value which is equal to acc to the equality of key condition and if is photo then we will get
    //the name of the file by target.files else we will find the value and save it
    formData.set(name, value);
    setValues({ ...values, [name]: value });
  };

  const createProductForm = () => (
    <form>
      <div className='form-group'>
        <input
          onChange={handleChange('photo')}
          type='file'
          name='photo'
          accept='image'
          placeholder='Upload photo 1'
        />
      </div>
      <div className='form-group'>
        <input
          onChange={handleChange('photo2')}
          type='file'
          name='photo2'
          accept='image'
          placeholder='Upload photo 2'
        />
      </div>
      <div className='form-group'>
        <input
          onChange={handleChange('photo3')}
          type='file'
          name='photo3'
          accept='image'
          placeholder='Upload photo 3'
        />
      </div>
      <div className='form-group'>
        <input
          onChange={handleChange('photo4')}
          type='file'
          name='photo4'
          accept='image'
          placeholder='Upload photo 4'
        />
      </div>
      <div className='form-group'>
        <input
          onChange={handleChange('photo5')}
          type='file'
          name='photo5'
          accept='image'
          placeholder='Upload photo 5'
        />
      </div>
      <div className='form-group'>
        <input
          onChange={handleChange('name')}
          name='name'
          className='form-control'
          placeholder='Name'
          value={name}
        />
      </div>
      <div className='form-group'>
        <textarea
          onChange={handleChange('description')}
          name='description'
          className='form-control'
          placeholder='Description'
          value={description}
        />
      </div>
      <div className='form-group'>
        <input
          onChange={handleChange('price')}
          type='number'
          name='price'
          className='form-control'
          placeholder='Price'
          value={price}
        />
      </div>
      <div className='form-group'>
        <select
          onChange={handleChange('category')}
          name='category'
          className='form-control'
          placeholder='Category'
        >
          <option>Select Category</option>
          {categories &&
            categories.map((cate, index) => (
              <option key={index} value={cate._id}>
                {cate.name}
              </option>
            ))}
        </select>
      </div>
      <div className='form-group'>
        <select
          onChange={handleChange('brand')}
          name='brand'
          className='form-control'
          placeholder='Brand'
        >
          <option>Select Brand</option>
          {brands &&
            brands.map((brand, index) => (
              <option key={index} value={brand._id}>
                {brand.name}
              </option>
            ))}
        </select>
      </div>
      {/* <div className='form-group'>
        <select
          onChange={handleChange('subtag')}
          name='subtag'
          className='form-control'
          placeholder='Subtag'
        >
          <option>Select Subtag</option>
          {subtags &&
            subtags.map((subtag, index) => (
              <option key={index} value={subtag._id}>
                {subtag.name}( {subtag.parent.name} )
              </option>
            ))}
        </select>
      </div> */}
      <div className='form-group'>
        <input
          onChange={handleChange('stock')}
          type='number'
          name='stock'
          className='form-control'
          placeholder='Stock'
          value={stock}
        />
      </div>
      <div className='form-group'>
        <input
          onChange={handleChange('sku')}
          type='text'
          name='sku'
          className='form-control'
          placeholder='SKU'
          value={sku}
        />
      </div>

      <div className='form-group'>
        <input
          onChange={handleChange('hsn')}
          type='number'
          name='hsn'
          className='form-control'
          placeholder='HSN'
          value={hsn}
        />
      </div>
      <div className='form-group'>
        <input
          onChange={handleChange('weight')}
          type='number'
          name='weight'
          className='form-control'
          placeholder='Weight'
          value={weight}
        />
      </div>
      <div className='form-group'>
        <input
          onChange={handleChange('height')}
          type='number'
          name='height'
          className='form-control'
          placeholder='Height'
          value={height}
        />
      </div>
      <div className='form-group'>
        <input
          onChange={handleChange('length')}
          type='number'
          name='length'
          className='form-control'
          placeholder='Length'
          value={length}
        />
      </div>
      <div className='form-group'>
        <input
          onChange={handleChange('breadth')}
          type='number'
          name='breadth'
          className='form-control'
          placeholder='Breadth'
          value={breadth}
        />
      </div>
      <ReactTags
        tags={keywords}
        delimiters={delimiters}
        classNames={{
          tags: 'tagsClass',
          tagInput: 'tagInputClass',
          tagInputField: 'tagInputFieldClass',
          selected: 'selectedClass',
          tag: 'tagClass',
          remove: 'removeClass',
          suggestions: 'suggestionsClass',
          activeSuggestion: 'activeSuggestionClass',
        }}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
      />
      <button
        type='submit'
        onClick={onSubmit}
        className='btn btn-outline-primary mb-3'
      >
        Create Product
      </button>
    </form>
  );

  return (
    <Dashboard>
      <ToastContainer position='bottom-right' autoClose={5000} />
      <div className='container h-100'>
        <div className='row justify-content-center align-item-center'>
          <div className='col-12'>
            <p className='h3 text-gray-800 font-weight-bold'>
              Create New Product
            </p>
            <hr />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-8 offset-md-2'>{createProductForm()}</div>
        </div>
      </div>
    </Dashboard>
  );
};

export default AddProduct;
