import React, { useState } from 'react';
import { API } from '../../backend';
import { themeColor } from '../../utility/ColorPallete';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Review.css';
import { getAllReviews } from '../helper/reviewHelper';
const Review = () => {
  const [reviews, setReviews] = useState([]);
  const [errors, setErrors] = useState(false);
  const [isloading, setIsLoading] = useState(true);
  const loadAllReviews = () => {
    getAllReviews().then((data) => {
      if (data)
        if (data.error) {
          setErrors(data.error);
          setIsLoading(false);
        } else {
          setReviews(data.reviews.reverse());
          setIsLoading(false);
        }
    });
  };
  React.useEffect(() => {
    loadAllReviews();
  }, []);
  const settings = {
    className: 'Responsive',
    centerMode: true,
    infinite: true,
    centerPadding: '60px',
    slidesToShow: reviews.length< 3 ? reviews.length  : 3,
    slidesToScroll: reviews.length< 3 ? reviews.length  : 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <div
      className='container-fluid p-0 m-0 py-3'
      style={{
        backgroundColor: themeColor.lightContrast,
      }}
    >
      <div className='row py-5 m-0 w-100'>
        <div className='col-12 pb-2 pt-5'>
          <p
            className='font-weight-bold h1  txt-black-faded text-center'
            style={{ color: '#FFFEF2' }}
          >
            What Our Customers Say
          </p>
          <hr
            className='divider mx-auto'
            style={{ height: '0.178rem', borderColor: '#FFFEF2' }}
          />
        </div>
        <div
          className='col-12'
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Slider {...settings} style={{ margin: `0px !important` }}>
            {reviews.length > 0 &&
              reviews.reverse().map((review, i) => {
                return (
                  <figure key={i} className='snip snip1192'>
                    <blockquote>
                      {`${review.name}: ${review.content}`}
                    </blockquote>
                    <div className='author'>
                      <img
                        src={`${API}/review/photo/${review ? review._id : ''}`}
                        alt='sq-sample1'
                      />
                      <h5>
                        {review.name} <span> {review.company}</span>
                      </h5>
                    </div>
                  </figure>
                );
              })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Review;
