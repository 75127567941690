import { API } from '../../backend';

//Add to cart
export const addItemToCart = (item, next) => {
  //next to get a callback to make use redirect to cart page
  let cart = [];
  if (typeof window !== undefined) {
    if (localStorage.getItem('cart')) {
      cart = JSON.parse(localStorage.getItem('cart'));
    }
    cart.push({
      ...item,
      count: 1,
    });
    localStorage.setItem('cart', JSON.stringify(cart));
    next();
  }
};

//Load in cart items
export const loadCart = () => {
  if (typeof window !== undefined) {
    if (localStorage.getItem('cart')) {
      return JSON.parse(localStorage.getItem('cart'));
    }
  }
};

//Remove cart item
export const removeItemFromCart = (productId) => {
  let cart = [];
  if (typeof window !== undefined) {
    if (localStorage.getItem('cart')) {
      cart = JSON.parse(localStorage.getItem('cart'));
    }

    cart.map((product, index) => {
      if (product._id === productId) {
        cart.splice(index, 1);
      }
    });
    localStorage.setItem('cart', JSON.stringify(cart));
  }
  return cart;
};

//Empty out the cart and force reload after payment
export const cartEmpty = (next) => {
  if (typeof window !== undefined) {
    localStorage.removeItem('cart');
    //below it is requird to keep the cart but empty it after transaction
    let cart = [];
    localStorage.setItem('cart', JSON.stringify(cart));
    next();
  }
};

export const addToDbCart = (product, token, userid) => {
  return fetch(`${API}/cart/user/${userid}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(product),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getUserById = (token, userid) => {
  return fetch(`${API}/user/${userid}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const removefromDbCart = (product, token, userid) => {
  return fetch(`${API}/cart/delete/user/${userid}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ product }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getDbCart = (token, userid) => {
  return fetch(`${API}/cart/user/${userid}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const updateCart = (updatedCart, token, userid) => {
  return fetch(`${API}/cart/update/user/${userid}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(updatedCart),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
