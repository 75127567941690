import React from 'react';

import { API } from '../../backend';
import {
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  withStyles,
  Button,
  Typography,
  Tooltip,
  Zoom,
} from '@material-ui/core';
import { pxToVw, pxToVh, pxToRem } from '../../utility/conversion';
import { themeColor } from '../../utility/ColorPallete';
import { useHistory } from 'react-router';

const styles = () => ({
  OrderCardHeading: {
    fontSize: '0.85rem !important',
    color: '#ffffffde',
    fontWeight: '400',
  },
  OrderCardHeading2: {
    fontSize: '1rem !important',
  },
  OrderCardHeadingLight: {
    fontSize: '0.8rem !important',
    color: '#ffffffa0',
    textDecoration: 'line-through',
  },
  expansionPanelRoot: {
    '&:before': {
      height: 0,
    },
    border: 'normal solid gray',
    margin: '1rem',
    width: '1250px',
    maxWidth: '80vw',
  },
  expansionPanelSummaryRoot: {
    padding: `${pxToVh(10)} ${pxToVw(10)}`,
    backgroundColor: '#9a1750',
    color: 'white',
    minHeight: '0 !important',
  },
  expansionPanelSummaryContent: {
    margin: 0,
    '& > :last-child': {
      paddingRight: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  expansionPanelDetailsRoot: {
    padding: `${pxToVh(25)} ${pxToVw(10)}`,
  },
  orderDate: {
    fontWeight: 500,
    fontSize: pxToRem(10),
  },
  light: {
    marginTop: '0.5rem',
    opacity: 0.75,
  },
  centerText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

const OrderPrice = (props) => {
  const { classes, order } = props;
  if (order.discAmount) {
    if (order.discAmount !== order.amount) {
      return (
        <Grid container alignItems='flex-end'>
          <Typography variant='h6' className={classes.OrderCardHeading}>
            ₹ {parseFloat(order?.discAmount).toFixed(2)}
          </Typography>
          <Typography
            variant='h6'
            className={classes.OrderCardHeadingLight}
            style={{ marginLeft: '0.5rem' }}
          >
            ₹ {parseFloat(order?.amount).toFixed(2)}{' '}
          </Typography>
        </Grid>
      );
    }
    return (
      <Typography
        variant='h6'
        className={classes.OrderCardHeading}
        align='left'
      >
        ₹ {parseFloat(order?.discAmount).toFixed(2)}
      </Typography>
    );
  }
  return (
    <Typography variant='h6' className={classes.OrderCardHeading} align='left'>
      ₹ {parseFloat(order?.amount).toFixed(2)}
    </Typography>
  );
};
const OrderCard = (props) => {
  const { classes, order, index, open = false } = props;
  const history = useHistory();
  const [expanded, setExpanded] = React.useState(open);
  React.useEffect(() => {
    if (index === 0) setExpanded(true);
  }, [index]);
  return (
    <ExpansionPanel
      classes={{
        root: classes.expansionPanelRoot,
      }}
      expanded={expanded}
      onChange={() => {
        if (expanded === false) setExpanded(true);
        else setExpanded(false);
      }}
      elevation={2}
    >
      <ExpansionPanelSummary
        classes={{
          root: classes.expansionPanelSummaryRoot,
          content: classes.expansionPanelSummaryContent,
        }}
      >
        <div style={{ width: '100%', padding: `0 20px` }}>
          <Grid container justify='space-between'>
            <Grid item xs={12} md={3}>
              <Typography
                variant='h6'
                className={`${classes.light} ${classes.OrderCardHeading}`}
                align='left'
              >{`ORDER ID`}</Typography>
              <Typography
                variant='h6'
                className={classes.OrderCardHeading}
                align='left'
              >
                {order?._id}
              </Typography>
              <Typography
                variant='h6'
                className={`${classes.light} ${classes.OrderCardHeading}`}
                align='left'
              >
                TOTAL
              </Typography>
              <OrderPrice classes={classes} order={order} />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography
                variant='h6'
                className={`${classes.light} ${classes.OrderCardHeading}`}
                align='left'
              >
                ORDER DATE
              </Typography>
              <Typography
                variant='h6'
                className={classes.OrderCardHeading}
                align='left'
              >{`${order.createdAt.substring(
                8,
                10
              )}/ ${order.createdAt.substring(
                5,
                7
              )}/ ${order.createdAt.substring(0, 4)}`}</Typography>
              <Typography
                variant='h6'
                className={`${classes.light} ${classes.OrderCardHeading}`}
                align='left'
              >
                ORDER STATUS
              </Typography>
              <Typography
                variant='h6'
                className={classes.OrderCardHeading}
                align='left'
              >{`${order.orderStatus}`}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography
                variant='h6'
                className={`${classes.light} ${classes.OrderCardHeading}`}
                align='left'
              >
                SHIP TO
              </Typography>
              <Typography
                variant='h6'
                className={classes.OrderCardHeading}
                align='left'
              >{`${order.address.fname} ${order.address.lname}`}</Typography>
              <Typography
                variant='h6'
                className={classes.OrderCardHeading}
                align='left'
              >
                {order.address.city}
              </Typography>
              <Typography
                variant='h6'
                className={classes.OrderCardHeading}
                align='left'
              >
                {order.address.pin}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        classes={{
          root: classes.expansionPanelDetailsRoot,
        }}
      >
        <Grid container justify='space-between'>
          <Grid item xs={12} md={12}>
            {order?.products?.map((product) => (
              <>
                <Grid
                  container
                  style={{
                    borderBottom: 'solid 1px #00000010',
                    padding: '1rem 0',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    history.push(`/product/${product?.product._id}`);
                  }}
                  alignItems='center'
                >
                  <Grid item xs={12} md={3}>
                    <img
                      src={`${API}/product/photo/${product?.product._id}`}
                      alt='productimage'
                      className='img-fluid'
                      style={{ maxWidth: '8rem' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography
                      variant='h5'
                      align='left'
                      className={`${classes.OrderCardHeading2} text-dark`}
                    >
                      {product?.product.name}
                    </Typography>
                    <Typography
                      align='left'
                      variant='h6'
                      className={`${classes.OrderCardHeading} text-dark`}
                    >
                      <span style={{ fontWeight: 'bold' }}>Quantity:</span>{' '}
                      {product?.quantity}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Typography
                      variant='h6'
                      align='left'
                      className={`${classes.OrderCardHeading} lead text-dark`}
                    >
                      {product?.product?.description.slice(0, 250) + '...'}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ))}
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default withStyles(styles, { withTheme: true })(OrderCard);
