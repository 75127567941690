import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isAuthenticated } from '../auth/helper';
import { getAllReviews, deleteReview } from './helper/adminapicall';
import Dashboard from './Dashboard';
import { Table, TableRow, TableCell, TableHead } from '@material-ui/core';
import Spinner from '../common/Spinner';
import { ToastContainer, toast } from 'react-toastify';

const ManageReview = () => {
  const [reviews, setReviews] = useState([]);
  const { user, token } = isAuthenticated();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const preload = (page, pageSize) => {
    getAllReviews(page, pageSize).then((data) => {
      if (data)
        if (data.error) {
          toast.error(data.error);
          setLoading(false);
        } else {
          setReviews(data?.reviews);
          setTotalCount(data?.totalCount);
          setLoading(false);
        }
    });
  };

  useEffect(() => {
    setLoading(true);
    preload(page, pageSize);
  }, [page]);

  const deleteThisReview = (reviewId) => {
    deleteReview(user._id, reviewId, token).then((data) => {
      if (data)
        if (data.error) {
          toast.error(data.error);
        } else {
          toast.success('Review deleted successfully! ...Reloading');
          if (reviews?.length % pageSize === 1 && page > 1) setPage(page - 1);
          setTimeout(() => {
            preload(page - 1, pageSize); //reloading the component after deleting
          }, 2000);
        }
    });
  };

  return (
    <Dashboard>
      <ToastContainer position='bottom-right' autoClose={5000} />
      <div className='container h-100'>
        <div className='row justify-content-center align-item-center'>
          <div className='col-12'>
            <p className='h3 text-gray-800 font-weight-bold'>All Reviews</p>
            <hr />
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <h2 className='text-center text-dark my-3'>
              Total {reviews?.length} reviews
            </h2>
            <div className='row text-center mb-2 justify-content-center'>
              {!loading && (
                <>
                  <div className='col-12 border'>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Company</TableCell>
                          <TableCell>Content</TableCell>
                        </TableRow>
                      </TableHead>
                      {reviews &&
                        reviews?.length > 0 &&
                        reviews.map((review, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{review?.name}</TableCell>
                              <TableCell>{review?.company}</TableCell>
                              <TableCell>
                                {review?.content?.toString?.()?.substr(0, 100) +
                                  '....'}
                              </TableCell>
                              <TableCell>
                                <button
                                  onClick={() => {
                                    deleteThisReview(review._id);
                                  }}
                                  style={{ background: 'red' }}
                                  className='btn text-light w-100'
                                >
                                  Delete
                                </button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </Table>
                  </div>
                  <div className='col-12 d-flex justify-content-between'>
                    {page > 1 && (
                      <span
                        className='h5 text-dark cursor-pointer'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setPage(page - 1);
                        }}
                      >
                        Previous
                      </span>
                    )}
                    {page * pageSize < totalCount && (
                      <span
                        className='h5 text-dark cursor-pointer'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setPage(page + 1);
                        }}
                      >
                        Next
                      </span>
                    )}
                  </div>
                </>
              )}
              {loading && <Spinner style={{ marginTop: '3rem' }} />}
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default ManageReview;
