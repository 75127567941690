import React, { useEffect } from 'react';
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { AiOutlineRightCircle } from 'react-icons/ai';
import Base from '../core/Base';

const RefundPolicy = () => {
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);
  return (
    <Base>
      <Grid container justify='center' className='py-5'>
        <Grid item xs={10} md={8}>
          <h1 class='section-title-heading mb-4 section-title-heading-about '>
            Refund Policy
          </h1>
          <Divider className='mb-4 mt-0' />
        </Grid>
        <Grid item xs={10} md={8}>
          <Grid container>
            <Grid item xs={10}>
              <h1
                class='section-title-heading mt-4 mb-1 section-title-heading-about'
                style={{ fontSize: '1.4rem' }}
              >
                Subscription Cancellation
              </h1>
            </Grid>
            <Grid container className='my-2'>
            <p className='privacy-policy-text text-left'>
            Circle Skincare subscriptions could be cancelled with No Questions Asked.
            In case you are not satisfied with your regimen and want to cancel your subscription, please write to us at support@circleskincare.com or call/Whatsapp us at +91 7648044505. Please note the subscription could be cancelled before the personalized regimen kit is dispatched.
            </p>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={10}>
              <h1
                class='section-title-heading mt-4 mb-1 section-title-heading-about'
                style={{ fontSize: '1.4rem' }}
              >
                Order Cancellation

              </h1>
            </Grid>
            <Grid container className='my-2'>
            <p className='privacy-policy-text text-left'>
            We believe in value for money and customer satisfaction. Hence, we have a No Questions Asked order cancellation policy. In case you change your mind after placing the order and want to cancel it, please write to us at support@circleskincare.com or call/Whatsapp us at +91 7648044505. Please note we address to cancellation requests only till 6 hours of order confirmation. The refund would be processed within 3 working days.
            </p>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={10}>
              <h1
                class='section-title-heading mt-4 mb-1 section-title-heading-about'
                style={{ fontSize: '1.4rem' }}
              >
                Replacement
              </h1>
            </Grid>
            <Grid container className='my-2'>
            <p className='privacy-policy-text text-left'>
            If you think, you have received the product in a bad condition or if the packaging is tampered with or damaged before delivery, please refuse to accept the package and return the package to the delivery person. Also, please write to us at support@circleskincare.com or call/Whatsapp us at +91 7648044505. We will ensure a replacement is sent to you.
            </p>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={10}>
              <h1
                class='section-title-heading mt-4 mb-1 section-title-heading-about'
                style={{ fontSize: '1.4rem' }}
              >
                Further Queries
              </h1>
            </Grid>
            <Grid container className='my-2'>
            <p className='privacy-policy-text text-left'>
            Please feel free to write to us at support@circleskincare.com or call/Whatsapp us at +91 7648044505.
            </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Base>
  );
};

export default RefundPolicy;
