import { API } from '../../backend';

export const getAllProducts = () => {
  return fetch(`${API}/products`, {
    method: 'GET',
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const sendMail = (mailContent) =>
  fetch(`${API}/push/emails`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(mailContent),
  })
    .then((response) => response.json())
    .catch((e) => {
      console.log('Error in Setting Up User');
    });

export const subscribe = (email) => {
  return fetch(`${API}/subscribe`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(email),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const filterProducts = (categories, brands, page, pageSize) => {
  return fetch(`${API}/products/filters?page=${page}&pageSize=${pageSize}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ categories, brands }),
  })
    .then((response) => response.json())
    .catch((err) => {
      console.log(err);
    });
};

export const searchProducts = (
  categories,
  brands,
  page,
  pageSize,
  search = ''
) => {
  return fetch(
    `${API}/products/search?search=${search}&page=${page}&pageSize=${pageSize}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ categories, brands }),
    }
  )
    .then((response) => response.json())
    .catch((err) => {
      console.log(err);
    });
};

export const downloadData = (type, userid, token) => {
  return fetch(`${API}/user/download/${type}/${userid}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => {
    response.blob().then((myBlob) => {
      const url = window.URL.createObjectURL(new Blob([myBlob]));
      const link = document.createElement('a');
      link.style = 'display:none';
      link.href = url;
      link.setAttribute(
        'download',
        `Newsletter-${type}-${new Date().toLocaleDateString()}.csv`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  });
};
