import { API } from '../../backend';
import AddSliderImage from '../AddSliderImage';

//Category Calls
export const createCategory = (userId, token, category) => {
  return fetch(`${API}/category/create/${userId}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(category),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
//Get All Categories
export const getAllCategories = () => {
  return fetch(`${API}/categories`, {
    method: 'GET',
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//Delete a category
export const deleteCategory = (userId, categoryId, token) => {
  return fetch(`${API}/category/${categoryId}/${userId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

//Products Calls
//Create was made using formidable not json data remember
export const createProduct = (userId, token, product) => {
  return fetch(`${API}/product/create/${userId}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: product,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

//Get all Products
export const getAllProducts = (page, pageSize) => {
  return fetch(`${API}/products?page=${page}&pageSize=${pageSize}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//Delete Product
export const deleteProduct = (userId, productId, token) => {
  return fetch(`${API}/product/${productId}/${userId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

//Get a single Product
export const getSingleProduct = (productId) => {
  return fetch(`${API}/product/${productId}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//Update Product
export const updateProduct = (userId, productId, token, product) => {
  return fetch(`${API}/product/${productId}/${userId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: product,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//Blog Calls
//Create was made using formidable not json data remember
export const createBlogs = (userId, token, blog) => {
  return fetch(`${API}/blog/create/${userId}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: blog,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

//Deleting Blogs
export const deleteBlog = (userId, blogId, token) => {
  return fetch(`${API}/blog/${blogId}/${userId}`, {
    method: 'delete',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

//delete Comment
export const deleteComment = (blogId, token, comment) => {
  return fetch(`${API}/blog/comment/delete/${blogId}/${comment}`, {
    method: 'put',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

//Author Calls

export const getAllAuthors = (page = 1, pageSize) => {
  return fetch(`${API}/authors?page=${page}&pageSize=${pageSize}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const addAuthor = (userId, token, author) => {
  return fetch(`${API}/author/create/${userId}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: author,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

//Delete Author
export const deleteAuthor = (userId, authorId, token) => {
  return fetch(`${API}/author/delete/${authorId}/${userId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const AddNewSliderImage = (userid, token, img) => {
  return fetch(`${API}/slider/addNewImage/${userid}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: img,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAllSliderImage = () => {
  return fetch(`${API}/slider/getAllimages`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const deleteSlideImage = (userId, token, imageId) => {
  return fetch(`${API}/slider/deleteImage/${imageId}/${userId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

//Create Brand

export const createBrand = (userId, token, brand) => {
  return fetch(`${API}/brand/create/${userId}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(brand),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//Get All Brand
export const getAllBrands = () => {
  return fetch(`${API}/brands`, {
    method: 'GET',
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//Delete a Brand
export const deleteBrand = (userId, brandId, token) => {
  return fetch(`${API}/brand/${brandId}/${userId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

//Tag Calls
export const createTag = (userId, token, tag) => {
  return fetch(`${API}/tag/create/${userId}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(tag),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
//Get All Tags
export const getAllTags = () => {
  return fetch(`${API}/tags`, {
    method: 'GET',
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//Delete a tag
export const deleteTag = (userId, tagId, token) => {
  return fetch(`${API}/tag/${tagId}/${userId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

//Subtag Calls
export const createSubtag = (userId, token, subtag) => {
  return fetch(`${API}/subtag/create/${userId}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(subtag),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
//Get All Subtags
export const getAllSubtags = () => {
  return fetch(`${API}/subtags`, {
    method: 'GET',
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

//Delete a subtag
export const deleteSubtag = (userId, subtagId, token) => {
  return fetch(`${API}/subtag/${subtagId}/${userId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

// Review API Calls
export const createReview = (userId, token, reviewData) => {
  return fetch(`${API}/review/create/${userId}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: reviewData,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAllReviews = (page, pageSize) => {
  return fetch(`${API}/reviews?page=${page}&pageSize=${pageSize}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const deleteReview = (userId, reviewId, token) => {
  return fetch(`${API}/review/${reviewId}/${userId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

// Orders download

export const downloadOrederData = (type, userid, token) => {
  return fetch(`${API}/order/download/${userid}?type=${type}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => {
    response.blob().then((myBlob) => {
      const url = window.URL.createObjectURL(new Blob([myBlob]));
      const link = document.createElement('a');
      link.style = 'display:none';
      link.href = url;
      link.setAttribute(
        'download',
        `Orders-${type ? type : 'All'}-${new Date().toLocaleDateString()}.csv`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  });
};
