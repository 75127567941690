import React from 'react';
import {
  Grid,
  Typography,
  withStyles,
  Button,
  Divider,
} from '@material-ui/core';
import Base from '../Base';
import backIcon from '../../img/icons/Back.png';
import plusIcon from '../../img/icons/plus.png';
import { getSingleProduct } from '../../admin/helper/adminapicall';
import { useState } from 'react';
import { addToDbCart } from '../../core/helper/CartHelper';
import { Redirect, useHistory } from 'react-router-dom';
import Spinner from '../../common/Spinner';
import { useEffect } from 'react';
import { API } from '../../backend';
import { Link } from 'react-router-dom';
import { isAuthenticated } from '../../auth/helper/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoIosAdd } from 'react-icons/io';
import Slider from 'react-slick';

const styles = (theme) => ({
  productTitle: {
    fontWeight: '500',
    marginTop: '5vw',
    fontSize: '3.5rem',
    color: '#222326',
  },
  backButton: {
    background: '#FFF',
    transition: '0.3s',
    '&:hover': {
      transform: 'scale(1.1)',
      background: '#FFF',
    },
  },
  productBrand: {
    fontWeight: '200',
    paddingLeft: '0.15rem',
    marginTop: '20px',
    color: '#3F494B',
  },
  productDesc: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: '#222326',
    fontWeight: '300',
    textAlign: 'justify',
  },
  price: {
    fontSize: '3rem',
    fontWeight: '300',
    color: theme.palette.primary.main,
    paddingBottom: '5vw',
  },
  addToCart: {
    minWidth: '160px',
    borderRadius: '0',
    background: '#FFFAFA',
    border: 'solid 1px #B84651',
    marginBottom: '5vw',
    '&:hover': {
      border: 'solid 1px #B84651',
      background: '#B8465110',
    },
    '&:disabled': {
      border: 'solid 1px #B8465140',
      background: '#f2aaca20',
    },
  },
});

const SingleProductCard = (props) => {
  const history = useHistory();
  const { token, user } = isAuthenticated();
  const { classes } = props;
  const { params } = props.match;
  const [Product, setProduct] = useState({});
  const [Loading, setLoading] = useState(true);
  const preload = (productId) => {
    getSingleProduct(productId).then((data) => {
      if (data) {
        if (!data.error) {
          setProduct(data);
          setLoading(false);
        } else {
          console.log(data.error);
        }
      }
    });
  };

  useEffect(() => {
    preload(params.productId);
    window.scrollTo(0, 0);
  }, []);

  if (Loading) {
    return <Spinner />;
  }

  const addProductToCart = (prod) => {
    if (user === undefined) {
      history.push('/signin');
    } else {
      addToDbCart(
        { product: prod._id, sellingPrice: prod.price },
        token,
        user._id
      ).then((data) => {
        if (data) {
          toast.success('Product added to your Cart');
          getRedirect(true);
        } else {
          toast.error('Unable to add');
        }
      });
    }
  };

  const getRedirect = (redirect) => {
    if (redirect) {
      //  history.push('/cart');
    }
  };

  const settings = {
    className: 'Responsive',
    centerMode: true,
    infinite: true,
    centerPadding: '60px',
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <Base>
      <ToastContainer position="bottom-right" autoClose={5000} />
      <Grid
        container
        justify="center"
        alignItems="flex-start"
        style={{ padding: '4vw' }}
      >
        <Grid
          item
          xs={12}
          md={1}
          style={{ alignSelf: 'start', padding: '20px 0' }}
        >
          <Link to="/product" className={classes.backButton}>
            <img src={backIcon} style={{ width: '20px' }} alt="back" />
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          style={{
            alignSelf: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
          className="allProductsSlider"
        >
          <Slider {...settings}>
            {Product.photo && (
              <img
                src={`${API}/product/photo/${
                  Product ? Product._id : ''
                }?photoIndex=1`}
                alt="productimage"
                className="img-fluid"
                style={{
                  marginLeft: 'auto',
                }}
              />
            )}
            {Product.photo2 && (
              <img
                src={`${API}/product/photo/${
                  Product ? Product._id : ''
                }?photoIndex=2`}
                alt="productimage"
                className="img-fluid"
                style={{
                  marginLeft: 'auto',
                }}
              />
            )}
            {Product.photo3 && (
              <img
                src={`${API}/product/photo/${
                  Product ? Product._id : ''
                }?photoIndex=3`}
                alt="productimage"
                className="img-fluid"
                style={{
                  marginLeft: 'auto',
                }}
              />
            )}
            {Product.photo4 && (
              <img
                src={`${API}/product/photo/${
                  Product ? Product._id : ''
                }?photoIndex=4`}
                alt="productimage"
                className="img-fluid"
                style={{
                  marginLeft: 'auto',
                }}
              />
            )}
            {Product.photo5 && (
              <img
                src={`${API}/product/photo/${
                  Product ? Product._id : ''
                }?photoIndex=5`}
                alt="productimage"
                className="img-fluid"
                style={{
                  marginLeft: 'auto',
                }}
              />
            )}
          </Slider>
        </Grid>
        <Grid item xs={12} md={6} style={{ paddingTop: '20px' }}>
          {/* //FIXME: mobile  */}
          <Grid
            container
            alignItems="center"
            style={{ margin: '0', paddingLeft: '50px' }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h2"
                align="left"
                gutterBottom
                className={classes.productTitle}
              >
                {Product.name}
              </Typography>
              <Divider />
              <Typography
                variant="h5"
                align="left"
                gutterBottom
                className={classes.productBrand}
              >
                By {Product.brand.name}
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography align="left" className={classes.price}>
                Rs. {Product.price}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              {isAuthenticated() && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    addProductToCart(Product);
                  }}
                  disabled={!Product.stock > 0}
                  variant="contained"
                  className={classes.addToCart}
                  style={{ padding: '15px 30px' }}
                >
                  <Typography variant="body1" style={{ color: '#762D09' }}>
                    {Product.stock > 0 ? 'Add to Cart' : 'Out of Stock'}
                  </Typography>
                  {Product.stock > 0 && (
                    <IoIosAdd
                      size={25}
                      style={{ marginLeft: '1rem' }}
                      color="#762D09"
                    />
                  )}
                </Button>
              )}

              {!isAuthenticated() && (
                <Button
                  disabled={!Product.stock > 0}
                  onClick={(e) => {
                    e.stopPropagation();
                    addProductToCart(Product);
                  }}
                  variant="contained"
                  className={classes.addToCart}
                  style={{ padding: '15px 30px' }}
                >
                  <Typography
                    variant="body1"
                    style={{ color: '#762D09', paddingRight: '40px' }}
                  >
                    {Product.stock > 0 ? 'Buy Now' : 'Out of Stock'}
                  </Typography>
                  <img src={plusIcon} alt="alt" style={{ width: '20px' }} />
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={10} style={{ margin: '4rem 0' }}>
          <Typography align="left" className={classes.productDesc}>
            {Product.description}
          </Typography>
        </Grid>
      </Grid>
    </Base>
  );
};

export default withStyles(styles, { withTheme: true })(SingleProductCard);
