import React, { useEffect } from 'react';
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { AiOutlineRightCircle } from 'react-icons/ai';
import Base from '../core/Base';

const Terms = () => {
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);
  
  return (
    <Base>
      <Grid container justify='center' className='py-5'>
        <Grid item xs={10} md={8}>
          <h1 class='section-title-heading mb-4 section-title-heading-about '>
            Terms and Conditions
          </h1>
          <Divider className='mb-4 mt-0' />
        </Grid>
        <Grid item xs={10} md={8}>
          <p className='privacy-policy-text text-left'>
          The terms "We" / "Us" / "Our"/ ”Company” individually and collectively refer to Circle Skincare and the terms "You"/"Your"/"Yourself" refer to the users.</p>
            <p>
            The use of this website is subject to the following terms of use:
          </p>
          
          <List>
            <ListItem>
              <ListItemIcon>
                <AiOutlineRightCircle />
              </ListItemIcon>
              <ListItemText>
              The content of the pages of this website is for general information and use only and is subject to change without notice.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AiOutlineRightCircle />
              </ListItemIcon>
              <ListItemText>
              Your use of any information, materials, products, services, and similar on this website is entirely at your own risk and consent, for which we shall not be liable. 
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AiOutlineRightCircle />
              </ListItemIcon>
              <ListItemText>
              This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.
              </ListItemText>
            </ListItem>
            
            <ListItem>
              <ListItemIcon>
                <AiOutlineRightCircle />
              </ListItemIcon>
              <ListItemText>
              All trademarks and other devices reproduced in this website which are not the property of, or licensed to the operator are acknowledged on the website.
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <AiOutlineRightCircle />
              </ListItemIcon>
              <ListItemText>
              Neither we nor any third parties associated with us provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found, or products or services offered on this website for any particular purpose. You acknowledge that such information, materials, products, services or similar may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
              </ListItemText>
            </ListItem>
            
            <ListItem>
              <ListItemIcon>
                <AiOutlineRightCircle />
              </ListItemIcon>
              <ListItemText>
              Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offence as per laws of India.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AiOutlineRightCircle />
              </ListItemIcon>
              <ListItemText>
              This website may also include links to other websites. These links are provided for your convenience to provide further information, material, products, services and similar. They do not signify that we endorse the website(s). We have no control on or responsibility for the content of the linked website(s).
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AiOutlineRightCircle />
              </ListItemIcon>
              <ListItemText>
              You may not create a link to this website from another website or document without our prior written consent.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AiOutlineRightCircle />
              </ListItemIcon>
              <ListItemText>
              Your use of this website and any dispute arising out of such use of the website is subject to the laws of India or other regulatory authority.
              </ListItemText>
            </ListItem>
          </List>
          
        </Grid>
      </Grid>
      </Base>
  );
};

export default Terms;
