import React, { useEffect } from 'react';
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { AiOutlineRightCircle } from 'react-icons/ai';
import Base from '../core/Base';
import '../styles.css';
const Terms = () => {
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <Base>
      <Grid container justify="center" className="py-5">
        <Grid item xs={10} md={8}>
          <h1 class="section-title-heading mb-4 section-title-heading-about ">
            FAQs
          </h1>
          <Divider className="mb-4 mt-0" />
        </Grid>
        <Grid item xs={10} md={8}>
          <p>
            <span className="faq-question">
              {' '}
              How much time does it take to get my order?
            </span>
            <p>
              It usually takes 4 to 7 working days for the order to get
              delivered.
            </p>
          </p>
          <p>
            <span className="faq-question">
              {' '}
              Is there any offer or discount?
            </span>
            <p>
              We keep our customers informed with personalized emails regarding
              latest developments and offers/discounts. We also update our
              social media handles with the latest offers/discounts.
            </p>
          </p>
          <p>
            <span className="faq-question"> Should I pay every month?</span>
            <p>
              Your skin condition can change and thus we update your regimen
              accordingly. If you choose 3 or 6 months subscription, you need to
              pay every month for your fresh and updated regimen.
            </p>
          </p>
          <p>
            <span className="faq-question"> Can I cancel my subscription?</span>
            <p>
              You can cancel your subscription anytime with No Questions Asked.
              However, we would love to get your feedback at your will.{' '}
            </p>
          </p>
          <p>
            <span className="faq-question"> Can I return my order?</span>
            <p>
              Please refer to our <a href="/refund-policy">Refund Policy</a> for
              details.
            </p>
          </p>
          <p>
            <span className="faq-question">
              {' '}
              Who can use Circle Skincare products?
            </span>
            <p>
              Circle Skincare products are for all skin types, and for all
              genders.{' '}
            </p>
          </p>
          <p>
            <span className="faq-question">
              {' '}
              Can I use makeup/cosmetics alongside Circle Skincare products?
            </span>
            <p>
              Yes, you can use makeup/cosmetics alongside Circle Skincare
              products.
            </p>
          </p>
        </Grid>
      </Grid>
    </Base>
  );
};

export default Terms;
