import React, { useState } from 'react';
import circleLogo from '../img/1navbar-cg.png';
import { useEffect } from 'react';
import { getRazorpayCreds } from './helper/Razorpay';
import { isAuthenticated } from '../auth/helper';

const PaymetnR = ({ amt }) => {
  const [razorBack, setRazorBack] = useState({
    loaded: false,
    id: '',
    amount: 0,
    currency: '',
  });

  const { user, token } = isAuthenticated();

  const preload = () => {
    getRazorpayCreds(user._id, token).then((data) => {
      if (data)
        if (data.error) {
          console.log(data.error);
        } else {
          setRazorBack({
            loaded: true,
            id: data.id,
          });
        }
    });
    console.log(razorBack.id, 'jsbdkj');
  };

  useEffect(() => {
    preload();
  }, []);

  const loadRazorpay = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const getRazorkey = () => {
    console.log({ paymentR: process.env.REACT_APP_RAZORPAY_KEY });
    return process.env.REACT_APP_RAZORPAY_KEY;
  };

  const displayRazorpay = async () => {
    const res = await loadRazorpay(
      'https://checkout.razorpay.com/v1/checkout.js'
    );

    if (!res) {
      alert('Problem Accessing our payment merchants');
    } else {
      var options = {
        key: getRazorkey(),
        subscription_id: razorBack.id,
        name: 'Circle Skincare Pvt. Ltd.',
        description: 'Thank you for purchasing!',
        image: { circleLogo },
        notes: {
          address: 'Circle Skincare',
        },
        theme: {
          color: '#F37254',
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.open();
    }
  };

  return (
    <div>
      Donation {amt} Rs.
      <button onClick={displayRazorpay}>Pay</button>
    </div>
  );
};

export default PaymetnR;
