import { API } from '../../backend';
// API means ; https://localhost:5000/api
export const sendOtp = (user) => {
  return fetch(`${API}/sendotp`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const signup = (user) => {
  //user will be json from frntend
  return fetch(`${API}/signup`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const signin = (user) => {
  //user will be json from frntend
  return fetch(`${API}/signin`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const forgotpassword = (user) => {
  //user will be json from frntend
  return fetch(`${API}/forgot-password`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const resetpassword = (user) => {
  //user will be json from frntend
  return fetch(`${API}/reset-password`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const googleLogin = (data) => {
  //user will be json from frntend
  return fetch(`${API}/google/login`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
//Browser forgets this json response to make the user kept signed in

export const authenticate = (data, rememberMe, next) => {
  if (typeof window !== 'undefined') {
    if (rememberMe) {
      localStorage.setItem('jwt-circle', JSON.stringify(data));
    } else sessionStorage.setItem('jwt-circle', JSON.stringify(data));
    localStorage.setItem('rememberMe', rememberMe.toString());
    next();
  }
};

export const signout = (next) => {
  //next is used here in order to make further proceedings as next act as a middleware we can redirect user by injecting a callback from next
  if (typeof window !== 'undefined') {
    localStorage.removeItem('jwt-circle'); //Remove the token before signout
    localStorage.removeItem('rememberMe'); //Remove the token before signout
    sessionStorage.removeItem('jwt-circle');
    // next();

    return fetch(`${API}/signout`, {
      method: 'GET',
    })
      .then((response) => {
        console.log('Successfully Logged Out');
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const isAuthenticated = () => {
  if (typeof window == 'undefined') {
    //accessing the window as jwt is stored here
    return false;
  }
  if (
    localStorage.getItem('jwt-circle') &&
    localStorage.getItem('rememberMe') === 'true'
  ) {
    return JSON.parse(localStorage.getItem('jwt-circle'));
    //if found dont send true send the string and parse
  } else if (
    sessionStorage.getItem('jwt-circle') &&
    localStorage.getItem('rememberMe') === 'false'
  ) {
    return JSON.parse(sessionStorage.getItem('jwt-circle'));
  } else {
    return false;
  }
};
