import {
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isAuthenticated } from "../auth/helper";
import { API } from "../backend";
import Spinner from "../common/Spinner";
import "../styles.css";
import { themeColor } from "../utility/ColorPallete";
import Base from "./Base";
import { getDbCart, updateCart } from "./helper/CartHelper";

const styles = (theme) => ({
  smallButtons: {
    color: "#FFFAFA",
    fontSize: "20px",
    margin: "0.25rem",
    padding: 0,
    minWidth: "2.5rem",
    minHeight: "2rem",
    borderRadius: "3px",
    background: "#9a1750",
    "&:hover": {
      color: "#9a1750",
      background: "#FFFAFA",
      border: "solid 1px #9a1750",
    },
  },
  checkoutBtn: {
    backgroundColor: themeColor.primary,
    width: "100%",
    color: "white",
    "&:hover": {
      color: "#9a1750",
      background: "#FFFAFA",
      border: "solid 1px #9a1750",
    },
    "&:disabled": {
      color: "#9a175080",
      background: "#FFFAFA",
      border: "solid 1px #9a1750",
    },
  },
});
const Cart = ({ classes, history }) => {
  const [products, setProducts] = useState([]);
  const [reload, setReload] = useState(false); //forcefull reload for remove in cart this func does nothing only changes reload from true to false and false to true....07
  const { user, token } = isAuthenticated();

  const [loading, setLoading] = useState(true);

  const preload = (token, user) => {
    if (user) {
      getDbCart(token, user._id)
        .then((data) => {
          if (data) {
            setProducts(data.data);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("Cart Error::", err);
          setLoading(true);
        });
    }
  };

  useEffect(() => {
    preload(token, user);
  }, [reload]);

  const addQuantity = ({ quantity, product }) => {
    if (product.stock < quantity + 1) {
      toast.success(
        `Currently only ${product.stock} product of ${product.name} is available with us.`
      );
      return null;
    }

    updateCart(
      { product: product._id, quantity: quantity + 1 },
      token,
      user._id
    ).then((data) => {
      if (data) {
        preload(token, user);
        toast.success("Product Quantity Increased");
      }
    });
  };

  const reduceQuantity = ({ quantity, product }) => {
    updateCart(
      { product: product._id, quantity: quantity - 1 },
      token,
      user._id
    ).then((data) => {
      if (data) {
        toast.success(
          quantity - 1 === 0
            ? "Product removed from cart"
            : "Product Quantity Decreased"
        );
        preload(token, user);
      }
    });
  };

  const isCheckoutDisabled = () => {
    return products.some((e, i) => {
      if (e && e.product && e.product.stock < e.quantity) return true;
    });
  };

  const calTotal = () => {
    var sum = 0;
    products.forEach((e, i) => {
      if (e && e.product && e.product.stock < e.quantity) return;
      if (e && e.product && e.quantity) {
        sum += e.product.price * e.quantity;
      }
    });
    return <p className="h6 text-muted font-weight-bold">Rs {sum}</p>;
  };
  const loadAllProducts = (products) => {
    //load all products from localStorage of browser
    console.log({ products });
    return (
      <Fragment>
        <div className="col-12 text-center">
          <p className="font-weight-bold h2 py-2">All Items</p>
        </div>
        <ToastContainer position="bottom-right" autoClose={5000} />
        <Table>
          <TableHead>
            <TableRow>
              {["", "Name", "Price", "Qty.", ""].map((item, index) => (
                <TableCell key={`${item}-${index}`}>
                  <Typography style={{ fontWeight: "500" }}>{item}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map(({ product, quantity }, index) => (
              <TableRow hover>
                <TableCell>
                  <img
                    src={`${API}/product/photo/${product ? product._id : ""}`}
                    style={{ width: "4rem" }}
                  />
                </TableCell>
                <TableCell>{product?.name}</TableCell>
                <TableCell>₹ {product?.price}</TableCell>
                <TableCell>
                  {product.stock >= quantity
                    ? quantity
                    : `Out of Stock ( Added: ${quantity}. Currently only ${product.stock} unit is available. )`}
                </TableCell>

                <TableCell>
                  <Button
                    className={classes.smallButtons}
                    onClick={() => reduceQuantity({ product, quantity })}
                  >
                    -
                  </Button>
                  <Button
                    className={classes.smallButtons}
                    onClick={() => addQuantity({ product, quantity })}
                  >
                    +
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Fragment>
    );
  };

  /* 
   //If more than one payment operator needed use this
    const loadCheckout = ()=>{
        //load all products from localStorage of browser
        return (
            <h1>For Checkout</h1>
        )
    }
     */

  return (
    <>
      {loading && <Spinner />}
      {!loading && (
        <Base
          title="Cart Page"
          description="Welcome to your Cart"
          style={{ height: "100%" }}
          className="container p-4"
        >
          <section
            className="contact-us quater-height"
            style={{ background: "#9a1750" }}
          >
            <div className="container-fluid h-100 text-center px-0">
              <div className="row mx-0 w-100 justify-content-center align-item-center d-flex px-0 h-100">
                <div className="col-12 px-0 align-self-center">
                  <p className="h1 font-weight-bold text-light">Cart</p>
                  <hr
                    className="divider  mx-auto"
                    style={{ borderColor: "#ffffff" }}
                  />
                </div>
              </div>
            </div>
          </section>
          <div className="container-fluid p-3">
            <div className="row text-center bg-light text-dark py-5 mx-0 justify-content-center align-item-center">
              {products && products.length > 0 ? (
                <div className="col-lg-8">
                  <div className="row mx-0 px-0 py-3">
                    {loadAllProducts(products)}
                  </div>
                </div>
              ) : (
                <div className="col-12" style={{ textAlign: "center" }}>
                  <h3 className="mt-5 mb-5">No Products in Cart</h3>
                </div>
              )}
              <div className="col-lg-4">
                {products && products.length > 0 && (
                  <div className="row mx-0 px-0">
                    <div className="col-12">
                      {/* <PaymentB products={products} setReload={setReload} /> */}
                      <div className="row cardCheckout p-3">
                        <p className="h4 font-weight-bold py-3">
                          Total Breakdown
                        </p>
                        {products.map((e, i) => {
                          if (e.product.stock < e.quantity) return null;
                          return (
                            <Fragment index={i}>
                              <div className="col-7 text-left">
                                <p className="h6 text-muted m-0">
                                  {i + 1}. {e.product?.name}
                                </p>
                              </div>

                              <div className="col-5 text-right">
                                <Divider
                                  orientation="vertical"
                                  light={false}
                                  absolute
                                  color="primary"
                                />
                                <p className="h6 text-muted  text-right">
                                  ₹ {e.product?.price}
                                  <span className="h6 ml-3 text-muted">
                                    x{e.quantity}
                                  </span>
                                </p>
                              </div>

                              <div className="col-12">
                                <hr className=" mt-1 mb-2" />
                              </div>
                            </Fragment>
                          );
                        })}
                        <div className="col-6 text-left">
                          <p className="h6 text-muted font-weight-bold m-0">
                            Total
                          </p>
                        </div>
                        <div className="col-6 text-right">{calTotal()}</div>
                        <div className="col-12">
                          <hr className=" mt-1 mb-2" />

                          <Button
                            className={classes.checkoutBtn}
                            disabled={isCheckoutDisabled()}
                            onClick={() => {
                              history.push("/user/checkout");
                            }}
                          >
                            Proceed to checkout
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Base>
      )}
    </>
  );
};

export default withStyles(styles, { withTheme: true })(Cart);
