import React, { useState } from "react";
import Base from "../core/Base";
import { isAuthenticated } from "../auth/helper";
import { Link } from "react-router-dom";
import { createBrand } from "./helper/adminapicall";
import Dashboard from "./Dashboard";

const AddBrand = () => {
  const [name, setName] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const { user, token } = isAuthenticated();

  const handleChange = (event) => {
    setError("");
    setName(event.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false);

    //Calling the backend ... we need to pass name as {name} as in admin api helper we are using JSON.stringify
    createBrand(user._id, token, { name }).then((data) => {
      if (data)if(data.error) {
        setError(true);
      } else {
        setError("");
        setSuccess(true);
        setName("");
      }
    });
  };

  const successMessage = () => {
    if (success) {
      return <h4 className="text-success">Brand Created Successfully</h4>;
    }
  };
  const errorMessage = () => {
    if (error) {
      return <h4 className="text-danger">Brand Creation Failed</h4>;
    }
  };

  const myCatForm = () => {
    return (
      <form action="">
        <div className="form-group">
          {successMessage()}
          {errorMessage()}
          <p className="lead">Enter the Brand Name</p>
          <input
            type="text"
            className="form-control my-3"
            onChange={handleChange}
            value={name}
            autoFocus
            required
            placeholder="Summer"
          />
          <button className="btn btn-outline-info rounded" onClick={onSubmit}>
            Create Brand
          </button>
        </div>
      </form>
    );
  };

  return (
    <Dashboard
      title="Create a Category here"
      description="add many as you want"
      className="container bg-info p-4"
    >
      <div className="container h-100">
        <div className="row justify-content-center align-item-center">
          <div className="col-12">
            <p className="h3 text-gray-800 font-weight-bold">
              Create New Brand
            </p>
            <hr />
          </div>
        </div>
        <div className="row rounded">
          <div className="col-md-8 offset-md-2">{myCatForm()}</div>
        </div>
      </div>
    </Dashboard>
  );
};

export default AddBrand;
