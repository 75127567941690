import React from 'react';
import { makeStyles } from '@material-ui/core';
import './loader.css';

const styles = makeStyles((theme) => ({
  root: {
    border: 'solid 1px #00000020',
    minHeight: '40vh',
    margin: '1rem',
    width: '90%',
    padding: '0.5rem',
  },
}));
const ProductLoadingCard = () => {
  const classes = styles();
  return (
    <div className={classes.root}>
      <div className='loader title'></div>
      <div className='loader subtitle1'></div>
      <div className='loader image'></div>
      <div className='loader subtitle2'></div>
      <div className='d-flex align-item-center justify-content-between'>
        <div className='loader subtitle1'></div>
        <div className='loader title'></div>
      </div>
    </div>
  );
};

export default ProductLoadingCard;
