import React from 'react';
import Dashboard from './Dashboard';
import AddBlogs from './components/AddBlogs';
import BlogDelete from './components/BlogDelete';
import { useState } from 'react';
import { getAllBlogs } from '../core/helper/blogHelper';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const AddNewBlogs = () => {
  let blogSize = 0;
  const [blogsize, setBlogsize] = useState([]);
  const [reload, setReload] = useState(false);

  const preload = () => {
    getAllBlogs().then((data) => {
      if (data)
        if (data.error) {
          console.log(data.error);
        } else {
          setBlogsize(data.blogs);
        }
    });
  };

  blogSize = blogsize.length;

  useEffect(() => {
    preload();
  }, [reload]);

  return (
    <Dashboard>
      <div className="container h-100">
        <div className="row justify-content-center align-item-center">
          <div className="col-md-8 align-self-center">
            <AddBlogs reload={reload} setReload={setReload} />
          </div>
          <div className="col-md-4 align-self-center">
            <div className="row">
              <div className="col-12 p-4">
                <div className="card border-left-primary shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Total Blogs
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          {blogSize}
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-calendar fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 p-4">
                <div className="card border-left-primary shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Create Blogs
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          <Link
                            className="btn btn-outline-primary shadow"
                            to="/admin/dashboard/blogs/add"
                          >
                            Click Here
                          </Link>
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-calendar fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 p-4">
                <div className="card border-left-primary shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Delete Blogs
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          <Link
                            className="btn btn-outline-primary shadow"
                            to="/admin/dashboard/blogs/Delete"
                          >
                            Click Here
                          </Link>
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-calendar fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default AddNewBlogs;
