import React, { useState, useEffect } from 'react';
import { isAuthenticated } from '../../auth/helper';
import { createBlogs, getAllAuthors } from '../helper/adminapicall';
import Select from 'react-select';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';

const AddBlogs = ({ reload = undefined, setReload = (f) => f }) => {
  const { user, token } = isAuthenticated();
  const [des,setDes] =useState('');
  const [values, setValues] = useState({
    title: '',
    description: '',
    author: '',
    allAuthors: [],
    smallPhoto: '',
    selectedAuthor: '',
    largePhoto: '',
    error: '',
    loading: true,
    blogCreated: false,
    getaRedirect: false,
    formData: new FormData(),
  });

  const preload = () => {
    getAllAuthors()
      .then((data) => {
        if (data && data.authors && data.authors.length > 0) {
          setValues({
            ...values,
            allAuthors: data.authors.map((item) => {
              return { value: item._id, label: item.name };
            }),
            loading: false,
          });
        } else
          setValues({
            ...values,
            loading: false,
          });
      })
      .catch((err) => {
        console.log(err);
        setValues({
          ...values,
          loading: false,
        });
      });
  };

  useEffect(() => {
    preload();
  }, []);

  const {
    title,
    description,
    author,
    smallPhoto,
    largePhoto,
    allAuthors,
    blogCreated,
    error,
    loading,
    formData,
  } = values;

  if (loading) {
    return <h1>Loading</h1>;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    setValues({ ...values, error: '', loading: true });
    createBlogs(user._id, token, formData).then((data) => {
      if (data)
        if (data.error) {
          setValues({ ...values, error: data.error });
        } else {
          setDes('')
          setValues({
            ...values,
            title: '',
            description: '',
            authorTag: '',
            authorPhoto: '',
            smallPhoto: '',
            largePhoto: '',
            error: '',
            blogCreated: true,
            loading: false,
          });
          setReload(!reload);
        }
    });
  };

  const onSelectChange = (e) => {
    setValues({
      ...values,
      author: e,
    });
    formData.set('author', e.value);
  };

  const handleChange = (name) => (event) => {
    const value = name === 'largePhoto'? event.target.files[0]
        : name === 'smallPhoto'
        ? event.target.files[0]
        : name === 'authorPhoto'
        ? event.target.files[0]
        : event.target.value;
    var files = event.target.files;
    formData.set(name, value);
    setValues({ ...values, [name]: value});
  };
  const successMessage = () => {
    return (
      <div
        className="alert alert-primary "
        style={{ display: blogCreated ? '' : 'none' }}
      >
        <h6>{title} Successfully Created</h6>
      </div>
    );
  };
  const errorMessage = () => {
    return (
      <div
        className="alert alert-danger mt-3"
        style={{ display: error ? '' : 'none' }}
      >
        <h4>{title} Failed To Create</h4>
      </div>
    );
  };

  const createBlogForm = () => {
    return (
      <form>
        {successMessage()}
        {errorMessage()}
        <div className="form-group ">
          <div class="custom-file ">
            <input
              onChange={handleChange('smallPhoto')}
              type="file"
              name="smallPhoto"
              accept="image"
              class="custom-file-input"
              id="inputGroupFile01"
              aria-describedby="inputGroupFileAddon01"
              placeholder="choose a file"
            />
            <label class="custom-file-label" for="inputGroupFile01">
              Upload Blog Small Photo
            </label>
          </div>
        </div>
        <div className="form-group">
          <div class="custom-file">
            <input
              onChange={handleChange('largePhoto')}
              type="file"
              name="largePhoto"
              accept="image"
              class="custom-file-input"
              id="inputGroupFile01"
              aria-describedby="inputGroupFileAddon01"
              placeholder="choose a file"
            />
            <label class="custom-file-label" for="inputGroupFile01">
              Upload Blog Large Photo
            </label>
          </div>
        </div>

        <div className="form-group">
          <input
            onChange={handleChange('title')}
            name="title"
            className="form-control"
            placeholder="Title"
            value={title}
          />
        </div>
        <div className="form-group">
          {/* <textarea
            onChange={handleChange('description')}
            name="description"
            className="form-control"
            placeholder="Description"
            value={description}
          /> */}
            <Editor
                  editorState={des}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapper-draft"
                  editorClassName="editor-draft"
                  onEditorStateChange={(data)=>{
                    setDes(data)
                    formData.set('description', draftToHtml(convertToRaw(data.getCurrentContent())))
                    setValues({ ...values, description: draftToHtml(convertToRaw(data.getCurrentContent()))});
                  }}
                />
        </div>
        <Select
          value={values.author}
          onChange={onSelectChange}
          options={allAuthors}
        />

        {/* <div className="form-group">
          <input
            onChange={handleChange("authorName")}
            type="text"
            name="authorName"
            className="form-control"
            placeholder="Author Name"
            value={authorName}
          />
        </div>
        <div className="form-group">
          <input
            onChange={handleChange("authorTag")}
            name="authorTag"
            className="form-control"
            placeholder="author tags"
            value={authorTag}
          />
        </div>

        <span>Author Photo</span>
        <div className="form-group">
          <div class="custom-file">
            <input
              onChange={handleChange("authorPhoto")}
              type="file"
              name="authorPhoto"
              accept="image"
              class="custom-file-input"
              id="inputGroupFile01"
              aria-describedby="inputGroupFileAddon01"
              placeholder="choose a file"
            />
            <label class="custom-file-label" for="inputGroupFile01">
              Upload Author Photo
            </label>
          </div>
        </div> */}

        <button
          type="submit"
          onClick={onSubmit}
          className="btn btn-outline-primary mb-3 mx-auto"
        >
          Create Blog
        </button>
      </form>
    );
  };

  return (
    <div className="card py-3">
      <h3 className="text-center">Add Blogs</h3>
      <div className="row text-dark rounded">
        <div className="col-md-8 offset-md-2">{createBlogForm()}</div>
      </div>
    </div>
  );
};

export default AddBlogs;
