import React, { useState, useEffect } from 'react';
import {
  getAllCategories,
  updateProduct,
  getSingleProduct,
} from './helper/adminapicall';
import { isAuthenticated } from '../auth/helper';
import Dashboard from './Dashboard';
import { WithContext as ReactTags } from 'react-tag-input';
import { API } from '../backend';

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const UpdateProduct = ({ match }) => {
  //destructuring the match to get the params its a props property

  const { user, token } = isAuthenticated();

  const [values, setValues] = useState({
    name: '',
    description: '',
    price: '',
    keywords: [],
    stock: '',
    photo: '',
    photo2: '',
    photo3: '',
    photo4: '',
    photo5: '',
    sku: '',
    categories: [],
    category: '',
    loading: false,
    error: '',
    createdProduct: '', //created product used to give a popup that the product has been created
    getaRedirect: false,
    formData: new FormData(),
  });

  const [keywords, setKeywords] = useState([]);

  const {
    name,
    description,
    price,
    stock,
    categories,
    error,
    sku,
    createdProduct,
    getaRedirect,
    formData,
  } = values;

  const handleDelete = (i) => {
    setKeywords(keywords.splice(i, 1) !== undefined ? keywords : keywords);
    setValues({
      ...values,
      keywords:
        values.keywords.splice(i, 1) !== undefined
          ? values.keywords
          : values.keywords,
    });
  };
  const handleAddition = async (newTag) => {
    setKeywords(keywords.concat([newTag]));
    setValues({ ...values, keywords: values.keywords.concat([newTag.text]) });
  };

  const preload = (productId) => {
    getSingleProduct(productId).then(async (data) => {
      if (data)
        if (data.error) {
          setValues({
            ...values,
            error: data.error,
          });
        } else {
          const categories = await preloadCategories();
          setValues({
            ...values,
            name: data.name,
            description: data.description,
            price: data.price,
            category: data.category._id,
            categories: categories,
            stock: data.stock,
            sku: data.sku,
            keywords: data.keywords,
            formData: new FormData(),
          });
          setKeywords(
            data.keywords.map((item, i) => {
              return { id: `${i}`, text: item };
            })
          );
        }
    });
  };

  const preloadCategories = async () => {
    return new Promise((resolve, reject) => {
      getAllCategories().then((data) => {
        if (data)
          if (data.error) {
            setValues({
              ...values,
              error: data.error,
            });
          } else {
            resolve(data);
            // setValues({
            //   ...values,
            //   categories: data,
            //   formData: new FormData(),
            // });
          }
      });
    });
  };

  //We have to call these preload in useEffect() by react Hooks
  useEffect(() => {
    preload(match.params.productId);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    formData.set('keys', values.keywords);
    setValues({ ...values, error: '', loading: true });
    updateProduct(user._id, match.params.productId, token, formData).then(
      (data) => {
        if (data)
          if (data.error) {
            setValues({ ...values, error: data.error });
          } else {
            setValues({
              ...values,
              name: '',
              description: '',
              price: '',
              stock: '',
              photo: '',
              photo2: '',
              photo3: '',
              photo4: '',
              photo5: '',
              loading: false,
              createdProduct: data.name,
              getaRedirect: true,
            });
          }
      }
    );
  };

  const handleChange = (name) => (event) => {
    const value = ['photo', 'photo2', 'photo3', 'photo4', 'photo5'].includes(
      name
    )
      ? event.target.files[0]
      : event.target.value;

    formData.set(name, value);

    setValues({ ...values, [name]: value });
  };

  const successMessage = () => {
    return (
      <div
        className='alert alert-success mt-3'
        style={{ display: createdProduct ? '' : 'none' }}
      >
        <h4>{createdProduct} Updated Successfully</h4>
      </div>
    );
  };

  const redirectingToProducts = () => {
    if (getaRedirect) {
      setTimeout(() => {
        window.location.assign('/admin/products');
      }, 1000);
    }
  };

  const errorMessage = () => {
    return (
      <div
        className='alert alert-danger mt-3'
        style={{ display: error ? '' : 'none' }}
      >
        <h4>{createdProduct} Failed To Create</h4>
      </div>
    );
  };
  const createProductForm = () => (
    <form>
      {successMessage()}
      {redirectingToProducts()}
      {errorMessage()}
      <span>Post photo</span>
      <div className='form-group'>
        <img
          src={`${API}/product/photo/${match.params.productId}?photoIndex=1`}
          alt='productimage'
          className='img-fluid'
          style={{
            marginLeft: 'auto',
            width: '6rem',
            height: '6rem',
          }}
        />
        <input
          onChange={handleChange('photo')}
          type='file'
          name='photo'
          accept='image'
          placeholder='Upload photo 1'
        />
      </div>
      <div className='form-group'>
        <img
          src={`${API}/product/photo/${match.params.productId}?photoIndex=2`}
          alt='productimage'
          className='img-fluid'
          style={{
            marginLeft: 'auto',
            width: '6rem',
            height: '6rem',
          }}
        />
        <input
          onChange={handleChange('photo2')}
          type='file'
          name='photo2'
          accept='image'
          placeholder='Upload photo 2'
        />
      </div>
      <div className='form-group'>
        <img
          src={`${API}/product/photo/${match.params.productId}?photoIndex=3`}
          alt='productimage'
          className='img-fluid'
          style={{
            marginLeft: 'auto',
            width: '6rem',
            height: '6rem',
          }}
        />
        <input
          onChange={handleChange('photo3')}
          type='file'
          name='photo3'
          accept='image'
          placeholder='Upload photo 3'
        />
      </div>
      <div className='form-group'>
        <img
          src={`${API}/product/photo/${match.params.productId}?photoIndex=4`}
          alt='productimage'
          className='img-fluid'
          style={{
            marginLeft: 'auto',
            width: '6rem',
            height: '6rem',
          }}
        />
        <input
          onChange={handleChange('photo4')}
          type='file'
          name='photo4'
          accept='image'
          placeholder='Upload photo 4'
        />
      </div>
      <div className='form-group'>
        <img
          src={`${API}/product/photo/${match.params.productId}?photoIndex=5`}
          alt='productimage'
          className='img-fluid'
          style={{
            marginLeft: 'auto',
            width: '6rem',
            height: '6rem',
          }}
        />
        <input
          onChange={handleChange('photo5')}
          type='file'
          name='photo5'
          accept='image'
          placeholder='Upload photo 5'
        />
      </div>
      <div className='form-group'>
        <input
          onChange={handleChange('name')}
          name='name'
          className='form-control'
          placeholder='Name'
          value={name}
        />
      </div>
      <div className='form-group'>
        <textarea
          onChange={handleChange('description')}
          name='description'
          className='form-control'
          placeholder='Description'
          value={description}
        />
      </div>
      <div className='form-group'>
        <input
          onChange={handleChange('price')}
          type='number'
          name='price'
          className='form-control'
          placeholder='Price'
          value={price}
        />
      </div>
      <div className='form-group'>
        <select
          onChange={handleChange('category')}
          name='category'
          className='form-control'
          placeholder='Category'
        >
          <option>Select</option>
          {categories &&
            categories.map((cate, index) => (
              <option key={index} value={cate._id}>
                {cate.name}
              </option>
            ))}
        </select>
      </div>
      <div className='form-group'>
        <input
          onChange={handleChange('stock')}
          type='number'
          name='stock'
          className='form-control'
          placeholder='Stock'
          value={stock}
        />
      </div>
      <div className='form-group'>
        <input
          onChange={handleChange('sku')}
          type='text'
          name='sku'
          className='form-control'
          placeholder='SKU'
          value={sku}
        />
      </div>
      <ReactTags
        tags={keywords}
        delimiters={delimiters}
        classNames={{
          tags: 'tagsClass',
          tagInput: 'tagInputClass',
          tagInputField: 'tagInputFieldClass',
          selected: 'selectedClass',
          tag: 'tagClass',
          remove: 'removeClass',
          suggestions: 'suggestionsClass',
          activeSuggestion: 'activeSuggestionClass',
        }}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
      />

      <button
        type='submit'
        onClick={onSubmit}
        className='btn btn-outline-primary mb-3'
      >
        Update Product
      </button>
    </form>
  );

  return (
    <Dashboard>
      <div className='container h-100'>
        <div className='row justify-content-center align-item-center'>
          <div className='col-12'>
            <p className='h3 text-gray-800 font-weight-bold'>Update Product</p>
            <hr />
          </div>
        </div>
        <div className='row text-white rounded'>
          <div className='col-md-8 offset-md-2'>{createProductForm()}</div>
        </div>
      </div>
    </Dashboard>
  );
};

export default UpdateProduct;
