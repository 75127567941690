import React, { useState } from 'react';
import { createReview } from './helper/adminapicall';
import { isAuthenticated } from '../auth/helper';
import Dashboard from './Dashboard';
import { toast, ToastContainer } from 'react-toastify';

const AddReview = () => {
  const { user, token } = isAuthenticated();
  const [values, setValues] = useState({
    name: '',
    content: '',
    photo: '',
    company: '',
    loading: false,
    error: '',
    getaRedirect: false,
    formData: new FormData(),
  });

  const { name, content, company, formData } = values;

  const onSubmit = (e) => {
    e.preventDefault();

    if (values?.photo?.size > 1 * 1024 * 1024 || values?.photo?.size <= 0) {
      toast.error('Please Enter a valid photo with size less than 1mb');
      return null;
    }

    if (values?.name?.toString()?.length < 5) {
      toast.error('Please Enter a valid name');
      return null;
    }

    if (values?.content?.toString()?.length === 0) {
      toast.error('Please enter some valid content');
      return null;
    }

    if (values?.company?.toString()?.length === 0) {
      toast.error('Please enter some valid company or designation');
      return null;
    }

    setValues({ ...values, error: '', loading: true });
    createReview(user._id, token, formData).then((data) => {
      if (data)
        if (data.error) {
          toast.error('Failed to create review!');
        } else {
          setValues({
            ...values,
            name: '',
            content: '',
            company: '',
            photo: '',
            loading: false,
          });
          toast.success(`${data.review.name} review submitted`);
        }
    });
  };

  const handleChange = (name) => (event) => {
    const value = name === 'photo' ? event.target.files[0] : event.target.value;
    //it means we will have a value which is equal to acc to the equality of key condition and if is photo then we will get
    //the name of the file by target.files else we will find the value and save it
    formData.set(name, value);
    setValues({ ...values, [name]: value });
  };

  const createReviewForm = () => (
    <>
      <ToastContainer position='bottom-right' autoClose={5000} />
      <form>
        <div className='form-group'>
          <input
            onChange={handleChange('photo')}
            type='file'
            name='photo'
            accept='image'
            placeholder='choose a file'
          />
        </div>
        <div className='form-group'>
          <input
            onChange={handleChange('name')}
            name='name'
            className='form-control'
            placeholder='Name'
            value={name}
          />
        </div>
        <div className='form-group'>
          <input
            onChange={handleChange('company')}
            name='name'
            className='form-control'
            placeholder='Company'
            value={company}
          />
        </div>
        <div className='form-group'>
          <textarea
            onChange={handleChange('content')}
            name='content'
            className='form-control'
            placeholder='Content'
            value={content}
          />
        </div>

        <button
          type='submit'
          onClick={onSubmit}
          className='btn btn-outline-primary mb-3'
        >
          Create Review
        </button>
      </form>
    </>
  );

  return (
    <Dashboard>
      <div className='container h-100'>
        <div className='row justify-content-center align-item-center'>
          <div className='col-12'>
            <p className='h3 text-gray-800 font-weight-bold'>
              Create New Review
            </p>
            <hr />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-8 offset-md-2'>{createReviewForm()}</div>
        </div>
      </div>
    </Dashboard>
  );
};

export default AddReview;
