import React from "react";

import ReactDOM from "react-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { themeColor } from "./utility/ColorPallete";
import App from "./App";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: `${themeColor.primary}`,
    },
    secondary: {
      main: `${themeColor.secondary}`,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        padding: "0.267rem 0.53rem",
      },
    },
    MuiTableCell: {
      root: {
        textAlign: "center",
        padding: "0.8rem",
      },
    },
  },
});
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById("root")
);
