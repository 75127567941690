import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

export default function PaymentForm({ handleChange, addr }) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Payment method
      </Typography>
      <Grid container spacing={3}>
        {' '}
        <Grid item xs={12} sm={6}>
          <TextField
            required
            type="number"
            id="phone"
            name="phone"
            value={addr.phone}
            onChange={handleChange('phone')}
            label="Phone No."
            fullWidth
            autoComplete="shipping country"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="alphone"
            type="number"
            name="alphone"
            value={addr.alphone}
            onChange={handleChange('alphone')}
            label="Alternate Phone No."
            fullWidth
            autoComplete="shipping country"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="email"
            type="email"
            name="email"
            value={addr.email}
            onChange={handleChange('email')}
            label="Email"
            fullWidth
            autoComplete="shipping country"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
