import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));

export default function Review({ products, addr, price }) {
  const classes = useStyles();

  const calTotal = () => {
    var s = 0;
    products.map((e) => {
      if(e.product !== undefined)
      {
        s += e.product.price * e.quantity;
      }else{
        s+= e.price;
      }
      return null;
    });
    return s;
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <List disablePadding>
        {products&&products.map((e, i) => {
          if(e.product !== undefined)
          {
            return(
          <ListItem className={classes.listItem} key={i}>
            <ListItemText primary={e.product.name} secondary={e.product.desc} />
            <Typography variant="body2">
              Rs {e.product.price} x {e.quantity} Qty.
            </Typography>
          </ListItem>
        )}
        else{
          return(
            <ListItem className={classes.listItem} key={i}>
              <ListItemText primary={e.name} secondary={e.desc} />
              <Typography variant="body2">
                Rs {e.price} x 1 Qty.
              </Typography>
            </ListItem>
          )
        }})}
        <ListItem className={classes.listItem}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" className={classes.total}>
            {products&&products.length !== 0?`Rs ${calTotal()}`:`Rs ${price}`}
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Shipping
          </Typography>
          <Typography gutterBottom>
            {addr.fname} {addr.lname}
          </Typography>
          <Typography gutterBottom>
            {addr.line1}, {addr.line2}, {addr.city}, {addr.district},{' '}
            {addr.state}, {addr.pin}, {addr.country}
          </Typography>
          <Typography gutterBottom>Phone: {addr.phone}</Typography>
          <Typography gutterBottom>Alternate Phone: {addr.alphone}</Typography>
          <Typography gutterBottom>Email: {addr.email}</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Payment details
          </Typography>
          <Grid container>Pending: Place order to complete the payment.</Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
