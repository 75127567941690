import { API } from '../../backend';

export const payment = async (amt) => {
  try {
    const response = await fetch(`${API}/recurring/payment/`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(amt),
    });
    return response.json();
  } catch (err) {
    return console.log(err);
  }
};

export const oneTimePayment = async (amt) => {
  try {
    const response = await fetch(`${API}/payment`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(amt),
    });
    return response.json();
  } catch (err) {
    return console.log(err);
  }
};
