import React, { useEffect, useState } from 'react';
import { themeColor } from '../utility/ColorPallete';
import { payment } from './helper/paymentRhelper';

import circleLogo from '../img/1navbar-cg.png';
import { getRazorpayCreds } from './helper/Razorpay';
import { isAuthenticated } from '../auth/helper';
import { getUserById } from './helper/CartHelper';
import Gotopayments from '../user/GoToQuestionairePayment';
import Spinner from '../common/Spinner';
const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const SUBSCRIPTION_DATA = [
  {
    price: 1495,
    month: 1,
    planId: 'plan_H8QgzIodzNZQ8t',
    prevPrice: 1795,
  },
  {
    price: 1295,
    month: 3,
    planId: 'plan_H0F9MCAOwfHpwE',
    prevPrice: 1795,
  },
  {
    price: 995,
    month: 6,
    planId: 'plan_H8QiZJEcrGR4AG',
    prevPrice: 1795,
  },
];

const ADDON_SUBSCRIPTION_DATA = [
  {
    price: 2135,
    month: 1,
    planId: 'plan_H8QoMTHhTMFmPg',
    prevPrice: 2535,
  },
  {
    price: 1835,
    month: 3,
    planId: 'plan_H8Qol6HTa91Kcx',
    prevPrice: 2535,
  },
  {
    price: 1435,
    month: 6,
    planId: 'plan_H8Qp0q3zPf1GRk',
    prevPrice: 2535,
  },
];

const getSubscriptionPlan = (products) => {
  return products instanceof Array && products.length === 5
    ? ADDON_SUBSCRIPTION_DATA
    : SUBSCRIPTION_DATA;
};
const Subscription = (props) => {
  const [razorBack, setRazorBack] = useState({
    loaded: false,
    id: '',
    amount: 0,
    currency: '',
  });

  const [products, setProducts] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const { user, token } = isAuthenticated();
  const [userData, setuserData] = useState('');
  const [price, setPrice] = useState(0);
  const [plan, setPlan] = useState('');
  const [months, setMonths] = useState(0);
  const [subscriptionPlans, setSubscriptionPlans] = useState(SUBSCRIPTION_DATA);

  const preload = () => {
    getUserById(token, user._id)
      .then(async (userD) => {
        setuserData(userD.customer_id);
        if (props.products.length === 0) {
          const productsFromLocalData = JSON.parse(
            localStorage.getItem('products')
          );
          setProducts(productsFromLocalData);
        } else {
          setProducts(props.products);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    preload();
  }, [props.products]);

  useEffect(() => {
    setSubscriptionPlans(getSubscriptionPlan(props.products));
  }, [products]);

  if (isLoading) return <Spinner />;
  return (
    <div className='subscription'>
      <h3 style={{ color: `${themeColor.primaryLight}` }}>
        Choose a Subscription:
      </h3>
      <div className={`subscriptionCardBox `}>
        {getSubscriptionPlan(products).map((subscriptionData) => (
          <div
            className={`subscriptionCardInner ${
              price === subscriptionData.price && 'active'
            }`}
            style={
              price === subscriptionData.price
                ? { backgroundColor: `${themeColor.primaryLight}` }
                : { backgroundColor: `${themeColor.Dark}` }
            }
          >
            <div className='Months'>
              <p>
                <span>{subscriptionData.month}</span> Month
              </p>
            </div>
            <div className='details'>
              <hr />
              <p>
                <span>
                  <span
                    style={{
                      textDecoration: 'line-through',
                      fontSize: '1.5rem',
                      fontWeight: '100',
                    }}
                  >
                    ₹ {subscriptionData.prevPrice}
                  </span>{' '}
                  ₹ {subscriptionData.price}
                </span>
                /pm
              </p>

              <button
                onClick={() => {
                  setMonths(subscriptionData.month);
                  setPrice(subscriptionData.price);
                  setPlan(subscriptionData.planId);
                  window.scrollTo(0, window.scrollY + 400);
                }}
                disabled={price === subscriptionData.price}
              >
                {price === subscriptionData.price
                  ? 'Selected Plan'
                  : 'Choose Plan'}
              </button>
            </div>
          </div>
        ))}
      </div>
      {plan !== '' && (
        <Gotopayments
          price={price}
          plan={plan}
          userData={userData}
          month={months}
          menu={false}
          products={products}
        />
      )}
    </div>
  );
};

export default Subscription;
