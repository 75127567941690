import { API } from "../../backend";

export const getRazorpayCreds = (user, token,obj) => {
  return fetch(`${API}/recurring/payment/3/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body:JSON.stringify(obj)
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
