export const getGaugePercentageSensitive = (value) => {
  const index = [
    'not sensitive skin',
    'little sensitive skin',
    'very sensitive skin',
  ].indexOf(value);
  if (index >= 0) {
    return index / 2;
  }
  return 0;
};

export const getGaugePercentagePimples = (value) => {
  const index = [
    'no pimples',
    'rare pimples',
    'monthly pimples',
    'weekly pimples',
    'often get pimples',
  ].indexOf(value);
  if (index >= 0) {
    return index / 4;
  }
  return 0;
};

export const getGaugePercentageBMI = (weight, height) => {
  if (height === 0 || weight === 0) return 0;
  let bmi = Number(weight) / ((Number(height) * Number(height)) / 10000);
  if (bmi) {
    let percentage = bmi / 56;
    if (percentage > 1) {
      return 1;
    }
    return percentage;
  }
  return 0;
};
