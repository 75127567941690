import React, { useState } from 'react';
import { getAllBlogs } from '../../core/helper/blogHelper';
import { useEffect } from 'react';
import { isAuthenticated } from '../../auth/helper';
import { deleteBlog, deleteComment } from '../helper/adminapicall';

const BlogDelete = ({ reload = undefined, setReload = (f) => f }) => {
  const [blogs, setBlogs] = useState([]);
  const { user, token } = isAuthenticated();
  const Month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const preload = () => {
    getAllBlogs().then((data) => {
      if (data)
        if (data.error) {
          console.log(data.error);
        } else {
          setBlogs(data.blogs);
        }
    });
  };

  useEffect(() => {
    preload();
  }, []);

  const deleteThisBlog = (blogId) => {
    deleteBlog(user._id, blogId, token).then((data) => {
      if (data)
        if (data.error) {
          console.log(data.error);
        } else {
          preload();
          setReload(!reload);
        }
    });
  };

  const deleteThisComment = (blogId, commentid) => {
    const comment = {
      cid: commentid,
    };
    deleteComment(blogId, token, commentid).then((data) => {
      if (data)
        if (data.error) {
          console.log(data.error);
        } else {
          preload();
          setReload(!reload);
        }
    });
  };

  return (
    <div>
      <div className="container h-100">
        <div
          className="row justify-content-center align-items-center p-2"
          style={{ overflowY: 'scroll', height: '75vh' }}
        >
          <div className="col-12">
            <p className="h3 text-gray-800 font-weight-bold">Delete Blogs</p>
            <hr />
          </div>

          {blogs.map((blog, index) => {
            return (
              <div
                className="col-12 align-self-center shadow p-3 m-2 rounded"
                key={index}
                style={{
                  backgroundColor: '#ffffff',
                  border: '1px solid #e3e6f0',
                }}
              >
                <p className="text-muted font-weight-bolder">
                  Blog Name: {blog.title}
                </p>
                <button
                  className="btn btn-outline-danger shadow"
                  onClick={() => {
                    deleteThisBlog(blog._id);
                  }}
                >
                  Delete
                </button>
                <hr />
                <p className="text-muted font-weight-bold">Comments</p>
                <div>
                  {blog.comments.length === 0
                    ? ''
                    : blog.comments.map((obj, i) => {
                        return (
                          <div key={i}>
                            <div
                              className="py-2"
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <p style={{ fontWeight: 'bold' }}>{obj.name}</p>
                              <p className="text-muted">
                                {obj.date.substring(8, 10)}{' '}
                                {Month[parseInt(obj.date.substring(5, 7) - 1)]},{' '}
                                {obj.date.substring(0, 4)}
                              </p>
                            </div>
                            <div>
                              <p>{obj.text}</p>
                            </div>
                            <div>
                              <button
                                className="btn btn-outline-danger shadow"
                                onClick={() => {
                                  deleteThisComment(blog._id, obj._id);
                                }}
                              >
                                Delete
                              </button>
                            </div>
                            <hr />
                          </div>
                        );
                      })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BlogDelete;
