import React, { useEffect } from 'react';
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { AiOutlineRightCircle } from 'react-icons/ai';
import Base from '../core/Base';

const PrivacyPolicy = () => {
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);
  return (
    <Base>
      <Grid container justify="center" className="py-5">
        <Grid item xs={10} md={8}>
          <h1 class="section-title-heading mb-4 section-title-heading-about ">
            Privacy Policy
          </h1>
          <Divider className="mb-4 mt-0" />
        </Grid>
        <Grid item xs={10} md={8}>
          <p className="privacy-policy-text text-justify">
            The terms "We" / "Us" / "Our"/ ”Company” individually and
            collectively refer to Circle Skincare and the terms
            "You"/"Your"/"Yourself" refer to the users.
          </p>
          <p className="privacy-policy-text text-justify">
            This privacy policy describes how we manage your personal
            information shared on “www.circleskincare.com”. Please feel free to
            contact us if following policy does not answer your questions. This
            policy may be amended from time to time.
          </p>
        </Grid>
        <Grid item xs={10} md={8}>
          <h1
            class="section-title-heading mt-5 mb-4 section-title-heading-about"
            style={{ fontSize: '1.5rem' }}
          >
            COLLECTION OF PERSONAL INFORMATION
          </h1>
        </Grid>
        <Grid item xs={10} md={8}>
          <p className="privacy-policy-text text-justify">
            To avail services on our website, users are requested for
            information like your name, email address, sex, age, PIN code,
            credit card or debit card details, medical records and history,
            sexual orientation, biometric information, password etc.,
            occupation, interests, and similar. The Information as supplied by
            the users enables us to improve our sites and provide users the most
            user-friendly experience. All required information is service
            dependent and we may use the above said user information to
            maintain, protect, and improve our services (including advertising
            services) and for developing new services.
          </p>
        </Grid>

        <Grid item xs={10} md={8}>
          <h1
            class="section-title-heading mt-5 mb-4 section-title-heading-about"
            style={{ fontSize: '1.5rem' }}
          >
            UPDATING YOUR PERSONAL INFORMATION
          </h1>
        </Grid>
        <Grid item xs={10} md={8}>
          <p className="privacy-policy-text text-justify">
            You have the right to access and edit or update your information
            except User ID. To delete the account, please contact support for
            security purposes. We will respond to your request promptly within a
            reasonable time & within working hours. Please note that we do not
            store your credit/debit card information in your personal profile.
          </p>
        </Grid>

        <Grid item xs={10} md={8}>
          <h1
            class="section-title-heading mt-5 mb-4 section-title-heading-about"
            style={{ fontSize: '1.5rem' }}
          >
            HOW WE USE YOUR PERSONAL INFORMATION
          </h1>
        </Grid>
        <Grid item xs={10} md={8}>
          <p className="privacy-policy-text text-justify">
            We do not sell, rent or disclose your information to third party.
            Following are the ways in which we use your information:
          </p>
          <List>
            <ListItem>
              <ListItemIcon>
                <AiOutlineRightCircle />
              </ListItemIcon>
              <ListItemText>Process your order.</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AiOutlineRightCircle />
              </ListItemIcon>
              <ListItemText>
                For promotional and marketing purposes.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AiOutlineRightCircle />
              </ListItemIcon>
              <ListItemText>
                Respond to your queries, services, requests and concerns.
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <AiOutlineRightCircle />
              </ListItemIcon>
              <ListItemText>
                Notify you of special offers, important events and so on.
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <AiOutlineRightCircle />
              </ListItemIcon>
              <ListItemText>
                Meet our research and data analysis needs to improvise services
                and offerings.
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <AiOutlineRightCircle />
              </ListItemIcon>
              <ListItemText>Customize your experience.</ListItemText>
            </ListItem>
          </List>
          <p className="privacy-policy-text text-justify">
            If required, Circle Skincare may disclose your personal information
            in response to lawful requests by public authorities to comply with
            a warrant, court order, or legal notice served on Circle Skincare.
            This situation may arise to protect our rights, avoid litigation,
            protect your safety or the safety of others, investigate fraud,
            and/or respond to a government request, national security, law
            enforcement, or other issues of public importance.
            {/* <a
              href='mailto:sajid@stepupanalytics.com?subject=Report+privacy+violation'
              style={{
                fontWeight: 'bold',
                color: '#222326',
              }}
            >
              sajid@stepupanalytics.com
            </a> */}
          </p>
        </Grid>
        <Grid item xs={10} md={8}>
          <h1
            class="section-title-heading mt-5 mb-4 section-title-heading-about"
            style={{ fontSize: '1.5rem' }}
          >
            INFORMATION SHARING WITH SERVICE PROVIDERS:
          </h1>
        </Grid>
        <Grid item xs={10} md={8}>
          <p className="privacy-policy-text text-justify">
            Circle Skincare uses third party payment processing companies to
            bill you for our products. These companies do not retain, share,
            store or use personally identifiable information for any other
            purpose. We also share Personal Information with certain companies
            (for example courier services) that perform services on our behalf.
            We only share the Personal Information which is necessary for them
            to perform those services and limit the use of such information only
            for Circle Skincare. We do not sell or otherwise provide Personal
            Information to other companies for the marketing of their own
            products or services.
          </p>
        </Grid>
        <Grid item xs={10} md={8}>
          <h1
            class="section-title-heading mt-5 mb-4 section-title-heading-about"
            style={{ fontSize: '1.5rem' }}
          >
            RETENTION AND DISPOSAL
          </h1>
        </Grid>
        <Grid item xs={10} md={8}>
          <p className="privacy-policy-text text-justify">
            Your personal information shall be retained only as long as
            necessary for the fulfilment of the stated purposes, and should be
            disposed thereafter. We will retain your information for as long as
            your account is active or as needed to provide you services. If you
            wish that we no longer use your information to provide you services,
            feel free to contact us via email. We will respond to your request
            within 10 working days of receipt of the request. However, we may
            also retain and use your information as necessary to comply with our
            legal obligations, resolve disputes, and enforce our agreements.
          </p>
        </Grid>
        <Grid item xs={10} md={8}>
          <h1
            class="section-title-heading mt-5 mb-4 section-title-heading-about"
            style={{ fontSize: '1.5rem' }}
          >
            THIRD PARTY LINKS
          </h1>
        </Grid>
        <Grid item xs={10} md={8}>
          <p className="privacy-policy-text text-justify">
            At our discretion, we may include or offer third-party products or
            services on our website. These third-party sites have separate and
            independent privacy policies. We therefore have no responsibility or
            liability for the content and activities of these linked sites.
            Nonetheless, we seek to protect the integrity of our site and
            welcome any feedback about these sites.
          </p>
        </Grid>
        <Grid item xs={10} md={8}>
          <h1
            class="section-title-heading mt-5 mb-4 section-title-heading-about"
            style={{ fontSize: '1.5rem' }}
          >
            CHANGES TO PRIVACY POLICY
          </h1>
        </Grid>
        <Grid item xs={10} md={8}>
          <p className="privacy-policy-text text-justify">
            From time to time we may update this Privacy Policy without any
            prior notice. Your continued subscription to our Services
            constitutes an acceptance of the current Privacy Policy and Terms &
            Conditions. Therefore, we encourage you to visit this page
            periodically to review any changes.
          </p>
        </Grid>
      </Grid>
    </Base>
  );
};

export default PrivacyPolicy;
