import React from "react";
import Menu from "./Menu";

import Footer from "./Footer";

const Base = ({ children }) => {
  return (
    <div>
      <Menu />
      <div className="header-padding">{children}</div>
      <Footer />
    </div>
  );
};

export default Base;
