import React from "react";
import Dashboard from "./Dashboard";
import AdminRender from "./components/AdminRender";
import AddBlogs from "./components/AddBlogs";

const AdminPanelPrimary = () => {
  return (
    <Dashboard>
      <AdminRender />
    </Dashboard>
  );
};

export default AdminPanelPrimary;
