import { Button, Container, Grid } from "@material-ui/core";
// import 'owl.carousel/dist/assets/owl.carousel.min.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import React, { useEffect, useState } from "react";
import { AiOutlineDeploymentUnit } from "react-icons/ai";
import { useHistory } from "react-router-dom";
// import OwlCarousel from 'react-owl-carousel';
import Slider from "react-slick";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { getAllSliderImage } from "../admin/helper/adminapicall";
import Spinner from "../common/Spinner";
import BeautyTreatmentSVG from "../img/beauty-treatment.svg";
import DermatologistSVG from "../img/dermatologist.svg";
import girlBorderImg from "../img/girlBorder.png";
import groupOne from "../img/groupIcon/Group_1.png";
import groupTwo from "../img/groupIcon/Group_2.png";
import groupThree from "../img/groupIcon/Group_3.png";
import groupLogo from "../img/groupIcon/logo_cropped.png";
import cream from "../img/hair.svg";
import img1 from "../img/homeIcon/1.png";
import img2 from "../img/homeIcon/2.png";
import img3 from "../img/homeIcon/3.png";
import img4 from "../img/homeIcon/4.png";
import img5 from "../img/homeIcon/5.png";
import img6 from "../img/homeIcon/6.png";
import QualityAssurance from "../img/qualityAssurance.svg";
import Research from "../img/research.svg";
import section3 from "../img/sect3.jpeg";
import skin from "../img/skin.svg";
import SkincareSVG from "../img/skincare.svg";
import pres from "../img/stethoscope.svg";
import { themeColor } from "../utility/ColorPallete";
import { pxToRem } from "./../utility/conversion";
import Base from "./Base";
import { subscribe } from "./helper/coreapicalls";
import { getDefaultProducts } from "./helper/questionnaire";
import NewCard from "./NewCard";
import Review from "./Review/Review";

const theme = {
  background: { height: "auto", maxHeight: "34rem" },
  sliderText: {
    alignSelf: "center",
    color: "#ffffffaa",
    fontSize: "10vh",
    fontWeight: 600,
  },
  safetyIcon: {
    width: "8rem",
    fontSize: "5rem",
    color: "#222326",
  },
  card: {
    border: "0.045rem solid white",
    alignSelf: "center",
    margin: "1vh",
  },
};
let keepInterval;
const Home = () => {
  const slideInitiator = React.useRef(null);
  const settings = {
    className: "Responsive",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    slidesToScroll: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  const [products, setProducts] = useState([]);
  const [sliderImages, setSliderImages] = useState([]);
  const [errors, setErrors] = useState(false);
  const [isloading, setIsLoading] = useState(true);
  const [email, setEmail] = useState("");
  const history = useHistory();
  const [whyCS, setWhyCS] = useState([
    {
      title: "Take Free Circle Skin Quiz",
      img: skin,
      buttonText: "Take Quiz ",
      route: "/skinquiz",
      groupBg: groupOne,
    },
    {
      title: "Get Personalized Dermatologist Recommended Regimen",
      img: pres,
      buttonText: "New Issue? Update Regimen",
      route: "/skinquiz",
      groupBg: groupTwo,
    },
    {
      title: "Follow Advice for Better Result",
      img: cream,
      buttonText: "Having difficulty? Let’s talk",
      route: "/contactUs",
      groupBg: groupThree,
    },
  ]);

  const preload = () => {
    getAllSliderImage().then(async (data) => {
      if (data)
        if (data.error) {
          console.log(data.error);
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        } else {
          setSliderImages(data);
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        }
    });
  };
  const loadAllProducts = () => {
    getDefaultProducts().then((data) => {
      if (data?.error) {
        setErrors(data?.error);
      } else {
        setProducts(data || []);
      }
    });
  };

  useEffect(() => {
    preload();
    loadAllProducts();
    window.scrollTo(0, 0);
  }, []);

  const handleChange = async (e) => {
    e.preventDefault();
    await setEmail(e.target.value);
  };

  const emailValidator = (email) => {
    return (
      email.length !== "" &&
      (email.match(/[^a-zA-Z0-9@.]+/) !== null ||
        email.match(/[a-zA-Z0-9]+@[a-zA-Z0-9]+[.][a-zA-Z]+/) === null)
    );
  };

  const subscriber = (e) => {
    e.preventDefault();
    if (emailValidator(email)) {
      toast.error("Please fill the email or enter a proper email ID.");
      return;
    }
    subscribe({ email }).then((data) => {
      if (data)
        if (data.error) {
          toast.error(data.error);
        } else {
          toast.success("Registration Successful");
        }
    });
  };
  React.useEffect(() => {
    if (!isloading && sliderImages instanceof Array && sliderImages.length > 0)
      keepInterval = setInterval(() => {
        slideInitiator.current.click();
      }, 5000);
    setTimeout(() => {
      clearTimeout(keepInterval);
    }, 15000);
  }, [isloading]);

  React.useEffect(() => {
    return () => {
      clearTimeout(keepInterval);
    };
  }, []);

  return (
    <Base>
      {isloading && <Spinner />}
      {!isloading && (
        <>
          <ToastContainer position="bottom-right" autoClose={5000} />
          <section className="carousel-home">
            <div
              id="carouselExampleIndicators"
              class="carousel slide"
              data-ride="carousel"
            >
              <div class="carousel-inner">
                {sliderImages &&
                  sliderImages instanceof Array &&
                  sliderImages.length !== 0 &&
                  sliderImages.map((e, i) => {
                    if (i === 0) {
                      return (
                        <div
                          class="carousel-item active"
                          key={i}
                          style={theme.background}
                          data-interval="1000"
                        >
                          <div
                            className=" text-center align-self-center d-flex"
                            style={{ maxHeight: "inherit" }}
                          >
                            <img
                              src={`data:image/png;base64,${e?.photoBuff}`}
                              style={{ width: "100%", objectFit: "fill" }}
                              className="img-fluid"
                              alt=".."
                            />
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          class="carousel-item"
                          key={i}
                          style={theme.background}
                          data-interval="1000"
                        >
                          <div
                            className=" text-center align-self-center d-flex"
                            style={{ maxHeight: "inherit" }}
                          >
                            <img
                              src={`data:image/png;base64,${e?.photoBuff}`}
                              style={{ width: "100%", objectFit: "fill" }}
                              className="img-fluid"
                              alt=".."
                            />
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
              <a
                className="carousel-control-prev"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="prev"
                onClick={() => {
                  clearInterval(keepInterval);
                }}
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="next"
                ref={slideInitiator}
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </section>
          <section className="takeSkinTest">
            <div className="container text-center">
              <div
                className="row mx-auto  d-flex align-items-center "
                style={{ height: "5.5rem" }}
              >
                <div className="col-12">
                  <Button
                    href="/skinquiz"
                    variant="outlined"
                    style={{
                      width: "20rem",
                      height: "3rem",
                      fontSize: pxToRem(22),
                      fontWeight: 600,
                      background: themeColor.primary,
                      color: "white",
                    }}
                  >
                    TAKE SKIN QUIZ
                  </Button>
                </div>
              </div>
            </div>
          </section>
          <section className="safety">
            <div
              className="container text-center pb-5"
              style={{ maxWidth: "inherit" }}
            >
              <div className="row mx-0 w-100 justify-content-center align-item-center py-4">
                <div className="col-12 pb-5">
                  <p className="h1 txt-black-faded font-weight-bold">
                    Your{" "}
                    <span style={{ color: `${themeColor.primaryLight}` }}>
                      Aesthetics
                    </span>{" "}
                    and Planet's{" "}
                    <span style={{ color: `${themeColor.primaryLight}` }}>
                      Safety
                    </span>{" "}
                    - Our Priority
                  </p>
                </div>
                {/* <div className="col-1"></div> */}
                <div className="col-lg-2 col-md-4 col-6 p-3">
                  <img src={img1} style={theme.safetyIcon} />
                </div>
                <div className="col-lg-2 col-md-4 col-6 p-3">
                  <img src={img2} style={theme.safetyIcon} />
                </div>
                <div className="col-lg-2 col-md-4 col-6 p-3">
                  <img src={img3} style={theme.safetyIcon} />
                </div>
                <div className="col-lg-2 col-md-4 col-6 p-3">
                  <img src={img4} style={theme.safetyIcon} />
                </div>
                <div className="col-lg-2 col-md-4 col-6 p-3">
                  <img src={img5} style={theme.safetyIcon} />
                </div>
                <div className="col-lg-2 col-md-4 col-6 p-3">
                  <img src={img6} style={theme.safetyIcon} />
                </div>
              </div>
            </div>
          </section>
          <section
            className="safety mt-2"
            style={{ backgroundColor: `${themeColor.primary}` }}
          >
            <div className="container-fluid  text-center px-0 ">
              <div className="row mx-0 w-100 justify-content-center align-item-center d-flex px-0">
                <div className="col-md-6 px-0">
                  <img
                    src={section3}
                    alt="section"
                    className="img-fluid researchImage"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="col-md-6 align-self-center">
                  <AiOutlineDeploymentUnit
                    style={{
                      marginTop: "3rem",
                      fontSize: "5.78rem",
                      animation: "linear infinite 2s animateRotate",
                    }}
                    className="txt-yellow-primary"
                  />
                  <p
                    className="h3 font-weight-bold "
                    style={{ color: `${themeColor.secondary}` }}
                  >
                    Personalized Skincare.
                  </p>
                  <p
                    className="h3 font-weight-bold "
                    style={{ color: `${themeColor.secondary}` }}
                  >
                    Just What Your Skin Needs.
                  </p>
                  <hr
                    className="mx-auto w-25"
                    style={{
                      marginBottom: "3rem",
                      height: "0.178rem",
                      backgroundColor: `${themeColor.secondary}`,
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="how bg-black-faded relDisplay py-5">
            <div className=" text-center">
              <div className="row mx-0 w-100 justify-content-center align-item-center">
                <div className="col-12 pb-1 px-0">
                  <p className="font-weight-bold h1 txt-faded-primary">
                    How Circle Skincare Works?
                  </p>
                  <hr
                    className="mx-auto w-25"
                    style={{ marginBottom: "3rem", borderColor: "#606062" }}
                  />
                </div>
                <div className="col-12 align-self-center ">
                  <div className="row mx-0 w-100 pb-5">
                    {whyCS.map((e) => {
                      return (
                        <div className="col-lg-4 p-3 highzindex">
                          <div
                            className="card p-3 mx-auto relDisplay"
                            style={
                              (theme.card,
                              {
                                borderRadius: "10%",
                                height: "22.45rem",
                                width: "22.45rem",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: `${themeColor.primaryLight}CA`,
                              })
                            }
                          >
                            <div className="card-img py-2 highzindex">
                              <img
                                src={e.img}
                                alt="Skin"
                                style={{ width: "6rem" }}
                                className="img-fluid p-3"
                              />
                            </div>
                            <div
                              className="card-body highzindex"
                              style={{ maxHeight: "9rem", padding: "0px" }}
                            >
                              <p
                                className="h6 txt-black-faded  pb-3"
                                style={{
                                  fontSize: "1.3rem",
                                  paddingTop: "0.4445rem",
                                  paddingBottom: "0.4445rem",
                                  color: `${themeColor.secondary}`,
                                }}
                              >
                                {e.title}
                              </p>
                              <button
                                className="btn btn-outline-light btn-sm hvr-grow"
                                style={{ fontSize: "2vh" }}
                                onClick={() => history.push(e.route)}
                              >
                                {e.buttonText}
                              </button>
                            </div>
                            <img
                              src={e.groupBg}
                              alt="groupIcon"
                              className="img-fluid absoluteimage"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <img
              src={groupLogo}
              alt="groupIcon"
              className="img-fluid absoluteImageBottom px-4 w-100"
            />
          </section>
          <section>
            <Review />
          </section>
          <section>{/* <Subscription/> */}</section>
          <section className=" bg-black-faded">
            <div className=" py-5">
              <Grid
                container
                alignItems="center"
                className="p-lg-5 w-100"
                justify="center"
                spacing={3}
              >
                <Grid item xs={10}>
                  <h1 className="font-weight-bold h1 txt-faded-primary text-center">
                    Why Circle Skincare?
                  </h1>
                  <hr
                    className="mx-auto w-25"
                    style={{
                      marginBottom: "3rem",
                      height: "0.178rem",
                      borderColor: "#606062",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={7}>
                  <Container>
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={10} md={6}>
                        <div className="card-features">
                          <div>
                            <img src={SkincareSVG} alt=".." />
                          </div>
                          <p className="txt-black-faded text-justify mb-0">
                            Ensures Product Efficacy And Formulation Safety
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={10} md={6}>
                        <div className="card-features">
                          <div>
                            <img src={QualityAssurance} alt="derma" />
                          </div>

                          <p className="txt-black-faded text-justify mb-0">
                            Manufactured and Delivered Under Strict Safety And
                            Quality Standards
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={10} md={6}>
                        <div className="card-features">
                          <div>
                            <img src={Research} alt="derma" />
                          </div>

                          <p className="txt-black-faded text-justify mb-0">
                            Personalized Regimen Recommended by Dermatologists
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={10} md={6}>
                        <div
                          className="card-features"
                          style={{ borderColor: `${themeColor.primary}` }}
                        >
                          <div>
                            <img src={BeautyTreatmentSVG} alt="derma" />
                          </div>

                          <p className="txt-black-faded text-justify mb-0">
                            Focuses On Sustained Solution For Exact Skin Needs
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={10} md={6}>
                        <div className="card-features">
                          <div>
                            <img src={DermatologistSVG} alt="derma" />
                          </div>
                          <p className="txt-black-faded text-justify mb-0">
                            Delivers Just What Your Skin Needs
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                  </Container>
                </Grid>
                <Grid item xs={10} lg={5} className="text-center">
                  <img src={girlBorderImg} alt=".." width="90%" />
                </Grid>
              </Grid>
            </div>
          </section>
          {products && products.length > 0 && (
            <section className="prodCard">
              <div className="container-fluid p-5">
                <div className="row mx-0 justify-content-center align-item-center">
                  <div className="col-12 py-3">
                    <p className="font-weight-bold h1 txt-black-faded text-center">
                      Products For All Skin Types
                    </p>
                    <hr
                      className="divider mx-auto"
                      style={{ borderColor: `${themeColor.primary}` }}
                    />
                    <p
                      className="font-weight-bold h4 txt-black-faded text-center"
                      style={{ opacity: "0.5" }}
                    >
                      Serving your daily skin needs while ensuring safety is
                      most important for us. Dermatologist recommended products
                      for all skin types
                    </p>
                  </div>
                </div>
                <div className="container-fluid p-0 m-0">
                  <div
                    className="home_products row w-100 m-0 p-0"
                    style={{ justifyContent: "center" }}
                  >
                    <Slider {...settings}>
                      {products.map((product, i) => (
                        <div className=" p-0">
                          <NewCard product={product} />
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </section>
          )}
          <section className="newsletter">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="content">
                    <form>
                      <h2>SUBSCRIBE TO OUR NEWSLETTER</h2>
                      <div className="input-group">
                        <input
                          onChange={handleChange}
                          type="email"
                          className="form-control"
                          placeholder="Enter your email"
                        />
                        <span className="input-group-btn">
                          <button
                            className="btn"
                            style={{ backgroundColor: `${themeColor.primary}` }}
                            onClick={subscriber}
                          >
                            Subscribe Now
                          </button>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </Base>
  );
};

export default Home;
