import { API } from "../../backend";

export const submitQuestionnaire = (questions)=>{
    return fetch(`${API}/questionnaire/create`,{
        method:"POST",
        headers:{
            Accept:"application/json",
            "Content-Type":"application/json"
        },
        body:JSON.stringify(questions)
    }).then(response=>{
        return response.json();
    }).catch(err => console.log(err));
}

export const getDefaultProducts = ()=>{
    return fetch(`${API}/products/default`,{
        method:"GET",
    }).then(response=>{
        return response.json();
    }).catch(err => console.log(err));
}