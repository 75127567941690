import { withStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { forgotpassword } from '../auth/helper';
import Base from '../core/Base';
import signinBg from '../img/signin.png';
import { themeColor } from '../utility/ColorPallete';

const styles = (theme) => ({
  root: {
    height: '92vh',
  },
  image: {
    backgroundImage: `url(${signinBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#FFF',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: themeColor.primary,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  links: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: themeColor.primary,
    },
  },
});

const ForgotPassword = ({ classes }) => {
  const history = useHistory();
  const [values, setValues] = useState({
    email: '',
    error: '', //redirect to user dash
  });

  const { email, password } = values;

  const handleChange = (key) => (event) => {
    setValues({
      ...values,
      error: '',
      [key]: event.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      error: false,
    });
    forgotpassword({ email })
      .then((data) => {
        if (data)
          if (data.error) {
            toast.error(data.error);
            setValues({
              ...values,
              error: data.error,
            });
          } else {
            toast.success('Please check your mail, to reset password.');
            setTimeout(() => {
              history.push('/signin');
            }, 2000);
          }
      })
      .catch((err) => {
        console.log(err, 'API');
        toast.error('Email Not Found');
      });
  };

  return (
    <Base>
      <ToastContainer position="bottom-right" autoClose={5000} />
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar} />
            <Typography component="h1" variant="h5">
              Enter Your Registered Email ID
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                type="email"
                value={email}
                placeholder="Enter Email Id"
                onChange={handleChange('email')}
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={onSubmit}
              >
                Submit
              </Button>
            </form>
          </div>
        </Grid>
      </Grid>
    </Base>
  );
};

export default withStyles(styles, { withTheme: true })(ForgotPassword);
