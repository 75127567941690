import React, { useState, useEffect } from 'react';
import Dashboard from './Dashboard';
import { isAuthenticated } from '../auth/helper';
import {
  AddNewSliderImage,
  getAllSliderImage,
  deleteSlideImage,
} from './helper/adminapicall';
import { API } from '../backend';

const AddSliderImage = () => {
  const { user, token } = isAuthenticated();
  const [sliderImages, setSliderImages] = useState([]);
  const [values, setValues] = useState({
    photo: '',
    error: '',
    loading: false,
    authCreated: false,
    formData: new FormData(),
  });

  const preload = () => {
    getAllSliderImage().then(async (data) => {
      if (data)
        if (data.error) {
          console.log(data.error);
        } else {
          await setSliderImages(data);
        }
    });
  };

  useEffect(() => {
    preload();
  }, []);

  const { error, loading, authCreated, formData } = values;

  if (loading) {
    return <h1>Loading</h1>;
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    setValues({ ...values, error: '', loading: true });
    await AddNewSliderImage(user._id, token, formData).then((data) => {
      if (data)
        if (data.error) {
          setValues({ ...values, error: data.error });
        } else {
          setValues({
            ...values,
            photo: '',
            error: '',
            authCreated: true,
            loading: false,
          });
        }
    });
    preload();
  };

  const deleteSlide = async (id) => {
    await deleteSlideImage(user._id, token, id).then((data) => {
      if (data)
        if (data.error) {
          console.log(data.error);
        } else {
          preload();
        }
    });
  };

  const handleChange = (name) => (event) => {
    const value = name === 'photo' ? event.target.files[0] : event.target.value;
    formData.set(name, value);

    setValues({ ...values, [name]: value });
  };

  const successMessage = () => {
    return (
      <div
        className="alert alert-primary "
        style={{ display: authCreated ? '' : 'none' }}
      >
        <h6>Successfully Uploaded</h6>
      </div>
    );
  };

  const errorMessage = () => {
    return (
      <div
        className="alert alert-danger mt-3"
        style={{ display: error ? '' : 'none' }}
      >
        <h4>Failed To Upload Image</h4>
      </div>
    );
  };

  const createBlogForm = () => {
    return (
      <form>
        {successMessage()}
        {errorMessage()}
        <span>Home Slider Image</span>
        <div className="form-group">
          <div class="custom-file">
            <input
              onChange={handleChange('photo')}
              type="file"
              name="photo"
              accept="image"
              class="custom-file-input"
              id="inputGroupFile01"
              aria-describedby="inputGroupFileAddon01"
              placeholder="choose a file"
            />
            <label class="custom-file-label" for="inputGroupFile01">
              Upload Slider Image
            </label>
          </div>
        </div>
        <button
          type="submit"
          onClick={onSubmit}
          className="btn btn-outline-primary mb-3 mx-auto"
        >
          Upload
        </button>
      </form>
    );
  };
  return (
    <Dashboard>
      <div className="container h-100">
        <div className="row justify-content-center align-item-center">
          <div className="col-12">
            <p className="h3 text-gray-800 font-weight-bold">Upload Image</p>
            <hr />
          </div>
          <div className="col-md-12 align-self-center pb-5">
            {createBlogForm()}
          </div>
          <div className="col-12">
            <p className="h3 text-gray-800 font-weight-bold">Delete Image</p>
            <hr />
          </div>
          <div className="container px-3">
            <div className="row mx-0 justify-content-center align-items-center">
              {sliderImages.length !== 0 &&
                sliderImages.map((e, i) => (
                  <div
                    className="col-md-4 align-self-center p-3 text-center dash-del-hover"
                    key={i}
                  >
                    <img
                      src={`${API}/slider/${e._id}`}
                      alt=""
                      className="img-fluid"
                      style={{ width: 'inherit' }}
                    />
                    <div className="dash-delete-img-overlay"></div>
                    <button
                      className="btn btn-outline-dark btn-md dash-delete-slider"
                      onClick={() => {
                        deleteSlide(e._id);
                      }}
                    >
                      Delete
                    </button>
                    <p className="text-muted font-weight-bold dash-delete-slidename">
                      Slide {i + 1}
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default AddSliderImage;
