import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './core/Home';
import Signup from './user/Signup';
import Signin from './user/Signin';
import AdminRoute from './auth/helper/AdminRoutes';
import PrivateRoute from './auth/helper/PrivateRoutes';
import UserDashboard from './user/UserDashBoard';
import AddCategory from './admin/AddCategory';
import ManageCategories from './admin/ManageCategories';
import AddProduct from './admin/AddProduct';
import ManageProducts from './admin/ManageProducts';
import UpdateProduct from './admin/UpdateProduct';
import UpdateCategory from './admin/UpdateCategory';
import Cart from './core/Cart';
import Blog from './core/Blog';
import AdminPanelPrimary from './admin/AdminPanelPrimary';
import EachBlog from './core/EachBlog';
import AddNewBlogs from './admin/AddNewBlogs';
import DeleteBlog from './admin/DeleteBlog';
import AddNewAuthor from './admin/AddNewAuthor';
import Questionaire from './core/questionaire/Questionaire';
import ContactUs from './core/ContactUs';
import AboutUs from './core/AboutUs';
import AddSliderImage from './admin/AddSliderImage';
import AddBrand from './admin/AddBrand';
import ManageBrand from './admin/ManageBrands';
import AddTags from './admin/AddTags';
import AddSubtags from './admin/AddSubtags';
import SingleProductCard from './core/Products/SingleProductCard';
import AllProduct from './core/Products/AllProduct';
import PaymetnR from './core/PaymetnR';
import Gotopayments from './user/Gotopayments';
import ReviewPage from './core/Review/ReviewPage';
import AddReview from './admin/AddReview';
import Checkout from './core/questionaire/Checkout';
import PrivacyPolicy from './common/PrivacyPolicy';
import RefundPolicy from './common/RefundPolicy';
import OrderHistory from './common/OrderHistory';
import Orders from './admin/Orders';
import NewsLetter from './admin/NewsLetter';
import Terms from './common/Terms';
import Faq from './common/Faq';
import ForgotPassword from './user/ForgotPassword';
import ResetPassword from './user/ResetPassword';
import ManageReview from './admin/ManageReview';
import ManageAuthors from './admin/ManageAuthors';
import { AppContext, AppStateProvider } from './contexts/AppContext';

const Routes = () => {
  return (
    <AppStateProvider>
      <Router>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/aboutUs' component={AboutUs} />
          <Route exact path='/signup' component={Signup} />
          <Route exact path='/signin' component={Signin} />
          <Route exact path='/forgotpassword' component={ForgotPassword} />
          <Route
            exact
            path='/reset-password/:resetKey'
            component={ResetPassword}
          />
          <Route exact path='/blogs' component={Blog} />
          <Route exact path='/reviews' component={ReviewPage} />
          <Route exact path='/refund-policy' component={RefundPolicy} />
          <Route exact path='/privacy-policy' component={PrivacyPolicy} />
          <Route exact path='/terms' component={Terms} />
          <Route exact path='/blogs/details/:blogId' component={EachBlog} />
          <Route exact path='/skinquiz' component={Questionaire} />
          <Route exact path='/contactUs' component={ContactUs} />
          <Route
            exact
            path='/product/:productId'
            component={SingleProductCard}
          />
          <Route
            exact
            path='/product'
            render={(props) => (
              <AppContext.Consumer>
                {(props) => {
                  return <AllProduct {...props} />;
                }}
              </AppContext.Consumer>
            )}
          />
          <Route exact path='/faq' component={Faq} />
          {/* <PrivateRoute exact path='/user/dashboard' component={UserDashboard} /> */}
          <PrivateRoute exact path='/cart' component={Cart} />
          <PrivateRoute exact path='/orders' component={OrderHistory} />
          <PrivateRoute exact path='/user/checkout' component={Gotopayments} />
          <PrivateRoute
            exact
            path='/user/products/checkout'
            component={Checkout}
          />
          <PrivateRoute exact path='/user/payments' component={PaymetnR} />
          <AdminRoute
            exact
            path='/admin/dashboard'
            component={AdminPanelPrimary}
          />
          <AdminRoute
            exact
            path='/admin/dashboard/blogs/add'
            component={AddNewBlogs}
          />
          <AdminRoute
            exact
            path='/admin/dashboard/blogs/delete'
            component={DeleteBlog}
          />
          <AdminRoute
            exact
            path='/admin/create/category'
            component={AddCategory}
          />
          <AdminRoute exact path='/admin/orders' component={Orders} />
          <AdminRoute exact path='/admin/newsletter' component={NewsLetter} />
          <AdminRoute exact path='/admin/create/review' component={AddReview} />
          <AdminRoute exact path='/admin/reviews' component={ManageReview} />
          <AdminRoute
            exact
            path='/admin/categories'
            component={ManageCategories}
          />
          <AdminRoute
            exact
            path='/admin/create/product'
            component={AddProduct}
          />
          <AdminRoute exact path='/admin/products' component={ManageProducts} />
          <AdminRoute
            exact
            path='/admin/product/update/:productId'
            component={UpdateProduct}
          />
          <AdminRoute
            exact
            path='/admin/category/update/:categoryId'
            component={UpdateCategory}
          />
          <AdminRoute exact path='/admin/create/brand' component={AddBrand} />
          <AdminRoute exact path='/admin/brands' component={ManageBrand} />
          <AdminRoute
            exact
            path='/admin/dashboard/blogs/add/author'
            component={AddNewAuthor}
          />
          <AdminRoute
            exact
            path='/admin/dashboard/blogs/authors'
            component={ManageAuthors}
          />
          <AdminRoute
            exact
            path='/admin/dashboard/homeslider'
            component={AddSliderImage}
          />

          {/* <Route exact path="/admin/create/blog" component={AddBlogs} /> */}
        </Switch>
      </Router>
    </AppStateProvider>
  );
};

export default Routes;
