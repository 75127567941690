import React, { useState } from 'react';
import Base from '../core/Base';
import { Link, Redirect } from 'react-router-dom';
import { CLIENT_ID } from '../backend';

import {
  signin,
  authenticate,
  isAuthenticated,
  googleLogin,
} from '../auth/helper';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import { themeColor } from '../utility/ColorPallete';
import signinBg from '../img/signin.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleLogin } from 'react-google-login';

const styles = (theme) => ({
  root: {
    height: '92vh',
  },
  image: {
    backgroundImage: `url(${signinBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#FFF',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: themeColor.primary,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1, 2),
  },
  links: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: themeColor.primary,
    },
  },
});

const Signin = ({ classes }) => {
  const [values, setValues] = useState({
    email: '',
    password: '',
    rememberMe: true,
    error: '',
    activeOtp: true,
    loading: false, //to wait use till authenticated
    didRedirect: false, //redirect to user dash
  });

  const { email, password, didRedirect, rememberMe, activeOtp } = values;
  const [otp, setOtp] = useState(null);

  //Store the token here
  const { user } = isAuthenticated();

  const handleChange = (key) => (event) => {
    setValues({
      ...values,
      error: '',
      [key]: event.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      error: false,
      loading: true,
    });
    if (
      !values.email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      toast.error('Enter a valid Email Id');
      return null;
    }
    signin({ email, password, rememberMe })
      .then((data) => {
        if (data)
          if (data.error) {
            toast.error(data.error);
            setValues({
              ...values,
              error: data.error,
              loading: false,
            });
          } else {
            authenticate(data, values.rememberMe, () => {
              setValues({
                ...values,
                email: '',
                password: '',
                rememberMe: false,
                error: '',
                didRedirect: true,
              });
            }); //if we use next() we can fire a callback
          }
      })
      .catch((err) => {
        toast.error('Signin Failed');
      });
  };
  const responseSuccessGoogle = (response) => {
    const tokenId = { tokenId: response.tokenId };
    googleLogin(tokenId).then((data) => {
      if (data && data.error) {
        toast.error(data.error);
        setValues({
          ...values,
          error: data.error,
          loading: false,
        });
      } else {
        authenticate(data, values.rememberMe, () => {
          setValues({
            ...values,
            email: '',
            password: '',
            rememberMe: false,
            error: '',
            didRedirect: true,
          });
        }); //if we use next() we can fire a callback
      }
    });
  };
  const responseErrorGoogle = (response) => {

    console.log('Try logging in again');
  };

  const performRedirect = () => {
    if (didRedirect) {
      if (user && user.role?.toString() === '1') {
        //user comes from is authenticated
        return <Redirect to='/admin/dashboard/' />;
      } else {
        return <Redirect to='/' />;
      }
    }

    if (isAuthenticated()) {
      return <Redirect to='/' />;
    }
  };

  return (
    <Base>
      <ToastContainer position='bottom-right' autoClose={5000} />
      <Grid container component='main' className={classes.root}>
        {performRedirect()}
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar} />
            <Typography component='h1' variant='h5'>
              Sign in
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                type='email'
                value={email}
                placeholder='Enter Email Id'
                onChange={handleChange('email')}
                autoFocus
              />
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                placeholder='Enter Password'
                value={password}
                onChange={handleChange('password')}
                autoComplete='current-password'
              />
              <FormControlLabel
                control={<Checkbox value='remember' color='primary' />}
                checked={rememberMe}
                onClick={(event) => {
                  setValues({ ...values, rememberMe: event.target.checked });
                }}
                label='Remember me'
              />
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
                onClick={onSubmit}
              >
                Sign In
              </Button>

              <GoogleLogin
                clientId={CLIENT_ID}
                render={(renderProps) => (
                  <button
                    type='button'
                    className='py-1'
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    style={{
                      backgroundColor: 'rgb(255, 255, 255)',
                      display: 'inline-flex',
                      alignItems: 'center',
                      color: 'rgba(0, 0, 0, 0.54)',
                      boxShadow:
                        'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px',
                      borderRadius: '2px',
                      border: '1px solid transparent',
                      fontSize: '14px',
                      fontWeight: 500,
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      placeContent: 'center',
                    }}
                  >
                    <div
                      style={{
                        marginRight: '10px',
                        background: 'rgb(255, 255, 255)',
                        borderRadius: '2px',
                      }}
                    >
                      <svg
                        width='18'
                        height='18'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g fill='#000' fill-rule='evenodd'>
                          <path
                            d='M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z'
                            fill='#EA4335'
                          ></path>
                          <path
                            d='M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z'
                            fill='#4285F4'
                          ></path>
                          <path
                            d='M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z'
                            fill='#FBBC05'
                          ></path>
                          <path
                            d='M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z'
                            fill='#34A853'
                          ></path>
                          <path fill='none' d='M0 0h18v18H0z'></path>
                        </g>
                      </svg>
                    </div>
                    <span style={{ padding: '5px', fontWeight: 800 }}>
                      SIGN IN USING GOOGLE
                    </span>
                  </button>
                )}
                buttonText='Login'
                onSuccess={responseSuccessGoogle}
                onFailure={responseErrorGoogle}
                cookiePolicy={'single_host_origin'}
              />
              <Grid container style={{ padding: '1.2rem 0 0.4rem'}}>
                <Grid item xs>
                  <Link to='/forgotpassword' variant='body2'>
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link to='/signup' variant='body2'>
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Grid>
      </Grid>
    </Base>
  );
};

export default withStyles(styles, { withTheme: true })(Signin);
