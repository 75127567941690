import React, { useState } from 'react';
import { API } from '../../backend';
import { themeColor } from '../../utility/ColorPallete';
import Base from '.././Base';
import circle_translucent from '../../img/groupIcon/logo_cropped.png';
import { getAllReviews } from '../helper/reviewHelper';
import Spinner from '../../common/Spinner';
import { Button } from '@material-ui/core';

const ReviewPage = () => {
  const [reviews, setReviews] = useState([]);
  const [errors, setErrors] = useState(false);
  const [isloading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const loadAllReviews = (page, pageSize) => {
    getAllReviews(page, pageSize).then((data) => {
      if (data)
        if (data.error) {
          setErrors(data.error);
          setIsLoading(false);
        } else {
          setReviews(data.reviews);
          setTotalCount(data?.totalCount);
          setIsLoading(false);
        }
    });
  };
  React.useEffect(() => {
    loadAllReviews(page, pageSize);
  }, [page]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isloading && <Spinner />}
      {!isloading && (
        <Base>
          <div
            className='container-fluid px-0 m-0 pb-5'
            style={{
              backgroundColor: themeColor.lightContrast,
            }}
          >
            <div className='row py-5 w-100 m-0'>
              <div className='col-12 pb-2 pt-5 text-center'>
                <img
                  src={circle_translucent}
                  alt='cursiveLogo'
                  className='fadeRightAnim img-fluid px-5 pb-3'
                />
                <p
                  className='font-weight-bold h1  txt-black-faded text-center'
                  style={{ color: '#FFFEF2' }}
                >
                  Customer Reviews
                </p>
                <hr
                  className='divider mx-auto'
                  style={{ height: '0.178rem', borderColor: '#FFFEF2' }}
                />
              </div>
              {reviews.length > 0 &&
                reviews.reverse().map((review, i) => {
                  return (
                    <figure key={i} className=' col-md-4 snip1192'>
                      <blockquote>
                        {`${review.name}: ${review.content}`}
                      </blockquote>
                      <div className='author'>
                        <img
                          src={`${API}/review/photo/${
                            review ? review._id : ''
                          }`}
                          alt='sq-sample1'
                        />
                        <h5>
                          {review.name} <span> {review.company}</span>
                        </h5>
                      </div>
                    </figure>
                  );
                })}
              {reviews.length == 0 && (
                <div className='mx-auto  lead text-white'>No Reviews Yet</div>
              )}
            </div>
            {page * pageSize < totalCount && (
              <div className=' text-center row mx-0 py-4 p-1'>
                <div
                  className='col-12 p-3'
                  style={{ textAlign: 'center', marginBottom: '2rem' }}
                >
                  <Button
                    style={{
                      cursor: 'pointer',
                      color: 'white',
                      border: '0.1rem solid white',
                      borderRadius: '5px',
                    }}
                    onClick={() => {
                      setPage(page + 1);
                    }}
                  >
                    Load More
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Base>
      )}
    </>
  );
};

export default ReviewPage;
