import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
// import Link from "@material-ui/core/Link";
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isAuthenticated } from '../auth/helper';
import { getDbCart } from '../core/helper/CartHelper';
import { createOrder } from '../core/helper/OrderHelper';
import { oneTimePayment } from '../core/helper/paymentRhelper';
import Menu from '../core/Menu';
import { themeColor } from '../utility/ColorPallete';
import AddressForm from './AddressForm';
import Checkout from './Checkout';
import Review from './Review';
import logo from '../img/1navbar-cg.png';
import Spinner from '../common/Spinner';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: '100%',
  },
  paper: {
    margin: '2rem',
    padding: '2rem',
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ['Shipping address', 'Payment details', 'Review your order'];

export default function Gotopayments(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [products, setProducts] = useState([]);
  const [order, setOrder] = useState('');
  const [flag, setFlag] = useState(true);
  const [productArray, setProductArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addr, setAddr] = useState({
    fname: '',
    lname: '',
    line1: '',
    line2: '',
    city: '',
    district: '',
    state: '',
    pin: '',
    country: '',
    phone: '',
    alphone: '',
    email: '',
  });

  const preload = (token, user) => {
    if (user && props.products === undefined) {
      getDbCart(token, user._id).then((data) => {
        if (data) {
          setProducts(data.data);
        }
      });
    } else {
      var arr = [];
      props.products.map((o, i) => {
        var obj = { product: o, quantity: 1, sellingPrice: o.price };
        arr.push(obj);
      });
      setProducts(arr);
    }
  };
  const { token, user } = isAuthenticated();
  useEffect(() => {
    preload(token, user);
  }, []);
  const history = useHistory();
  const handleNext = () => {
    if (
      activeStep == 0 &&
      addr?.fname !== '' &&
      addr?.lname !== '' &&
      addr?.line1 !== '' &&
      addr?.city !== '' &&
      addr?.district !== '' &&
      addr?.country !== '' &&
      addr?.pin !== ''
    ) {
      setActiveStep(activeStep + 1);
    } else if (
      activeStep == 1 &&
      addr?.phone.match(/^\d{10}$/) &&
      addr?.email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      setActiveStep(activeStep + 1);
    } else if (activeStep == 2) {
      setActiveStep(activeStep + 1);
    } else {
      toast.error('Fill all the required fields and enter valid inputs.');
    }
    if (activeStep === steps.length - 1) {
      if (products.length == 0) {
        history.push('/cart');
      }
      setFlag(false);
    }
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const displayRazorPay = async (amt = 10) => {
    setIsLoading(true);
    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js'
    );
    if (!res) {
      toast.error('RazorPay SDK failed to load. Are you online?');
      return;
    }
    const calTotal = () => {
      var s = 0;
      products.map((e) => {
        if (e.product) {
          s += e.product.price * e.quantity;
          return null;
        } else {
          s += e.price * 1;
          return null;
        }
      });
      return s;
    };
    const amtCal = await calTotal();
    const data = await oneTimePayment({ amt: amtCal });
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: amtCal,
      currency: 'INR',
      name: 'Circle Skincare',
      description: 'Thank you for purchasing!',
      image: 'http://demo.circleskincare.com/favicon.png',
      order_id: data.id,
      handler: function (response) {
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        //   console.log(response)
        // purchaseCount();
        // getRedirect();

        createOrder(user._id, token, {
          products: products,
          amount: amtCal,
          address: addr,
          rzpOrderId: response.razorpay_order_id,
          rzpPaymentId: response.razorpay_payment_id,
          rzpSignatuure: response.razorpay_signature,
        }).then((data1) => {
          if (data1) {
            if (data1.error) {
              toast.error(
                `Order could not be Placed, kindly Contact Us if your account has been deducted with ${amtCal} INR. Payment ID: ${data.id}`
              );
              setIsLoading(false);
            } else {
              setOrder(data1._id);
              toast.success(`Congratulations your Order was successful!`);
              setTimeout(() => {
                history.push('/orders');
              }, 500);
              setIsLoading(false);
            }
          }
        });
      },
      prefill: {
        email: addr.email,
        contact: addr.phone,
      },

      theme: {
        color: `${themeColor.primaryLight}`,
      },
    };
    var paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleChange = (key) => (event) => {
    setAddr({
      ...addr,
      [key]: event.target.value,
    });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <AddressForm handleChange={handleChange} addr={addr} />;
      case 1:
        return <Checkout handleChange={handleChange} addr={addr} />;
      case 2:
        return <Review products={products} addr={addr} />;
      default:
        throw new Error('Unknown step');
    }
  };

  const getRedirect = () => {
    if (flag) {
      return (
        <div>
          Please fill the form with valid details. Happy Shopping!
          {activeStep !== 0 && (
            <Button
              variant='contained'
              color='primary'
              onClick={handleBack}
              className={classes.button}
            >
              Back
            </Button>
          )}
        </div>
      );
    } else {
      return (
        <div className='text-center'>
          <p> Please click below to proceed to payment.</p>
          <Button
            className='btn '
            onClick={displayRazorPay}
            style={{
              backgroundColor: themeColor.primary,
              color: 'white',
              margin: '2rem',
            }}
          >
            Proceed to payment
          </Button>
        </div>
      );
    }
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div>
      {props.menu !== false ? <Menu /> : <div />}
      <CssBaseline />
      <ToastContainer position='bottom-right' autoClose={5000} />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component='h1' variant='h4' align='center'>
            Checkout
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography
                  variant='h5'
                  gutterBottom
                  className='py-4 text-center'
                >
                  Just one more step to get your product(s).
                </Typography>
                <Typography variant='subtitle1'>{getRedirect()}</Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? 'Place order' : 'Next'}
                  </Button>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </main>
    </div>
  );
}
