import React from "react";
import Base from "./Base";
import logo from "../img/navbar-cg1.png";
import { FiFacebook, FiInstagram, FiTwitter, FiLinkedin } from "react-icons/fi";
import { MdLocationOn } from "react-icons/md";
import { FaWhatsapp, FaPhone, FaEnvelope } from "react-icons/fa";

const ContactUs = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Base>
      <section class="page-section py-5 mt-3" id="contact">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 txt-black-faded text-center">
              <h1 class="mt-0 h1  font-weight-bold">
                We stand by your side to ensure hassle-free personalized
                skincare
              </h1>
              <hr class="divider my-3  mb-4 mx-auto" />
              {/* <p class=' mb-5'>
                Ready to start your next project with us? Give us a call or send
                us an email and we will get back to you as soon as possible!
              </p> */}
            </div>
          </div>
          <div class="row txt-black-faded">
            <div class="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
              <FaPhone
                style={{ height: "2rem", width: "2rem", margin: "1rem" }}
              />
              <a class="contact-a d-block txt-black-faded" target="_blank" href="tel:+916305129239">+91 - 6305129239</a>
            </div>
            <div class="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
              <MdLocationOn
                style={{ height: "2.5rem", width: "2.5rem", margin: "0.5rem" }}
              />
              <a class="contact-a d-block txt-black-faded contact-a-capitalize">
                A/504, bay hills, langar house road, gandipet, hyderabad, india
                500075
              </a>
            </div>
            <div class="col-lg-4 mr-auto text-center">
              <FaEnvelope
                style={{ height: "2rem", width: "2rem", margin: "1rem" }}
              />
              <a
                class="contact-a d-block txt-black-faded"
                href="mailto:contact@circleskincare.com"
              >
                contact@circleskincare.com
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="social text-center">
        <div class="container">
          <ul class="list-inline mb-5">
            <li class="list-inline-item social-icons">
              <a
                class="sl-whatsapp social-link rounded-circle bg-black-faded text-white mr-3"
                href="https://wa.me/message/AOT6C4KESOBCJ1"
                target="_blank"
              >
                <FaWhatsapp />
              </a>
            </li>
            <li class="list-inline-item">
              <a
                class="social-link rounded-circle sl-facebook bg-black-faded text-white mr-3"
                href="http://Facebook.com/circleskincare.official"
                target="_blank"
              >
                <FiFacebook />
              </a>
            </li>
            <li class="list-inline-item  social-icons">
              <a
                class="social-link sl-instagram rounded-circle bg-black-faded text-white mr-3"
                href="http://Instagram.com/circleskincare.official"
                target="_blank"
              >
                <FiInstagram />
              </a>
            </li>
            <li class="list-inline-item  social-icons">
              <a
                class="social-link sl-twitter rounded-circle bg-black-faded text-white"
                href="https://twitter.com/CircleSkincare"
                target="_blank"
              >
                <FiTwitter />
              </a>
            </li>
            <li class="list-inline-item social-icons">
              <a
                class="social-link sl-linkedin rounded-circle bg-black-faded text-white"
                href=" https://www.linkedin.com/company/circle-skincare"
                target="_blank"
              >
                <FiLinkedin />
              </a>
            </li>
          </ul>
        </div>
      </section>

      <section className="safety half-height" style={{ background: "#222326" }}>
        <div className="container-fluid h-100 text-center px-0">
          <div className="row mx-0 w-100 justify-content-center align-item-center d-flex px-0 h-100">
            <div className="col-md-6 px-0 align-self-center">
              <img src={logo} alt="logo" className="img-fluid w-50" />
            </div>
            <div className="col-md-6 px-0">
              <div className="row mx-0 px-0 h-100">
                <div className="col-12 px-0 h-100">
                  <div id="contact" class="map">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.5997037067445!2d78.35934027821523!3d17.382984560420116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9437ab370f5d%3A0xa3bea1762dd235e3!2sLangar%20House%20Rd%20%26%20Bay%20Hls%20Rd%2C%20Narsingi%2C%20Hyderabad%2C%20Narsingi%2C%20Telangana%20500075!5e0!3m2!1sen!2sin!4v1690696057849!5m2!1sen!2sin"
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>{" "}
                    <br />
                    <small>
                      <a href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.5997037067445!2d78.35934027821523!3d17.382984560420116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9437ab370f5d%3A0xa3bea1762dd235e3!2sLangar%20House%20Rd%20%26%20Bay%20Hls%20Rd%2C%20Narsingi%2C%20Hyderabad%2C%20Narsingi%2C%20Telangana%20500075!5e0!3m2!1sen!2sin!4v1690696057849!5m2!1sen!2sin"></a>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Base>
  );
};

export default ContactUs;
