import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function AddressForm({ handleChange, addr }) {
  return (
    <React.Fragment>
      <Typography variant='h6' gutterBottom>
        Shipping address
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            type='text'
            id='fname'
            value={addr.fname}
            name='fname'
            onChange={handleChange('fname')}
            label='First name'
            fullWidth
            autoComplete='given-name'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            type='text'
            value={addr.lname}
            id='lname'
            name='lname'
            onChange={handleChange('lname')}
            label='Last name'
            fullWidth
            autoComplete='family-name'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            type='text'
            id='line1'
            value={addr.line1}
            name='line1'
            onChange={handleChange('line1')}
            label='Address line 1'
            fullWidth
            autoComplete='shipping address-line1'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='line2'
            name='line2'
            type='text'
            value={addr.line2}
            onChange={handleChange('line2')}
            label='Address line 2'
            fullWidth
            autoComplete='shipping address-line2'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='city'
            name='city'
            type='text'
            value={addr.city}
            onChange={handleChange('city')}
            label='City'
            fullWidth
            autoComplete='shipping address-level2'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='district'
            name='district'
            type='text'
            value={addr.district}
            onChange={handleChange('district')}
            label='District'
            fullWidth
            autoComplete='shipping address-district'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id='state'
            name='state'
            type='text'
            value={addr.state}
            onChange={handleChange('state')}
            label='State/Province/Region'
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='pin'
            name='pin'
            value={addr.pin}
            type='number'
            onChange={handleChange('pin')}
            label='Zip / Postal code'
            fullWidth
            autoComplete='shipping postal-code'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id='country'
            name='country'
            type='text'
            value={addr.country}
            onChange={handleChange('country')}
            label='Country'
            fullWidth
            autoComplete='shipping country'
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
