import { API } from '../../backend';

export const getAllBlogs = (page, pageSize) => {
  return fetch(`${API}/blog?page=${page}&pageSize=${pageSize}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getSingleBlog = (blogId) => {
  return fetch(`${API}/blog/${blogId}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getLatestBlogs = () => {
  return fetch(`${API}/blogs/getLatestBlogs`, {
    method: 'GET',
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const postComment = (comment, blogId) => {
  return fetch(`${API}/blog/comment/${blogId}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(comment),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
