import { Button, withStyles } from '@material-ui/core';
import React from 'react';
import { AiFillPlusCircle, AiOutlineTag } from 'react-icons/ai';
import { FiSearch } from 'react-icons/fi';
import { Redirect, useHistory } from 'react-router-dom';
import { isAuthenticated } from '../auth/helper';
import { API } from '../backend';
import '../newcard.css';
import { addToDbCart, removefromDbCart } from './helper/CartHelper';
import Product1Img from '../img/product1.jpg';
import Product2Img from '../img/product2.jpg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const styles = (theme) => ({
  root: {
    maxWidth: '90%',
    margin: '0.5rem',
    padding: '0.5rem',
    border: 'solid #00000010 1px',
    borderRadius: '4px',
    boxShadow: '1px 1px 10px 2px #00000005',
    backgroundColor: '#fefefe',
  },
  title: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    margin: '0.3rem 0',
    color: '#222326',
    textTransform: 'capitalize',
  },
  subtitle: {
    fontSize: '0.8rem',
    minHeight: '0.8rem',
    color: '#222326a2',
    letterSpacing: '1px',
    margin: '0.5rem 0',
  },
  prodImage: {
    width: '100%',
  },
  price: {
    color: '#222326',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '0.9rem',
  },
  addToCard: {
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',

    textTransform: 'uppercase',
    fontSize: '0.8rem',
    color: '#9a1750',
    position: 'relative',
    '&:hover': {
      color: '#9a1750',
    },
  },
  outOfStock: {
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    color: '#22051280',
    position: 'relative',
    '&:hover': {
      color: '#22051280',
    },
  },
});

const NewCard = ({
  product,
  addToCart = true,
  removeFromCart = false,
  setReload = (f) => f, //function(f){return f} this is forcefull component to reload the page
  reload = undefined,
  classes,
  cart,
  showAddCart = true,
  disableClick = false,
}) => {
  const history = useHistory();
  const { token, user } = isAuthenticated();

  const viewProductDetails = (id) => {
    history.push(`/product/${id}`);
  };

  const addProductToCart = (prod) => {
    if (user === undefined) {
      history.push('/signin');
    } else {
      addToDbCart(
        { product: prod._id, sellingPrice: prod.price },
        token,
        user._id
      ).then((data) => {
        if (data) {
          toast.success('Product added to your Cart');
          getRedirect(true);
        } else {
          toast.error('Unable to add');
        }
      });
    }
  };

  const removefromCart = (prod) => {
    if (user === undefined) {
      history.push('/signin');
    } else {
      removefromDbCart(prod._id, token, user._id).then((data) => {
        if (data) {
          toast.success('Product removed from your Cart');
          getRedirect(true);
          setReload(!reload);
        } else {
          toast.error('Unable to remove');
        }
      });
    }
  };

  const getRedirect = (redirect) => {
    if (redirect) {
      // history.push('/cart');
    }
  };

  const showRemoveFromCart = () => {
    //
    return (
      removeFromCart && (
        <div className='col-12'>
          <Button
            onClick={() => {
              removefromCart(product);
              setReload(!reload);
            }}
            className=' addtocart-btn'
          >
            {/* Rs {cardPrice} */}
            Remove From Cart {'    '}
          </Button>
        </div>
      )
    );
  };

  const checkStock = () => {
    if (product) {
      if (product.stock > 0) {
        return (
          isAuthenticated() &&
          (addToCart ? (
            <p
              className={classes.addToCard}
              onClick={(e) => {
                e.stopPropagation();
                addProductToCart(product);
              }}
              style={{ cursor: 'pointer' }}
            >
              Add to Cart <AiFillPlusCircle className='ml-1' size={15} />
            </p>
          ) : (
            <p
              className={classes.addToCard}
              onClick={(e) => {
                e.stopPropagation();
                removefromCart(product);
              }}
            >
              Remove from Cart
            </p>
          ))
        );
      } else {
        return <p className={classes.outOfStock}>Out of Stock</p>;
      }
    }
  };

  if (!product) return null;
  return (
    <>
      <ToastContainer position='bottom-right' autoClose={5000} />
      <div
        className={classes.root}
        onClick={() => {
          if (!disableClick) viewProductDetails(product._id);
        }}
        style={{ cursor: disableClick ? 'default' : 'pointer' }}
      >
        <p className={classes.title}>{product.name}</p>
        <p className={classes.subtitle}>{product?.brand?.name}</p>

        <img
          src={`${API}/product/photo/${product._id}?photoIndex=1`}
          alt='productImage'
          className={classes.prodImage}
        />

        {product?.category?.name && (
          <p className={classes.subtitle}>
            <AiOutlineTag className='' /> {product?.category?.name}
          </p>
        )}
        <div className='d-flex align-items-center my-2 justify-content-between'>
          <p className={classes.price}>₹ {product?.price}</p>
          {showAddCart && checkStock()}
          {!isAuthenticated() && (
            <p
              className={classes.addToCard}
              onClick={(e) => {
                viewProductDetails(product._id);
              }}
            >
              View Product <FiSearch className='ml-1' size={15} />
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(NewCard);
