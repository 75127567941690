import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { AiFillCaretDown } from 'react-icons/ai';
import './admin-dashboard.css';

const Dashboard = ({ children }) => {
  return (
    <div id='page-top'>
      <div id='wrapper'>
        <ul
          className='navbar-nav bg-gradient-primary sidebar sidebar-dark accordion'
          id='accordionSidebar'
        >
          <a
            className='sidebar-brand d-flex align-items-center justify-content-center'
            href='/admin/dashboard'
          >
            <div className='sidebar-brand-icon rotate-n-15'>
              <i className='fas fa-laugh-wink'></i>
            </div>
            <div className='sidebar-brand-text ml-1'>Circle Skincare</div>
          </a>

          <hr className='sidebar-divider my-0' />

          <li className='nav-item active'>
            <a className='nav-link' href='/admin/dashboard'>
              <i className='fas fa-fw fa-tachometer-alt'></i>
              <span>Dashboard</span>
            </a>
          </li>

          <hr className='sidebar-divider' />

          <div className='sidebar-heading'>Blogs</div>

          <li className='nav-item'>
            <a
              className='nav-link collapsed'
              href='#'
              data-toggle='collapse'
              data-target='#collapseTwo'
              aria-expanded='true'
            >
              <i className='fab fa-blogger-b'></i>
              <span>Blogs</span>
            </a>
            <div
              id='collapseTwo'
              className='collapse'
              aria-labelledby='headingTwo'
              data-parent='#accordionSidebar'
            >
              <div className='bg-white py-2 collapse-inner rounded'>
                <h6 className='collapse-header'>Blog Options:</h6>
                <Link className='collapse-item' to='/admin/dashboard/blogs/add'>
                  Create Blog
                </Link>
                <Link
                  className='collapse-item'
                  to='/admin/dashboard/blogs/delete'
                >
                  Delete Blog
                </Link>
              </div>
            </div>
          </li>

          <hr className='sidebar-divider' />

          <div className='sidebar-heading'>Authors</div>

          <li className='nav-item'>
            <a
              className='nav-link collapsed'
              data-toggle='collapse'
              data-target='#collapseAuth'
              aria-expanded='true'
              aria-controls='collapseAuth'
            >
              <i className='fab fa-blogger-b'></i>
              <span>Authors</span>
            </a>
            <div
              id='collapseAuth'
              className='collapse'
              aria-labelledby='headingTwo'
              data-parent='#accordionSidebar'
            >
              <div className='bg-white py-2 collapse-inner rounded'>
                <h6 className='collapse-header'>Author Options:</h6>
                <Link
                  className='collapse-item'
                  to='/admin/dashboard/blogs/add/author'
                >
                  Add Author
                </Link>
                <Link
                  className='collapse-item'
                  to='/admin/dashboard/blogs/authors'
                >
                  Manage Authors
                </Link>
              </div>
            </div>
          </li>

          <hr className='sidebar-divider' />

          <div className='sidebar-heading'>Brands</div>

          <li className='nav-item'>
            <a
              className='nav-link collapsed'
              href='#'
              data-toggle='collapse'
              data-target='#collapsethree'
              aria-expanded='true'
              aria-controls='collapsethree'
            >
              <i className='fab fa-bandcamp'></i>
              <span>Our Brands</span>
            </a>
            <div
              id='collapsethree'
              className='collapse'
              aria-labelledby='headingTwo'
              data-parent='#accordionSidebar'
            >
              <div className='bg-white py-2 collapse-inner rounded'>
                <h6 className='collapse-header'>Brand Options:</h6>
                <Link className='collapse-item' to='/admin/create/brand'>
                  Create Brand
                </Link>
                <Link className='collapse-item' to='/admin/brands'>
                  Manage Brand
                </Link>
              </div>
            </div>
          </li>
          <hr className='sidebar-divider' />

          <div className='sidebar-heading'>Products</div>

          <li className='nav-item'>
            <a
              className='nav-link collapsed'
              href='#'
              data-toggle='collapse'
              data-target='#collapsefour'
              aria-expanded='true'
              aria-controls='collapsefour'
            >
              <i className='fab fa-product-hunt'></i>
              <span>Our Products</span>
            </a>
            <div
              id='collapsefour'
              className='collapse'
              aria-labelledby='headingTwo'
              data-parent='#accordionSidebar'
            >
              <div className='bg-white py-2 collapse-inner rounded'>
                <h6 className='collapse-header'>Product Options:</h6>
                <Link className='collapse-item' to='/admin/create/product'>
                  Create Product
                </Link>
                <Link className='collapse-item' to='/admin/products'>
                  Manage Product
                </Link>
              </div>
            </div>
          </li>

          <hr className='sidebar-divider' />

          <div className='sidebar-heading'>Category</div>

          <li className='nav-item'>
            <a
              className='nav-link collapsed'
              href='#'
              data-toggle='collapse'
              data-target='#cateCollapse'
              aria-expanded='true'
              aria-controls='collapsefour'
            >
              <i className='fab fa-product-hunt'></i>
              <span>Our Category</span>
            </a>
            <div
              id='cateCollapse'
              className='collapse'
              aria-labelledby='headingTwo'
              data-parent='#accordionSidebar'
            >
              <div className='bg-white py-2 collapse-inner rounded'>
                <h6 className='collapse-header'>Category Options:</h6>
                <Link className='collapse-item' to='/admin/create/category'>
                  Create Category
                </Link>
                <Link className='collapse-item' to='/admin/categories'>
                  Manage Category
                </Link>
              </div>
            </div>
          </li>

          <hr className='sidebar-divider' />

          <div className='sidebar-heading'>Orders</div>

          <li className='nav-item'>
            <a
              className='nav-link collapsed'
              href='#'
              data-toggle='collapse'
              data-target='#orderCollapse'
              aria-expanded='true'
              aria-controls='collapsefour'
            >
              <i className='fab fa-product-hunt'></i>
              <span>Orders</span>
            </a>
            <div
              id='orderCollapse'
              className='collapse'
              aria-labelledby='headingTwo'
              data-parent='#accordionSidebar'
            >
              <div className='bg-white py-2 collapse-inner rounded'>
                <h6 className='collapse-header'>Orders Options:</h6>
                <Link className='collapse-item' to='/admin/orders'>
                  Manage Orders
                </Link>
              </div>
            </div>
          </li>

          <hr className='sidebar-divider' />

          <div className='sidebar-heading'> Newsletter</div>

          <li className='nav-item'>
            <a
              className='nav-link collapsed'
              href='#'
              data-toggle='collapse'
              data-target='#newsLetterCollapse'
              aria-expanded='true'
              aria-controls='collapsefour'
            >
              <i className='fab fa-product-hunt'></i>
              <span>Newsletter</span>
            </a>
            <div
              id='newsLetterCollapse'
              className='collapse'
              aria-labelledby='headingTwo'
              data-parent='#accordionSidebar'
            >
              <div className='bg-white py-2 collapse-inner rounded'>
                <h6 className='collapse-header'>Newsletter Options:</h6>
                <Link className='collapse-item' to='/admin/newsletter'>
                  Send Newsletter
                </Link>
              </div>
            </div>
          </li>

          <hr className='sidebar-divider' />
          {/* 
          <div className="sidebar-heading">Tags</div>

          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#"
              data-toggle="collapse"
              data-target="#tagCollapse"
              aria-expanded="true"
              aria-controls="collapsefour"
            >
              <i className="fab fa-product-hunt"></i>
              <span>Our Tags</span>
            </a>
            <div
              id="tagCollapse"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Tag Options:</h6>
                <Link className="collapse-item" to="/admin/manage/tags">
                  Manage Tags
                </Link>
                <Link className="collapse-item" to="/admin/manage/subtags">
                  Manage Sub-Tags
                </Link>
              </div>
            </div>
          </li>

          <hr className="sidebar-divider" /> */}

          <div className='sidebar-heading'>Slider</div>

          <li className='nav-item'>
            <a
              className='nav-link collapsed'
              href='#'
              data-toggle='collapse'
              data-target='#homeSliderCollapse'
              aria-expanded='true'
              aria-controls='collapsefour'
            >
              <i className='fab fa-product-hunt'></i>
              <span>Home Slider</span>
            </a>
            <div
              id='homeSliderCollapse'
              className='collapse'
              aria-labelledby='headingTwo'
              data-parent='#accordionSidebar'
            >
              <div className='bg-white py-2 collapse-inner rounded'>
                <h6 className='collapse-header'>Slider Options:</h6>
                <Link
                  className='collapse-item'
                  to='/admin/dashboard/homeslider'
                >
                  Add / Remove Slider
                </Link>
              </div>
            </div>
          </li>

          <hr className='sidebar-divider' />

          <div className='sidebar-heading'>Reviews</div>

          <li className='nav-item'>
            <a
              className='nav-link collapsed'
              href='#'
              data-toggle='collapse'
              data-target='#reviewCollapse'
              aria-expanded='true'
              aria-controls='collapsefour'
            >
              <i className='fab fa-product-hunt'></i>
              <span>Reviews</span>
            </a>
            <div
              id='reviewCollapse'
              className='collapse'
              aria-labelledby='headingTwo'
              data-parent='#accordionSidebar'
            >
              <div className='bg-white py-2 collapse-inner rounded'>
                <h6 className='collapse-header'>Review Options:</h6>
                <Link className='collapse-item' to='/admin/create/review'>
                  Add Review
                </Link>
                <Link className='collapse-item' to='/admin/reviews'>
                  Manage Review
                </Link>
              </div>
            </div>
          </li>
        </ul>

        <div id='content-wrapper' className='d-flex flex-column'>
          <div id='content' style={{ padding: '3rem' }}>
            {children}
          </div>

          <footer className='sticky-footer bg-white'>
            <div className='container my-auto'>
              <div className='copyright text-center my-auto'>
                <span>
                  Copyright &copy; Circle Skincare. All Rights Reserved
                </span>
              </div>
            </div>
          </footer>
        </div>
      </div>

      <a className='scroll-to-top rounded' href='#page-top'>
        <i className='fas fa-angle-up'></i>
      </a>

      <div
        className='modal fade'
        id='logoutModal'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Ready to Leave?
              </h5>
              <button
                className='close'
                type='button'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>×</span>
              </button>
            </div>
            <div className='modal-body'>
              Select "Logout" below if you are ready to end your current
              session.
            </div>
            <div className='modal-footer'>
              <button
                className='btn btn-secondary'
                type='button'
                data-dismiss='modal'
              >
                Cancel
              </button>
              <a className='btn btn-primary' href='login.html'>
                Logout
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
