import React, { useState, useEffect } from "react";
import ImageHelper from "./helper/ImageHelper";
import { Redirect, Link } from "react-router-dom";
import { API } from "../backend";

const BlogCard = ({ blogs, history }) => {
  // const backPic = () => {
  //     return {
  //         backgroundImage:
  //     }
  // }

  // const randHeight = () => {
  //     return Math.floor(Math.random() * (500 - 300)) + 350;
  // }
  const truncate = (str) =>
    str.length > 100 ? `${str.substring(0, 100)}...` : str;

  return (
    // <div className=" col-sm-12 col-md-6 col-lg-4 row justify-content-center align-items-end mx-0 " >
    <div className="col-sm-12 col-md-6 col-lg-4 blog-card">
      <Link to={`/blogs/details/${blogs._id}`} className="portfolio-item my-2 ">
        <span className="caption">
          <span className="caption-content">
            <h2 className="text-left" style={{fontSize: "30px"}}>{blogs.title}</h2>
            {/* <div  dangerouslySetInnerHTML={{__html:blogs.description}} className="font-weight-bold text-justify mb-0" style={{fontSize: "12px"}}>
              {truncate(blogs.description)} 
            </div> */}
          </span>
        </span>
        <img
          src={API + "/blog/smallPhoto/" + blogs._id}
          alt=""
          className="img-fluid"
        />
      </Link>
    </div>
  );
};

export default BlogCard;
