import React from 'react';
import Base from '../Base';
import Subscription from '../Subscription';

const Checkout = ()=>{
    return(
        <Base>
            <Subscription/>
        </Base>
    )
}

export default Checkout