import React, { useState, useEffect, Fragment } from "react";
import Base from "../core/Base";
import { Link } from "react-router-dom";
import { isAuthenticated } from "../auth/helper";
import { getAllBrands, deleteBrand } from "./helper/adminapicall";
import Dashboard from "./Dashboard";

const ManageBrand = () => {
  const [brands, setBrands] = useState([]);
  const { user, token } = isAuthenticated();

  const preload = () => {
    getAllBrands().then((data) => {
      if (data)if(data.error) {
        console.log(data.error);
      } else {
        setBrands(data);
      }
    });
  };

  useEffect(() => {
    preload();
  }, []);

  const deleteThisBrand = (brandId) => {
    deleteBrand(user._id, brandId, token).then((data) => {
      if (data)if(data.error) {
        console.log(data.error);
      } else {
        preload(); //reloading the component after deleting
      }
    });
  };

  //FIXME: Update Categories
  return (
    <Dashboard>
      <div className="container h-100">
        <div className="row justify-content-center align-item-center">
          <div className="col-12">
            <p className="h3 text-gray-800 font-weight-bold">
              Manage Categories
            </p>
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h2 className="text-center text-dark my-3">
              Total {brands.length} Categories
            </h2>

            <div className="row text-center mb-2">
              {brands.map((brand, index) => {
                return (
                  <Fragment key={index}>
                    <div className="col-4">
                      <div className="m-2">
                        <div style={{ background: "#00005c" }}>
                          <h3 className="text-center text-light font-weight-bold p-5 m-0">
                            {brand.name}
                          </h3>
                        </div>
                        <div className="row mx-0 p-0">
                          <div className="col-6 p-0">
                            <Link
                              className="btn  text-light  w-100"
                              style={{ background: "#3f3f44" }}
                              to={`/admin/brand/update/${brand._id}`}
                            >
                              <span className="">Update</span>
                            </Link>
                          </div>
                          <div className="col-6 p-0">
                            <button
                              onClick={() => {
                                deleteThisBrand(brand._id);
                              }}
                              style={{ background: "#fdcb9e" }}
                              className="btn text-light w-100"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default ManageBrand;
