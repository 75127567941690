import React, { useState, useEffect } from 'react';
import { Typography, withStyles, Button } from '@material-ui/core';
import Base from '../core/Base';
import OrderCard from '../core/Cards/OrderCard.js';
import { themeColor } from '../utility/ColorPallete';
import { getMyOrders } from '../core/helper/OrderHelper';
import { isAuthenticated } from '../auth/helper';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from './Spinner';

const styles = () => ({
  root: {
    padding: '2% 5%',
    textAlign: 'center',
  },
  header: {
    fontSize: '2rem',
  },
  route: {},
  highlight: {
    color: themeColor.primary,
  },
});

const OrderHistory = (props) => {
  const { user, token } = isAuthenticated();
  const [orders, setOrders] = useState([]);
  const [errors, setErrors] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [isloading, setIsLoading] = useState(true);

  const loadAllOrders = (user, page, pageSize) => {
    getMyOrders(user._id, token, page, pageSize).then((data) => {
      if (data?.error) {
        setErrors(data?.error);
        toast.error(data?.error);
        setIsLoading(false);
      } else {
        setOrders([...orders, ...data?.orders]);
        setTotalCount(data?.totalCount);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    loadAllOrders(user, page, pageSize);
  }, [page]);

  const { classes } = props;

  return (
    <>
      {isloading && <Spinner />}
      {!isloading && (
        <Base>
          <ToastContainer position='bottom-right' autoClose={5000} />
          <div className={classes.root}>
            {orders !== undefined && orders.length !== 0 && (
              <Typography className={classes.header}>Your Orders</Typography>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {orders !== undefined && orders.length !== 0 ? (
                orders.map((order, index) => (
                  <OrderCard
                    open={index === 0}
                    key={order.id}
                    order={order}
                    index={index}
                  />
                ))
              ) : (
                <div
                  style={{
                    width: '100%',
                    padding: '3rem',
                    textAlign: 'center',
                  }}
                >
                  <h3 style={{ color: themeColor.primary, height: '19.2rem' }}>
                    No Orders
                  </h3>
                </div>
              )}
            </div>
          </div>
          {page * pageSize < totalCount && (
            <div className=' text-center bg-light text-dark p-2'>
              <div className='row mx-0 py-4 p-1'>
                <div
                  className='col-12'
                  style={{ textAlign: 'center', marginBottom: '2rem' }}
                >
                  <Button
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setPage(page + 1);
                    }}
                  >
                    Load More
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Base>
      )}
    </>
  );
};

export default withStyles(styles, { withTheme: true })(OrderHistory);
