import React, { useState, useEffect } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import Dashboard from './Dashboard';
import { themeColor } from '../utility/ColorPallete';
import { sendMail, downloadData } from '../core/helper/coreapicalls';
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Row,
  Col,
  Input,
  Table,
} from 'reactstrap';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { isAuthenticated } from '../auth/helper';
import { convertToRaw } from 'draft-js';
import { toast } from 'react-toastify';

const styles = () => ({
  root: {
    padding: '2% 20%',
    textAlign: 'center',
  },
  header: {
    fontSize: '2rem',
  },
  route: {},
  highlight: {
    color: themeColor.primary,
  },
});

const Orders = (props) => {
  const { user, token } = isAuthenticated();
  const [Email, setEmail] = useState({
    subject: '',
    bodyMsg: '',
    imgUrl: '',
  });
  const [editorState, setEditorState] = React.useState('');

  const handleEmail = (name) => (event) => {
    setEmail({
      ...Email,
      [name]: event.target.value,
    });
  };

  const onExportEmails = async () => {
    await downloadData('email', user._id, token);
  };

  const { bodyMsg, subject, imgUrl } = Email;

  const pushEmailsForSubscribers = (e) => {
    e.preventDefault();

    let { bodyMsg, subject, imgUrl } = Email;
    bodyMsg = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    sendMail({ bodyMsg, subject, imgUrl })
      .then((data) => {
        if (data && data.error) {
          toast.error('Cannot send mail');
        }
        setEmail({ subject: '', bodyMsg: '', imgUrl: '' });
        setEditorState('');
        toast.success('Mail Sent', { type: 'success' });
      })
      .catch((error) => {
        console.log(error);
        toast.error('Cannot send mail');
      });
  };

  const { classes } = props;
  return (
    <Dashboard>
      <div className={classes.root}>
        <Row>
          <Col xs="12" md="12">
            <Card>
              <CardBody>
                <CardTitle>News Letter</CardTitle>
                <CardSubtitle>Send Email</CardSubtitle>
                <Input
                  type="text"
                  name="subject"
                  id="subject"
                  placeholder="Enter Email Title here.."
                  value={subject}
                  onChange={handleEmail('subject')}
                  style={{ marginTop: '1rem' }}
                />
                <hr />
                <Input
                  type="text"
                  name="imgUrl"
                  id="imgUrl"
                  placeholder="Enter Img here.."
                  value={imgUrl}
                  onChange={handleEmail('imgUrl')}
                  style={{ marginTop: '1rem' }}
                />
                <hr />
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapper-draft"
                  editorClassName="editor-draft"
                  onEditorStateChange={setEditorState}
                />
                {/* <Input
                type='textarea'
                name='bodyMsg'
                id='bodyMsg'
                placeholder='Enter Email Body here..'
                value={bodyMsg}
                onChange={handleEmail('bodyMsg')}
                style={{ marginTop: '1rem' }}
              /> */}
                <Button
                  onClick={pushEmailsForSubscribers}
                  style={{ marginTop: '1rem' }}
                >
                  Send
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12">
            <Card>
              <CardBody>
                <CardTitle>Download Email Ids of Subscribers</CardTitle>
                <Button onClick={onExportEmails}>Export Emails</Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Dashboard>
  );
};

export default withStyles(styles, { withTheme: true })(Orders);
