import React from 'react';
import Base from '../Base';
import ques1 from '../../img/Ques1.png';
import { useState, useEffect } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';
import {
  TextField,
  InputAdornment,
  Grid,
  Button,
  RadioGroup,
  FormControl,
  Checkbox,
  FormControlLabel,
  Paper,
  Typography,
  Radio,
  TextareaAutosize,
  LinearProgress,
  Select,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import {
  IoIosPerson,
  IoIosMale,
  IoIosFemale,
  IoMdArrowForward,
  IoIosTransgender,
} from 'react-icons/io';
import { GiCactus } from 'react-icons/gi';
import { BsDropletHalf } from 'react-icons/bs';
import { FaBalanceScale } from 'react-icons/fa';
import GaugeChart from 'react-gauge-chart';
import { submitQuestionnaire } from '../helper/questionnaire';
import { isAuthenticated } from '../../auth/helper';
import oily from '../../img/oily.png';
import normal from '../../img/normal.png';
import mild from '../../img/mild.png';
import moderate from '../../img/moderate.png';
import severe from '../../img/severe.png';
import lightBrown from '../../img/lightBrown.png';
import darkBrown from '../../img/darkBrown.png';
import { addToDbCart } from '../helper/CartHelper';
import { themeColor } from '../../utility/ColorPallete';
import { MdCheckBox } from 'react-icons/md';
import Subscription from '../Subscription';
import NewCard from '../NewCard';
import Gotopayments from '../../user/Gotopayments';
import {
  getGaugePercentageSensitive,
  getGaugePercentagePimples,
  getGaugePercentageBMI,
} from './helper';
import SignupComponnt from '../../user/SignupComponnt';

// import Highcharts from 'highcharts';

const Questionaire = () => {
  const history = useHistory();
  const [auth, setAuth] = useState(isAuthenticated() ? true : false);
  const [open, setOpen] = useState(true);
  const { user } = isAuthenticated();
  const [values, setValues] = useState({
    mail: user?.email,
    name: null,
    ageGroup: null,
    gender: null,
    skinCareRoutine: null,
    usedPrescProd: null,
    steroidProd: null,
    sunExpo: null,
    skinFeel: null,
    oilyCombination: null,
    sensitiveSkin: null,
    skinConcern: null,
    acneOften: null,
    acnePattern: null,
    painfulPimples: null,
    pimpleBeforePeriods: null,
    regularPeriods: null,
    pusPimple: null,
    scratchPimples: null,
    weight: null,
    height: null,
    bmi: null,
    ingredientReaction: null,
    otherAcneMessage: null,
    suplementsForBoost: null,
    darkPatch: null,
    darkSpots: null,
    agingSkin: null,
    saggySkin: null,
    sunProtectionWear: null,
    dryFaceAreas: null,
    smokeOften: null,
    stressedOften: null,
    balancedDiet: null,
    adequateSleep: null,
    excerciseOften: null,
    glassesWater: null,
    timeUnderSun: null,
    subscription: '1',
  });

  const [Page, setPage] = useState(0);
  const [isQues, setisQues] = useState(true);
  const [tnc1, settnc1] = useState(true);
  const [tnc2, settnc2] = useState(true);
  const [products, setProducts] = useState([]);

  const handleChange = async (name, e, nextView) => {
    setValues({ ...values, [name]: nextView });
  };
  const handleChangeN = async (e, nextView) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const nextPage = (e) => {
    if (values.skinConcern === 'boost glow' && Page === 10) setPage(30);
    //generalstarts
    else if (values.skinConcern === 'acne' && Page === 20) setPage(100);
    else if (values.skinConcern === 'uneven tone' && Page === 29) setPage(100);
    //acne ends so gen starts
    else if (values.skinConcern === 'uneven tone' && Page === 10) setPage(21);
    //pigstart
    else if (values.gender === 'male' && Page === 13) setPage(16);
    //periods Skip
    else if (values.skinConcern === 'acne' && Page === 100) setPage(30);
    else if (values.skinConcern === 'uneven tone' && Page === 100) setPage(30);
    else if (Page === 37) setPage(101);
    else setPage(Page + 1);
  };

  const prevPage = (e) => {
    if (values.skinConcern === 'boost glow' && Page === 30) setPage(10);
    //general first page skip acne and pig
    else if (values.skinConcern === 'acne' && Page === 30) setPage(20);
    // Acne last page skip pig
    else if (values.skinConcern === 'uneven tone' && Page === 21) setPage(10);
    //Pig start page so go to selection
    else if (
      (values.skinConcern === 'acne' || values.skinConcern === 'uneven tone') &&
      Page === 30
    )
      setPage(100);
    else if (values.skinConcern === 'uneven tone' && Page === 100) setPage(29);
    else if (values.skinConcern === 'acne' && Page === 100) setPage(20);
    else if (values.gender === 'male' && Page === 16) setPage(13);
    else if (Page === 101) setPage(37);
    //periods Skip
    else if (Page === 1) return;
    else setPage(Page - 1);
  };

  const Pagination = ({ nextDisable }) => {
    return (
      <Grid item xs={10} align='right'>
        {Page !== 1 && (
          <Button
            variant='contained'
            className='prevPageQuestionnaire'
            disabled={Page === 1}
            onClick={prevPage}
            color='primary'
          >
            <AiOutlineLeft size={20} />
          </Button>
        )}
        {!tnc1 && !tnc2 && Page === 1 && (
          <Button
            variant='contained'
            className='nextPageQuestionnaire'
            style={{ borderRadius: '0', margin: '1vh' }}
            disabled={nextDisable}
            onClick={nextPage}
            color='primary'
          >
            <AiOutlineRight size={20} />
          </Button>
        )}
        {Page !== 1 && Page !== 100 && Page !== 37 && (
          <Button
            variant='contained'
            style={{ borderRadius: '0', margin: '1vh' }}
            disabled={nextDisable}
            onClick={nextPage}
            className='nextPageQuestionnaire'
            color='primary'
          >
            <AiOutlineRight size={20} />
          </Button>
        )}
        {Page === 100 && (
          <Button
            variant='contained'
            style={{ borderRadius: '5', margin: '1vh' }}
            disabled={nextDisable}
            onClick={nextPage}
            color='primary'
          >
            Continue
          </Button>
        )}
        {Page > 29 &&
          Page < 37 &&
          (values.skinConcern === 'uneven tone' ||
            values.skinConcern === 'acne') && (
            <Button
              variant='outlined'
              style={{ borderRadius: '5', margin: '1vh' }}
              onClick={nextPage}
              color='primary'
            >
              Skip
            </Button>
          )}
        {Page === 101 && (
          <Button
            variant='contained'
            style={{ borderRadius: '5', margin: '1vh' }}
            onClick={() => {
              history.push('/cart');
            }}
            color='primary'
          >
            Go To Cart
          </Button>
        )}
        {Page === 100 && (
          <Button
            variant='contained'
            style={{ borderRadius: '5', margin: '1vh' }}
            onClick={() => {
              setPage(37);
            }}
            color='primary'
          >
            Skip
          </Button>
        )}
        {values.skinConcern !== 'general' &&
          values.skinConcern !== undefined &&
          Page > 13 &&
          Page < 16 && (
            <Button
              variant='container'
              style={{ borderRadius: '5', margin: '1vh' }}
              onClick={() => {
                if (Page === 14) {
                  setPage(15);
                } else if (Page === 15) {
                  setPage(16);
                }
              }}
            >
              {' '}
              Skip{' '}
            </Button>
          )}
        {Page === 37 && (
          // <Link to='/user/products/checkout'>
          <Button
            variant='contained'
            style={{ borderRadius: '5', margin: '1vh' }}
            onClick={() => {
              handleSubmit();
              setisQues(false);
            }}
            color='primary'
          >
            Proceed
          </Button>
          //</Link>
        )}
      </Grid>
    );
  };

  const handleSubmit = async (e) => {
    submitQuestionnaire(values).then(async (data) => {
      if (data) {
        if (!data.error) {
          const requiredProducts = [];
          data.products.forEach((x) => {
            if (
              x.productList[0] !== undefined &&
              requiredProducts.length <
                (values.suplementsForBoost === 'one' ? 5 : 4)
            ) {
              requiredProducts.push(x.productList[0]);
            }
          });
          setProducts(requiredProducts);
          localStorage.setItem('products', JSON.stringify(requiredProducts));
        } else {
          console.log(data.error);
        }
      }
    });
  };

  useEffect(() => {
    if (products.length === 0) {
      setProducts(JSON.parse(localStorage.getItem('products')) || []);
    }
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, [Page]);

  const StyledToggleButtonGroup = withStyles((theme) => ({
    // grouped: {
    //   margin: theme.spacing(0.5),
    //   '&:not(:first-child)': {
    //     borderRadius: theme.shape.borderRadius,
    //   },
    //   '&:first-child': {
    //     borderRadius: theme.shape.borderRadius,
    //   },
    // },
  }))(ToggleButtonGroup);

  const getQuestions = () => {
    switch (Page) {
      case 0:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={2}
            alignItems='center'
          >
            <Grid item xs={12} md={10}>
              <Grid
                item
                xs={10}
                className='text-justify mt-0'
                style={{ textAlign: 'left', maxWidth: '100%' }}
              >
                <Typography
                  variant='h6'
                  style={{
                    fontWeight: 'bold',
                    color: '#222326',
                    marginTop: '2vh',
                  }}
                >
                  Welcome to Circle Skincare, Just What Your Skin Needs.
                </Typography>
                <Divider />
                <Typography variant='body2' className=' my-2'>
                  Help us to know your skin in 3 minutes
                </Typography>
                <Typography variant='body1' className='font-weight-bold mt-4'>
                  Our Process
                </Typography>
                <Divider className='my-2' />
                <Typography gutterBottom variant='body2'>
                  Circle Skincare offers effective and realistic solutions that
                  actually works your skin & hair issues. Welcome to younger,
                  healthier and sexier you.
                </Typography>
                <List>
                  <ListItem alignItems='flex-start'>
                    <ListItemText>
                      <Typography variant='body2'>
                        1. My Circle Skincare Quiz - a self-assessment
                        questionnaire designed by celebrity skin experts
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <Typography variant='body2'>
                        2. Treatment plan recommendation by Circle Skincare
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>

                <Typography variant='body1' className='font-weight-bold mt-3'>
                  My Circle Skincare Quiz
                </Typography>
                <Divider className='my-2' />
                <Typography variant='body2'>
                  A significant portion of skin and hair problems could be
                  attributed to lifestyle and environment. Your skin is a unique
                  signature of yourself. Our proper diagnostic tool and
                  algorithm created by experts can help you. Take the quiz and
                  help us build you a customized skin care plan – just for you.
                </Typography>
              </Grid>
            </Grid>
            <Grid itme xs={12} className='text-center mb-4'>
              {' '}
              <Button
                variant='contained'
                style={{ borderRadius: '5', margin: '1vh' }}
                onClick={nextPage}
                color='primary'
              >
                START
              </Button>
            </Grid>
          </Grid>
        );

      case 1:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                Get a Customized Regime That’s Right for Your Skin
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Typography
                variant='body1'
                gutterBottom
                style={{ color: '#444444de', fontWeight: 'bolder' }}
              >
                {' '}
                This regimen is for... *
              </Typography>
              <TextField
                gutterBottom
                autoFocus
                placeholder='Your Name'
                value={values.name}
                name='name'
                onChange={handleChangeN}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      {' '}
                      <IoIosPerson color='#444444de' />{' '}
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={10}>
              <Grid container alignItems='baseline' className='mb-3'>
                <Grid item xs={1}>
                  <Checkbox
                    value='female'
                    checked={!tnc1}
                    style={{ padding: '0' }}
                    checkedIcon={<MdCheckBox style={{ color: '#9a1750' }} />}
                    onClick={() => {
                      settnc1(!tnc1);
                    }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='body1'>
                    I have read and understood the{' '}
                    <Link to='/privacy-policy' target='_blank'>
                      Privacy
                    </Link>{' '}
                    notice related to my quiz responses
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems='baseline'>
                <Grid item xs={1}>
                  <Checkbox
                    value='female'
                    checked={!tnc2}
                    style={{ padding: '0' }}
                    checkedIcon={<MdCheckBox style={{ color: '#9a1750' }} />}
                    onClick={() => {
                      settnc2(!tnc2);
                    }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='body1'>
                    I have read, understood and agree to Circle Skincare{' '}
                    <Link to='/terms' target='_blank'>
                      Terms
                    </Link>{' '}
                    of Use and certify that I am taking the quiz on my own
                    behalf
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {Pagination({
              nextDisable: values.name === null || values.name.trim() === '',
            })}
          </Grid>
        );

      case 2:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                How young are you today?
              </Typography>
              <Typography
                variant='body2'
                style={{
                  color: '#444444de',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '1px',
                }}
              >
                Your age is an integral part of your skin identity.{' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='ageGroup'
                value={values.ageGroup}
                exclusive
                onChange={(e, nextView) => {
                  handleChange('ageGroup', e, nextView);
                }}
              >
                <ToggleButton
                  name='ageGroup'
                  value='15-24'
                  style={{ color: '#000', fontSize: '0.85rem', width: '4rem' }}
                >
                  15-24
                </ToggleButton>

                <ToggleButton
                  name='ageGroup'
                  value='25-34'
                  style={{ color: '#000', fontSize: '0.85rem', width: '4rem' }}
                >
                  25-34
                </ToggleButton>
                <ToggleButton
                  name='ageGroup'
                  value='35-44'
                  style={{ color: '#000', fontSize: '0.85rem', width: '4rem' }}
                >
                  35-44
                </ToggleButton>
                <ToggleButton
                  name='ageGroup'
                  value='45-54'
                  style={{ color: '#000', fontSize: '0.85rem', width: '4rem' }}
                >
                  45-54
                </ToggleButton>
                <ToggleButton
                  name='ageGroup'
                  value='55+'
                  style={{ color: '#000', fontSize: '0.85rem', width: '4rem' }}
                >
                  55 and above
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            {Pagination({ nextDisable: values.ageGroup === null })}
          </Grid>
        );

      case 3:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                Are you a Male or a Female?
              </Typography>
              <Typography
                variant='body2'
                style={{
                  color: '#444444de',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '1px',
                }}
              >
                A lot of beauty secrets are there in the hormones.{' '}
              </Typography>
              <Typography
                variant='body2'
                style={{
                  color: '#444444de',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '1px',
                }}
              >
                Male skin is thicker and needs optimum products to suit skin
                chemistry. Female skin is delicate and needs milder products.{' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='gender'
                value={values.gender}
                exclusive
                onChange={(e, nextView) => handleChange('gender', e, nextView)}
              >
                <ToggleButton name='gender' value='male'>
                  <Grid container>
                    <Grid item xs={12}>
                      <IoIosMale size={60} />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '7px' }}>
                      <Typography variant='body2'>Male</Typography>
                    </Grid>
                  </Grid>
                </ToggleButton>

                <ToggleButton name='gender' value='female'>
                  <Grid container>
                    <Grid item xs={12}>
                      <IoIosFemale size={60}></IoIosFemale>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '7px' }}>
                      <Typography variant='body2'>Female</Typography>
                    </Grid>
                  </Grid>
                </ToggleButton>
                <ToggleButton name='gender' value='other'>
                  <Grid container>
                    <Grid item xs={12}>
                      <IoIosTransgender size={60}></IoIosTransgender>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '7px' }}>
                      <Typography variant='body2'>Other</Typography>
                    </Grid>
                  </Grid>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.gender === null })}
          </Grid>
        );

      case 4:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                {' '}
                How would you describe your current skin care routine?
              </Typography>
              <Typography
                variant='body2'
                style={{
                  color: '#444444de',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '1px',
                }}
              >
                Help us know you better...{' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <StyledToggleButtonGroup
                size='small'
                name='skinCareRoutine'
                value={values.skinCareRoutine}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('skinCareRoutine', e, nextView)
                }
                orientation='vertical'
              >
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='skinCareRoutine'
                  style={{ color: '#000', height: '4rem' }}
                  value='no routine'
                >
                  I don't have a routine
                </ToggleButton>

                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='skinCareRoutine'
                  style={{ color: '#000', height: '4rem' }}
                  value='own fav products'
                >
                  I have my own list of favourite products
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='skinCareRoutine'
                  style={{ color: '#000', height: '4rem' }}
                  value='use a following brand'
                >
                  I use all products from a following brand
                </ToggleButton>
              </StyledToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.skinCareRoutine === null })}
          </Grid>
        );

      case 5:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                {' '}
                Are you currently or in the last 3 months have used any
                prescription product suggested by a skin specialist?
              </Typography>
              <Typography
                variant='body2'
                style={{
                  color: '#444444de',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '1px',
                }}
              >
                Help us know you better...{' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='usedPrescProd'
                value={values.usedPrescProd}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('usedPrescProd', e, nextView)
                }
              >
                <ToggleButton
                  classes={{
                    root: 'toggleButton50',
                  }}
                  name='usedPrescProd'
                  style={{ color: '#000' }}
                  value='true'
                >
                  Yes
                </ToggleButton>

                <ToggleButton
                  classes={{
                    root: 'toggleButton50',
                  }}
                  name='usedPrescProd'
                  style={{ color: '#000' }}
                  value='false'
                >
                  No
                </ToggleButton>
              </ToggleButtonGroup>
              {values.usedPrescProd === 'true' && (
                <Grid item xs={12} md={10}>
                  <FormControl
                    component='fieldset'
                    className='mt-3'
                    style={{ textAlign: 'center' }}
                  >
                    <Typography
                      variant='body1'
                      gutterBottom
                      style={{
                        color: '#222326',
                        marginTop: '2vh',
                      }}
                    >
                      Are there any ingredients in the product that contain
                      Steroids or Skin Lightening Agent?
                    </Typography>
                    <RadioGroup
                      col
                      name='steroidProd'
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                      }}
                      value={values.steroidProd}
                      onChange={(e, nextView) =>
                        handleChange('steroidProd', e, nextView)
                      }
                    >
                      <FormControlLabel
                        value='Yes'
                        control={<Radio />}
                        label='Yes'
                        style={{ display: 'flow-root' }}
                      />
                      {/* {values.steroidProd !== 'No' &&
                        values.steroidProd === 'Yes' &&
                        values.steroidProd && <Select />} */}
                      {/* The above list is temporarily commented, until the list arrives */}
                      <FormControlLabel
                        value='No'
                        control={<Radio />}
                        label='No'
                        style={{ display: 'flow-root' }}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}
            </Grid>

            {Pagination({
              nextDisable:
                values.usedPrescProd !== 'false' && values.steroidProd === null,
            })}
            {/* {Pagination({ nextDisable: (values.usedPrescProd) !== "0" })} */}
          </Grid>
        );

      case 6:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                {' '}
                When exposed to sun, my skin...
              </Typography>
              <Typography
                variant='body2'
                style={{
                  color: '#444444de',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '1px',
                }}
              >
                Sun exposure can determine how fast your skin will age.{' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='sunExpo'
                value={values.sunExpo}
                orientation='vertical'
                exclusive
                onChange={(e, nextView) => handleChange('sunExpo', e, nextView)}
              >
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='sunExpo'
                  style={{ color: '#000', height: '3rem' }}
                  value='tan'
                >
                  Tans easily
                </ToggleButton>

                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='sunExpo'
                  style={{ color: '#000', height: '3rem' }}
                  value='burn'
                >
                  Burns easily
                </ToggleButton>

                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='sunExpo'
                  style={{ color: '#000', height: '3rem' }}
                  value='default'
                >
                  Neither
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='sunExpo'
                  style={{ color: '#000', height: '3rem' }}
                  value='no sun exposure'
                >
                  I do not have sun exposure
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.sunExpo === null })}
          </Grid>
        );

      case 7:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                {' '}
                How does your skin feel?
              </Typography>
              <Typography
                variant='body2'
                style={{
                  color: '#444444de',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '1px',
                }}
              >
                Help us know you better...{' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='skinFeel'
                value={values.skinFeel}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('skinFeel', e, nextView)
                }
              >
                <ToggleButton
                  name='skinFeel'
                  style={{ color: '#000' }}
                  value='oily'
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <BsDropletHalf size={40} />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '7px' }}>
                      <Typography variant='body2'>Always Oily</Typography>
                    </Grid>
                  </Grid>
                </ToggleButton>

                <ToggleButton
                  name='skinFeel'
                  value='dry'
                  style={{
                    color: '#000',
                    paddingLeft: '2.5em',
                    paddingRight: '2.5em',
                  }}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <GiCactus size={40} />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '7px' }}>
                      <div variant='body2'>Dry</div>
                    </Grid>
                  </Grid>
                </ToggleButton>
                <ToggleButton
                  name='skinFeel'
                  style={{ color: '#000' }}
                  value='balanced'
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <FaBalanceScale size={40} />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '7px' }}>
                      <Typography variant='body2'>Balanced</Typography>
                    </Grid>
                  </Grid>
                </ToggleButton>
              </ToggleButtonGroup>
              <br></br>
              {/* <div style={{padding:"1em"}}></div> */}
              <ToggleButtonGroup
                size='small'
                name='skinFeel'
                value={values.skinFeel}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('skinFeel', e, nextView)
                }
              >
                <ToggleButton name='skinFeel' value='sometimes dry'>
                  <Grid container>
                    <Grid item xs={12}>
                      <GiCactus size={40} />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '7px' }}>
                      <div variant='body2' style={{ color: '#000' }}>
                        Sometimes Dry
                      </div>
                    </Grid>
                  </Grid>
                </ToggleButton>

                <ToggleButton
                  name='skinFeel'
                  style={{ color: '#000', paddingLeft: '-30px' }}
                  classname='comb'
                  value='combination of dry and oily'
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <GiCactus size={40}></GiCactus> +{' '}
                      <BsDropletHalf size={40}></BsDropletHalf>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '7px' }}>
                      <Typography variant='body2'>Combination</Typography>
                    </Grid>
                  </Grid>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.skinFeel === null })}
          </Grid>
        );

      case 8:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                {' '}
                What type of combination skin do you have?
              </Typography>
              <Typography
                variant='body2'
                style={{
                  color: '#444444de',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '1px',
                }}
              >
                Help us know you better...{' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='oilyCombination'
                value={values.oilyCombination}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('oilyCombination', e, nextView)
                }
                orientation='vertical'
                style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
              >
                <ToggleButton
                  name='oilyCombination'
                  style={{ color: '#000', display: 'flow-root' }}
                  value='normal to oily dry cheeks'
                >
                  <img src={normal} alt='.' style={{ width: '100%' }} />
                  <br />
                  <p>Normal to oily T, Dry Cheeks</p>
                </ToggleButton>
                <ToggleButton
                  name='oilyCombination'
                  style={{ color: '#000', display: 'flow-root' }}
                  value='oily normal to dry cheeks'
                >
                  <img src={oily} style={{ width: '92.5%' }} alt='...' />
                  <br />
                  <p>Oily T, Normal to Dry Cheeks</p>
                </ToggleButton>
                <ToggleButton
                  name='oilyCombination'
                  style={{
                    color: '#000',
                    gridColumn: '1 / 3',
                  }}
                  value='no skin combination'
                >
                  These arent me
                </ToggleButton>
                <ToggleButton
                  name='oilyCombination'
                  style={{
                    color: '#000',
                    gridColumn: '1 / 3',
                  }}
                  value='default'
                >
                  I dont have a combination
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.oilyCombination === null })}
          </Grid>
        );

      case 9:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                {' '}
                Is your skin sensitive?
              </Typography>
              <Typography
                variant='body2'
                style={{
                  color: '#444444de',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '1px',
                }}
              >
                Help us know you better...{' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <GaugeChart
                id='gauge-chart3'
                nrOfLevels={3}
                percent={getGaugePercentageSensitive(values.sensitiveSkin)}
                arcsLength={[0.3, 0.3, 0.4]}
                colors={['#00a1ab', '#f6d743', '#900c3f']}
                arcPadding={0}
                hideText
                needleColor='#345243'
              />
              <ToggleButtonGroup
                size='small'
                name='sensitiveSkin'
                value={values.sensitiveSkin}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('sensitiveSkin', e, nextView)
                }
                orientation='vertical'
              >
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='sensitiveSkin'
                  style={{ color: '#000' }}
                  value='not sensitive skin'
                >
                  Not at all
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='sensitiveSkin'
                  style={{ color: '#000' }}
                  value='little sensitive skin'
                >
                  A little bit
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='sensitiveSkin'
                  style={{ color: '#000' }}
                  value='very sensitive skin'
                >
                  Very much
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.sensitiveSkin === null })}
          </Grid>
        );

      case 10:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                {' '}
                The skin concerns you most likely want to address are?
              </Typography>
              <Typography
                variant='body2'
                style={{
                  color: '#444444de',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '1px',
                }}
              >
                From ageing to lusterless tone or breakouts, we all have
                something we want to improve.{' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='skinConcern'
                value={values.skinConcern}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('skinConcern', e, nextView)
                }
                orientation='vertical'
              >
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='skinConcern'
                  style={{ color: '#000', height: '3rem' }}
                  value='acne'
                >
                  Acne/Acne marks
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='skinConcern'
                  style={{ color: '#000', height: '3rem' }}
                  value='uneven tone'
                >
                  Uneven Texture/Tone
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='skinConcern'
                  style={{ color: '#000', height: '3rem' }}
                  value='boost glow'
                >
                  Boost Glow and General Skin routine (to keep shining)
                </ToggleButton>
              </ToggleButtonGroup>
              {/* <img src={face_List} style={{ width: '75%' }} /> */}
            </Grid>

            {Pagination({ nextDisable: values.skinConcern === null })}
          </Grid>
        );

      case 11:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                {' '}
                How often do you get pimples?
              </Typography>
              <Grid item xs={8} align='center'>
                {' '}
                <GaugeChart
                  id='gauge-chart3'
                  nrOfLevels={4}
                  percent={getGaugePercentagePimples(values.acneOften)}
                  arcsLength={[0.25, 0.25, 0.25, 0.25]}
                  colors={['#29f516', '#f6d743', '#ff9c12', '#f21818']}
                  arcPadding={0}
                  hideText
                  needleColor='#345243'
                />
              </Grid>
              {/* <Typography
                variant="body2"
                style={{
                  color: "#444444de",
                  fontSize: "12px",
                  fontWeight: "500",
                  letterSpacing: "1px",
                }}
              >
                Sun exposure can determine how fast your skin will age{" "}
              </Typography> */}
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='acneOften'
                value={values.acneOften}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('acneOften', e, nextView)
                }
                orientation='vertical'
              >
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='acneOften'
                  style={{ color: '#000' }}
                  value='rare pimples'
                >
                  A few times a year
                </ToggleButton>

                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='acneOften'
                  style={{ color: '#000' }}
                  value='monthly pimples'
                >
                  Monthly{' '}
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='acneOften'
                  style={{ color: '#000' }}
                  value='weekly pimples'
                >
                  Weekly{' '}
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='acneOften'
                  style={{ color: '#000' }}
                  value='often get pimples'
                >
                  I am always Breaking out{' '}
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='acneOften'
                  style={{ color: '#000' }}
                  value='no pimples'
                >
                  Never{' '}
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.acneOften === null })}
          </Grid>
        );

      case 12:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                Which image reflects your acne pattern?
              </Typography>
              <Typography
                variant='body2'
                style={{
                  color: '#444444de',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '1px',
                }}
              >
                Help us know you better...{' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='acnePattern'
                value={values.acnePattern}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('acnePattern', e, nextView)
                }
                orientation='vertical'
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr',
                }}
              >
                <ToggleButton
                  name='acnePattern'
                  style={{ color: '#000', display: 'flow-root' }}
                  value='mild acne'
                >
                  <div>
                    <img src={mild} alt='.' style={{ width: '100%' }} />
                  </div>

                  <h5>Mild</h5>
                  <div style={{ height: '5rem' }}>
                    <Typography variant='caption'>
                      Minor breakouts with less than 15 whiteheads or blackheads
                    </Typography>
                  </div>
                </ToggleButton>
                <ToggleButton
                  name='acnePattern'
                  style={{ color: '#000', display: 'flow-root' }}
                  value='moderate acne'
                >
                  <div>
                    <img src={moderate} alt='.' style={{ width: '100%' }} />
                  </div>

                  <h5>Moderate</h5>
                  <div style={{ height: '5rem' }}>
                    <Typography variant='caption'>
                      15+ whiteheads, blackheads or lesions
                    </Typography>
                  </div>
                </ToggleButton>
                <ToggleButton
                  name='acnePattern'
                  style={{ color: '#000', display: 'flow-root' }}
                  value='severe acne'
                >
                  <img src={severe} alt='.' style={{ width: '100%' }} />
                  <br />
                  <h5>Severe</h5>
                  <div style={{ height: '5rem' }}>
                    <Typography variant='caption'>
                      large, red and painful breakouts deep in the skin that can
                      lead to scarring
                    </Typography>
                  </div>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.acnePattern === null })}
          </Grid>
        );

      case 13:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                Are your pimples painful?
              </Typography>
              {/* <Typography variant="body2" style={{ color: "#444444de", fontSize: "12px", fontWeight: "500", letterSpacing: '1px' }} >Sun exposure can determine how fast your skin will age </Typography> */}
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='painfulPimples'
                value={values.painfulPimples}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('painfulPimples', e, nextView)
                }
              >
                <ToggleButton
                  classes={{
                    root: 'toggleButton50',
                  }}
                  name='painfulPimples'
                  style={{ color: '#000' }}
                  value='one'
                >
                  Yes
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton50',
                  }}
                  name='painfulPimples'
                  style={{ color: '#000' }}
                  value='two'
                >
                  No
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.painfulPimples === null })}
          </Grid>
        );

      case 14:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                Do you get more pimples before your periods?
              </Typography>
              {/* <Typography variant="body2" style={{ color: "#444444de", fontSize: "12px", fontWeight: "500", letterSpacing: '1px' }} >Sun exposure can determine how fast your skin will age </Typography> */}
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='pimpleBeforePeriods'
                value={values.pimpleBeforePeriods}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('pimpleBeforePeriods', e, nextView)
                }
              >
                <ToggleButton
                  classes={{
                    root: 'toggleButton50',
                  }}
                  name='pimpleBeforePeriods'
                  style={{ color: '#000' }}
                  value='one'
                >
                  Yes
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton50',
                  }}
                  name='pimpleBeforePeriods'
                  style={{ color: '#000' }}
                  value='two'
                >
                  No
                </ToggleButton>
                <ToggleButton
                  name='pimpleBeforePeriods'
                  style={{ color: '#000' }}
                  value='three'
                >
                  Sometimes
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.pimpleBeforePeriods === null })}
          </Grid>
        );

      case 15:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                Are your periods regular?
              </Typography>
              {/* <Typography variant="body2" style={{ color: "#444444de", fontSize: "12px", fontWeight: "500", letterSpacing: '1px' }} >Sun exposure can determine how fast your skin will age </Typography> */}
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='regularPeriods'
                value={values.regularPeriods}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('regularPeriods', e, nextView)
                }
              >
                <ToggleButton
                  classes={{
                    root: 'toggleButton50',
                  }}
                  name='regularPeriods'
                  style={{ color: '#000' }}
                  value='one'
                >
                  Yes
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton50',
                  }}
                  name='regularPeriods'
                  style={{ color: '#000' }}
                  value='two'
                >
                  No
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.regularPeriods === null })}
          </Grid>
        );

      case 16:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                Is there pus in the pimple lesion?
              </Typography>
              {/* <Typography variant="body2" style={{ color: "#444444de", fontSize: "12px", fontWeight: "500", letterSpacing: '1px' }} >Sun exposure can determine how fast your skin will age </Typography> */}
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='pusPimple'
                value={values.pusPimple}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('pusPimple', e, nextView)
                }
                // orientation="vertical"
              >
                <ToggleButton
                  classes={{
                    root: 'toggleButton50',
                  }}
                  name='pusPimple'
                  style={{ color: '#000' }}
                  value='one'
                >
                  Yes
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton50',
                  }}
                  name='pusPimple'
                  style={{ color: '#000' }}
                  value='two'
                >
                  No
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.pusPimple === null })}
          </Grid>
        );

      case 17:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                Do you pop/scratch your pimples?
              </Typography>
              {/* <Typography variant="body2" style={{ color: "#444444de", fontSize: "12px", fontWeight: "500", letterSpacing: '1px' }} >Sun exposure can determine how fast your skin will age </Typography> */}
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='scratchPimples'
                value={values.scratchPimples}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('scratchPimples', e, nextView)
                }
                // orientation="vertical"
              >
                <ToggleButton
                  classes={{
                    root: 'toggleButton50',
                  }}
                  name='scratchPimples'
                  style={{ color: '#000' }}
                  value='one'
                >
                  Yes
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton50',
                  }}
                  name='scratchPimples'
                  style={{ color: '#000' }}
                  value='two'
                >
                  No
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.scratchPimples === null })}
          </Grid>
        );

      case 18:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                Let's calculate your BMI...
              </Typography>
              {/* <Typography variant="body2" style={{ color: "#444444de", fontSize: "12px", fontWeight: "500", letterSpacing: '1px' }} >Sun exposure can determine how fast your skin will age </Typography> */}
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <FormControl component='fieldset'>
                {/* <Typography variant="body1" gutterBottom style={{ color: "#B9384A", fontWeight: "bolder" }}> <IoMdArrowForward />Lets calculate your BMI</Typography> */}
                {/* <RadioGroup col name="skinConcern" value={values.skinConcern} onChange={handleChangeN}>
                                    <FormControlLabel value="acne" control={<Radio />} label="Acne/ Acne marks" />
                                    <FormControlLabel value="pigmentation" control={<Radio />} label="Uneven Texture/ Tone " />
                                    <FormControlLabel value="general" control={<Radio />} label="Boost Glow and General Skin routine (to keep shining)" />
                                </RadioGroup> */}
                <GaugeChart
                  id='gauge-chart3'
                  nrOfLevels={9}
                  hideText
                  percent={getGaugePercentageBMI(values.weight, values.height)}
                  arcsLength={[0.3, 0.2, 0.2, 0.3]}
                  colors={['#00a1ab', '#94D147', '#f6d743', '#900c3f']}
                  arcPadding={0}
                  needleColor='#345243'
                  textColor='#345243'
                />
                <Typography gutterBottom>
                  {values.weight &&
                  values.height &&
                  values.weight > 0 &&
                  values.height > 0
                    ? (
                        Number(values.weight) /
                        ((Number(values.height) * Number(values.height)) /
                          10000)
                      ).toFixed(2)
                    : 0}
                </Typography>

                <TextField
                  value={values.height}
                  InputProps={{
                    startAdornment: (
                      <Typography
                        style={{
                          color: '#4A4A4ADD',
                          minWidth: '90px',
                          paddingRight: '20px',
                          marginRight: '5px',
                          borderRight: 'solid 1px',
                        }}
                      >
                        Height
                      </Typography>
                    ),
                    endAdornment: (
                      <Typography style={{ color: '#4A4A4ADD' }}>
                        Centi-Meters
                      </Typography>
                    ),
                  }}
                  onChange={handleChangeN}
                  name='height'
                />
                <TextField
                  value={values.weight}
                  onChange={handleChangeN}
                  name='weight'
                  InputProps={{
                    startAdornment: (
                      <Typography
                        style={{
                          minWidth: '90px',
                          color: '#4A4A4ADD',
                          marginRight: '5px',
                          paddingRight: '20px',
                          borderRight: 'solid 1px',
                        }}
                      >
                        Weight
                      </Typography>
                    ),
                    endAdornment: (
                      <Typography style={{ color: '#4A4A4ADD' }}>
                        Kilo-Grams
                      </Typography>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            {Pagination({
              nextDisable:
                values.weight === null ||
                values.height === null ||
                values.weight === '' ||
                values.height === '',
            })}
          </Grid>
        );

      case 19:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                Have you had any adverse reaction to a skincare product or
                ingredient?
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='ingredientReaction'
                value={values.ingredientReaction}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('ingredientReaction', e, nextView)
                }
                orientation='vertical'
              >
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='ingredientReaction'
                  style={{ color: '#000' }}
                  value='one'
                >
                  Benzoyl Peroxide
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='ingredientReaction'
                  style={{ color: '#000' }}
                  value='two'
                >
                   Salicylic Acid
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='ingredientReaction'
                  style={{ color: '#000' }}
                  value='three'
                >
                  Retinol
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='ingredientReaction'
                  style={{ color: '#000' }}
                  value='four'
                >
                  Vitamin C
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='ingredientReaction'
                  style={{ color: '#000' }}
                  value='five'
                >
                  Niacinamide
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='ingredientReaction'
                  style={{ color: '#000' }}
                  value='six'
                >
                  None
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} md={10} align='center'>
              <Typography
                variant='body1'
                gutterBottom
                style={{ color: '#B9384A', fontWeight: 'bolder' }}
              >
                Anything else you'd like to share with us?
              </Typography>
              <TextareaAutosize
                rows={5}
                contentEditable
                variant='outlined'
                placeholder=''
                cols={40}
                value={values.otherAcneMessage}
                name='otherAcneMessage'
                onChange={(e, nextView) =>
                  handleChange('otherAcneMessage', e, nextView)
                }
              />
            </Grid>

            {Pagination({ nextDisable: values.ingredientReaction === null })}
          </Grid>
        );

      case 20:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                Would you like supplements to boost your skin health?
              </Typography>
              <Typography
                variant='body2'
                style={{
                  color: '#444444de',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '1px',
                }}
              >
                We ensure that they are completely suitable for vegetarians.{' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='suplementsForBoost'
                value={values.suplementsForBoost}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('suplementsForBoost', e, nextView)
                }
                // orientation="vertical"
              >
                <ToggleButton
                  classes={{
                    root: 'toggleButton50',
                  }}
                  name='suplementsForBoost'
                  style={{ color: '#000' }}
                  value='one'
                >
                  Yes, for sure
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton50',
                  }}
                  name='suplementsForBoost'
                  style={{ color: '#000' }}
                  value='two'
                >
                  Not for me
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            {Pagination({ nextDisable: values.suplementsForBoost === null })}
          </Grid>
        );

      case 21:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                Do you have any dark patch on our face?
              </Typography>
              <Typography
                variant='body2'
                style={{
                  color: '#444444de',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '1px',
                }}
              >
                Help us know you better...{' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='darkPatch'
                value={values.darkPatch}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('darkPatch', e, nextView)
                }
                orientation='vertical'
                style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
              >
                <ToggleButton
                  name='darkPatch'
                  style={{ color: '#000', display: 'flow-root' }}
                  value='light patches'
                >
                  <img src={lightBrown} alt='.' style={{ width: '100%' }} />
                  <br />
                  Light Brown
                </ToggleButton>
                <ToggleButton
                  name='darkPatch'
                  style={{ color: '#000', display: 'flow-root' }}
                  value='dark patches'
                >
                  <img src={darkBrown} alt='.' style={{ width: '100%' }} />
                  <br />
                  Dark Brown
                </ToggleButton>
                <ToggleButton
                  name='darkPatch'
                  style={{ color: '#000', gridColumn: '1 / 3' }}
                  value='no patches'
                >
                  No
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.darkPatch === null })}
          </Grid>
        );

      case 22:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                How many dark spots are there on your face?
              </Typography>
              <Typography
                variant='body2'
                style={{
                  color: '#444444de',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '1px',
                }}
              >
                Help us know you better...{' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='darkSpots'
                value={values.darkSpots}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('darkSpots', e, nextView)
                }
                orientation='vertical'
              >
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='darkSpots'
                  style={{ color: '#000' }}
                  value='2-5 spots'
                >
                  2-5
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='darkSpots'
                  style={{ color: '#000' }}
                  value='5-10 spots'
                >
                  5-10
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='darkSpots'
                  style={{ color: '#000' }}
                  value='more than 10 spots'
                >
                  Greater than 10
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.darkSpots === null })}
          </Grid>
        );

      case 23:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                Are there signs of ageing of skin?
              </Typography>
              <Typography
                variant='body2'
                style={{
                  color: '#444444de',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '1px',
                }}
              >
                Fine lines/wrinkles around eyes that become prominent on
                smiling. Forehead lines/wrinkles that become prominent on
                raising the eyebrows{' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='agingSkin'
                value={values.agingSkin}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('agingSkin', e, nextView)
                }
                orientation='vertical'
              >
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='agingSkin'
                  style={{ color: '#000' }}
                  value='no sign of ageing'
                >
                  No
                </ToggleButton>

                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='agingSkin'
                  style={{ color: '#000' }}
                  value='few signs of ageing'
                >
                  Yes – Few and just starting
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='agingSkin'
                  style={{ color: '#000' }}
                  value='many signs of ageing'
                >
                  Yes – Many and prominent
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.agingSkin === null })}
          </Grid>
        );

      case 24:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                How much your skin sags?
              </Typography>
              <Typography
                variant='body2'
                style={{
                  color: '#444444de',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '1px',
                }}
              >
                Help us know you better...{' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='saggySkin'
                value={values.saggySkin}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('saggySkin', e, nextView)
                }
                orientation='vertical'
              >
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='saggySkin'
                  style={{ color: '#000' }}
                  value='one'
                >
                  Not at all
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='saggySkin'
                  style={{ color: '#000' }}
                  value='two'
                >
                  I have firm skin
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='saggySkin'
                  style={{ color: '#000' }}
                  value='three'
                >
                  Somewhat
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='saggySkin'
                  style={{ color: '#000' }}
                  value='four'
                >
                  Obvious sagging
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.saggySkin === null })}
          </Grid>
        );

      case 25:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                Do you wear sun protection (SPF Cream)?
              </Typography>
              <Typography
                variant='body2'
                style={{
                  color: '#444444de',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '1px',
                }}
              >
                Sun exposure can determine how fast your skin will age.{' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='sunProtectionWear'
                value={values.sunProtectionWear}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('sunProtectionWear', e, nextView)
                }
                orientation='vertical'
              >
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='sunProtectionWear'
                  style={{ color: '#000' }}
                  value='one'
                >
                  Never
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='sunProtectionWear'
                  style={{ color: '#000' }}
                  value='two'
                >
                  Sometimes
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='sunProtectionWear'
                  style={{ color: '#000' }}
                  value='three'
                >
                  Daily
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.sunProtectionWear === null })}
          </Grid>
        );

      case 26:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                Are there any dry areas on your face?
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='dryFaceAreas'
                value={values.dryFaceAreas}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('dryFaceAreas', e, nextView)
                }
              >
                <ToggleButton
                  classes={{
                    root: 'toggleButton50',
                  }}
                  name='dryFaceAreas'
                  style={{ color: '#000' }}
                  value='one'
                >
                  Yes
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton50',
                  }}
                  name='dryFaceAreas'
                  style={{ color: '#000' }}
                  value='two'
                >
                  No
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.dryFaceAreas === null })}
          </Grid>
        );

      case 27:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                Let's calculate your BMI...
              </Typography>
              {/* <Typography variant="body2" style={{ color: "#444444de", fontSize: "12px", fontWeight: "500", letterSpacing: '1px' }} >Sun exposure can determine how fast your skin will age </Typography> */}
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <FormControl component='fieldset'>
                {/* <Typography variant="body1" gutterBottom style={{ color: "#B9384A", fontWeight: "bolder" }}> <IoMdArrowForward />Lets calculate your BMI</Typography> */}
                {/* <RadioGroup col name="skinConcern" value={values.skinConcern} onChange={handleChangeN}>
                                        <FormControlLabel value="acne" control={<Radio />} label="Acne/ Acne marks" />
                                        <FormControlLabel value="pigmentation" control={<Radio />} label="Uneven Texture/ Tone " />
                                        <FormControlLabel value="general" control={<Radio />} label="Boost Glow and General Skin routine (to keep shining)" />
                                    </RadioGroup> */}
                <GaugeChart
                  id='gauge-chart3'
                  nrOfLevels={9}
                  hideText
                  percent={getGaugePercentageBMI(values.weight, values.height)}
                  arcsLength={[0.3, 0.2, 0.2, 0.3]}
                  colors={['#00a1ab', '#94D147', '#f6d743', '#900c3f']}
                  arcPadding={0}
                  needleColor='#345243'
                  textColor='#345243'
                />
                {values.weight &&
                values.height &&
                values.weight > 0 &&
                values.height > 0
                  ? (
                      Number(values.weight) /
                      ((Number(values.height) * Number(values.height)) / 10000)
                    ).toFixed(2)
                  : 0}

                <TextField
                  value={values.height}
                  InputProps={{
                    startAdornment: (
                      <Typography
                        style={{
                          color: '#4A4A4ADD',
                          minWidth: '90px',
                          paddingRight: '20px',
                          marginRight: '5px',
                          borderRight: 'solid 1px',
                        }}
                      >
                        Height
                      </Typography>
                    ),
                    endAdornment: (
                      <Typography style={{ color: '#4A4A4ADD' }}>
                        Centi-Meters
                      </Typography>
                    ),
                  }}
                  onChange={handleChangeN}
                  name='height'
                />
                <TextField
                  value={values.weight}
                  onChange={handleChangeN}
                  name='weight'
                  InputProps={{
                    startAdornment: (
                      <Typography
                        style={{
                          minWidth: '90px',
                          color: '#4A4A4ADD',
                          marginRight: '5px',
                          paddingRight: '20px',
                          borderRight: 'solid 1px',
                        }}
                      >
                        Weight
                      </Typography>
                    ),
                    endAdornment: (
                      <Typography style={{ color: '#4A4A4ADD' }}>
                        Kgs
                      </Typography>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            {Pagination({
              nextDisable:
                values.weight === null ||
                values.height === null ||
                values.weight === '' ||
                values.height === '',
            })}
          </Grid>
        );

      case 28:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                Have you had any adverse reaction to a skincare product or
                ingredient?
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='ingredientReaction'
                value={values.ingredientReaction}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('ingredientReaction', e, nextView)
                }
                orientation='vertical'
              >
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='ingredientReaction'
                  style={{ color: '#000' }}
                  value='one'
                >
                  Benzoyl Peroxide
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='ingredientReaction'
                  style={{ color: '#000' }}
                  value='two'
                >
                   Salicylic Acid
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='ingredientReaction'
                  style={{ color: '#000' }}
                  value='three'
                >
                  Retinol
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='ingredientReaction'
                  style={{ color: '#000' }}
                  value='four'
                >
                  Vitamin C
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='ingredientReaction'
                  style={{ color: '#000' }}
                  value='five'
                >
                  Niacinamide
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='ingredientReaction'
                  style={{ color: '#000' }}
                  value='six'
                >
                  None
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} md={10} align='center'>
              <Typography
                variant='body1'
                gutterBottom
                style={{ color: '#B9384A', fontWeight: 'bolder' }}
              >
                Anything else you'd like to share with us?
              </Typography>
              <TextareaAutosize
                rows={5}
                contentEditable
                variant='outlined'
                placeholder=''
                cols={40}
                value={values.otherAcneMessage}
                name='otherAcneMessage'
                onChange={(e, nextView) =>
                  handleChange('otherAcneMessage', e, nextView)
                }
              />
            </Grid>

            {Pagination({ nextDisable: values.ingredientReaction === null })}
          </Grid>
        );

      case 29:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                Would you like supplements to boost your skin health?
              </Typography>
              <Typography
                variant='body2'
                style={{
                  color: '#444444de',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '1px',
                }}
              >
                We ensure that they are completely suitable for vegetarians.{' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='suplementsForBoost'
                value={values.suplementsForBoost}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('suplementsForBoost', e, nextView)
                }
                // orientation="vertical"
              >
                <ToggleButton
                  classes={{
                    root: 'toggleButton50',
                  }}
                  name='suplementsForBoost'
                  style={{ color: '#000' }}
                  value='one'
                >
                  Yes, for sure
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton50',
                  }}
                  name='suplementsForBoost'
                  style={{ color: '#000' }}
                  value='two'
                >
                  Not for me
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            {Pagination({ nextDisable: values.suplementsForBoost === null })}
          </Grid>
        );

      case 30:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                How often do you smoke?
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='smokeOften'
                value={values.smokeOften}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('smokeOften', e, nextView)
                }
              >
                <ToggleButton
                  name='smokeOften'
                  style={{ color: '#000', width: '7.5rem' }}
                  value='one'
                >
                  Never
                </ToggleButton>
                <ToggleButton
                  name='smokeOften'
                  style={{ color: '#000', width: '7.5rem' }}
                  value='two'
                >
                  Sometimes
                </ToggleButton>
                <ToggleButton
                  name='smokeOften'
                  style={{ color: '#000', width: '7.5rem' }}
                  value='three'
                >
                  Regularly
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.smokeOften === null })}
          </Grid>
        );

      case 31:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                {' '}
                How frequently would you describe yourself as stressed out?
              </Typography>
              <Typography
                variant='body2'
                style={{
                  color: '#444444de',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '1px',
                }}
              >
                Stress releases certain specific cytokines which can harm your
                skin chemistry.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='stressedOften'
                value={values.stressedOften}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('stressedOften', e, nextView)
                }
              >
                <ToggleButton
                  name='stressedOften'
                  style={{ color: '#000', width: '7.5rem' }}
                  value='one'
                >
                  Never
                </ToggleButton>
                <ToggleButton
                  name='stressedOften'
                  style={{ color: '#000', width: '7.5rem' }}
                  value='two'
                >
                  Sometimes
                </ToggleButton>
                <ToggleButton
                  name='stressedOften'
                  style={{ color: '#000', width: '7.5rem' }}
                  value='three'
                >
                  Regularly
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.stressedOften === null })}
          </Grid>
        );

      case 32:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                How balanced is your diet?
              </Typography>
              <Typography
                variant='body2'
                style={{
                  color: '#444444de',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '1px',
                }}
              >
                Balance diet helps in maintaining skin supple, healthy and
                vibrant by providing anti-oxidants.{' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='balancedDiet'
                value={values.balancedDiet}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('balancedDiet', e, nextView)
                }
              >
                <ToggleButton
                  name='balancedDiet'
                  style={{ color: '#000', width: '7.5rem' }}
                  value='one'
                >
                  Never
                </ToggleButton>
                <ToggleButton
                  name='balancedDiet'
                  style={{ color: '#000', width: '7.5rem' }}
                  value='two'
                >
                  Sometimes
                </ToggleButton>
                <ToggleButton
                  name='balancedDiet'
                  style={{ color: '#000', width: '7.5rem' }}
                  value='three'
                >
                  Regularly
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.balancedDiet === null })}
          </Grid>
        );

      case 33:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                How adequate is your sleep?
              </Typography>
              <Typography
                variant='body2'
                style={{
                  color: '#444444de',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '1px',
                }}
              >
                Adequate sleep ensures repair of skin cells properly.{' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='adequateSleep'
                value={values.adequateSleep}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('adequateSleep', e, nextView)
                }
                orientation='vertical'
              >
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='adequateSleep'
                  style={{ color: '#000' }}
                  value='one'
                >
                  Less than 4 hours
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='adequateSleep'
                  style={{ color: '#000' }}
                  value='two'
                >
                  4-8 hours
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='adequateSleep'
                  style={{ color: '#000' }}
                  value='three'
                >
                  More than 8 hours
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.adequateSleep === null })}
          </Grid>
        );

      case 34:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                On an average how many hours a week you exercise?
              </Typography>
              <Typography
                variant='body2'
                style={{
                  color: '#444444de',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '1px',
                }}
              >
                Exercise helps with blood circulation that delivers healthy
                nutrients to the skin.{' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='excerciseOften'
                value={values.excerciseOften}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('excerciseOften', e, nextView)
                }
                orientation='vertical'
              >
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='excerciseOften'
                  style={{ color: '#000' }}
                  value='one'
                >
                  Less than 4 hours
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='excerciseOften'
                  style={{ color: '#000' }}
                  value='two'
                >
                  4-8 hours
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='excerciseOften'
                  style={{ color: '#000' }}
                  value='three'
                >
                  More than 8 hours
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='excerciseOften'
                  style={{ color: '#000' }}
                  value='four'
                >
                  I never exercise
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.excerciseOften === null })}
          </Grid>
        );

      case 35:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                On an average how many glasses of water do you drink per day?
              </Typography>
              <Typography
                variant='body2'
                style={{
                  color: '#444444de',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '1px',
                }}
              >
                Adequate water intake helps in neutralizing the toxins produced
                in your skin and maintains moisturization.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='glassesWater'
                value={values.glassesWater}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('glassesWater', e, nextView)
                }
                orientation='vertical'
              >
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='glassesWater'
                  style={{ color: '#000' }}
                  value='one'
                >
                  Less than 4
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='glassesWater'
                  style={{ color: '#000' }}
                  value='two'
                >
                  4-6
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='glassesWater'
                  style={{ color: '#000' }}
                  value='three'
                >
                  More than 6
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.glassesWater === null })}
          </Grid>
        );

      case 36:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h6'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '2vh',
                }}
              >
                On a typical day how much time do you spend under the sun or in
                front of a screen – tv, laptops, smart phones – heads up - the
                total may surprise you?
              </Typography>
              <Typography
                variant='body2'
                style={{
                  color: '#444444de',
                  fontSize: '12px',
                  fontWeight: '500',
                  letterSpacing: '1px',
                }}
              >
                High energy visible lights by devices or sun exposure leads to
                accelerated ageing and pigmentation.{' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='timeUnderSun'
                value={values.timeUnderSun}
                exclusive
                onChange={(e, nextView) =>
                  handleChange('timeUnderSun', e, nextView)
                }
                orientation='vertical'
              >
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='timeUnderSun'
                  style={{ color: '#000' }}
                  value='less than 3 hours of screen time'
                >
                  Less than 4
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='timeUnderSun'
                  style={{ color: '#000' }}
                  value='4-6 hours of screen time'
                >
                  4-6
                </ToggleButton>
                <ToggleButton
                  classes={{
                    root: 'toggleButton100',
                  }}
                  name='timeUnderSun'
                  style={{ color: '#000' }}
                  value='more than 6 hours of screen time'
                >
                  More than 6
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {Pagination({ nextDisable: values.timeUnderSun === null })}
          </Grid>
        );

      case 37:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h4'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                }}
              >
                We have just found your Products
              </Typography>
            </Grid>
            {Pagination({ nextDisable: true })}
          </Grid>
        );

      case 100:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h5'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  textAlign: 'center',
                  padding: '0 4rem',
                }}
              >
                We have diagonised your skin however a few more questions about
                your lifestyle can help us evaluate your skin better. Would you
                like to answer them or skip them for now?
              </Typography>
            </Grid>

            {Pagination({ nextDisable: false })}
          </Grid>
        );

      case 101:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            <Grid item xs={12} align='center'>
              <Typography
                variant='h3'
                style={{
                  fontWeight: 'bold',
                  color: '#222326',
                  marginTop: '15vh',
                }}
              >
                Subscription Plans
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              justify='center'
              spacing={4}
              alignItems='center'
              className='text-center'
            >
              <ToggleButtonGroup
                size='small'
                name='ageGroup'
                value={values.subscription}
                exclusive
                // onChange={(e) =>setSub(e.target.value)}
                onChange={(e, nextView) =>
                  handleChange('subscription', e, nextView)
                }
                orientation='vertical'
              >
                <ToggleButton
                  name='subscription plans'
                  value='1'
                  style={{ color: '#000', fontSize: '2.7vh' }}
                >
                  1 Month
                </ToggleButton>

                <ToggleButton
                  name='subscription plans'
                  value='3'
                  style={{ color: '#000', fontSize: '2.7vh' }}
                >
                  3 Months
                </ToggleButton>
                <ToggleButton
                  name='subscription plans'
                  value='6'
                  style={{ color: '#000', fontSize: '2.7vh' }}
                >
                  6 Months
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            {Pagination({ nextDisable: true })}
          </Grid>
        );

      default:
        return (
          <Grid
            container
            xs={12}
            justify='center'
            spacing={4}
            alignItems='center'
          >
            {Pagination()}
          </Grid>
        );
    }
  };

  return (
    <Base>
      {isQues ? (
        <Grid
          container
          spacing={2}
          className='questionaire-home'
          alignItems='center'
          justify='center'
          style={{ padding: '3vh 0', margin: 0 }}
        >
          {/* <Grid md={2} item></Grid> */}
          <Grid
            item
            xs={12}
            alignItems='center'
            justify='center'
            className='text-center'
          >
            <Typography
              variant='h4'
              style={{
                fontWeight: 'bold',
                color: `${themeColor.primaryLight}`,
              }}
            >
              Skin Assessment
            </Typography>
            <div
              style={{
                width: '60%',
                marginTop: '2vh',
                marginLeft: '20%',
                color: '#ffffff',
              }}
            >
              <LinearProgress
                variant='determinate'
                className='text-center'
                value={(Page / 36) * 100}
              />
            </div>
          </Grid>
          {open ? (
            <Grid item xs={12} md={12} style={{ marginTop: '2vh' }}>
              <Paper
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '90%',
                  position: 'relative',
                  maxWidth: '52.5rem',
                  minHeight: '70vh',
                  height: 'fit-content',
                  padding: '2vw',
                  margin: '0 auto',
                  background: 'rgba(255, 255, 255, 0.8)',
                }}
              >
                <Grid
                  container
                  spacing={4}
                  justify='center'
                  alignItems='center'
                  style={{ padding: '1rem' }}
                >
                  {/* <Grid align="right" item xs={2}>
                                    <Button color="secondary" onClick={() => setOpen(!open)} ><IoIosClose color="black" size="5vh" /> </Button>

                                </Grid> */}

                  {getQuestions()}
                </Grid>
              </Paper>
            </Grid>
          ) : (
            <Grid item xs={12} md={3} align='center'>
              <img
                src={ques1}
                style={{ width: '80%', marginBottom: '2vh' }}
                alt='in'
              />

              <img
                src={ques1}
                style={{ width: '80%', marginBottom: '2vh' }}
                alt='in'
              />
              <img
                src={ques1}
                style={{ width: '80%', marginBottom: '2vh' }}
                alt='in'
              />

              <button
                className='btn col-10 font-weight-bolder btn-outline-dark '
                style={{ letterSpacing: '3px' }}
                onClick={() => {
                  setOpen(!open);
                }}
              >
                {' '}
                Take Test
              </button>
            </Grid>
          )}
        </Grid>
      ) : (
        <>
          {auth ? (
            <>
              <div className='subscription'>
                <h3 style={{ color: `${themeColor.primaryLight}` }}>
                  Your Products:
                </h3>
                <div
                  className='row justify-content-center'
                  style={{ padding: '2rem' }}
                >
                  {products.map((product, i) => {
                    return (
                      <div className='col-md-3'>
                        <div className='m-2'>
                          <NewCard
                            product={product}
                            showAddCart={false}
                            disableClick
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <Subscription menu={false} products={products} />
            </>
          ) : (
            <>
              <SignupComponnt setAuth={setAuth} auth={auth} />
            </>
          )}

          {/* <Gotopayments menu={false} products={products} /> */}
        </>
      )}
    </Base>
  );
};

export default Questionaire;
