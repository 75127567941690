import {
  Divider,
  Drawer,
  Grid,
  Typography,
  withStyles,
  Button,
  TextField,
} from '@material-ui/core';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import {
  getAllBrands,
  getAllCategories,
  getAllProducts,
} from '../../admin/helper/adminapicall';
import Base from '../Base';
import ProductLoadingCard from '../Cards/ProductLoadingCard';
import { filterProducts } from '../helper/coreapicalls';
import { searchProducts } from './../helper/coreapicalls';
import NewCard from '../NewCard';
import { AppContext } from '../../contexts/AppContext';

const styles = (theme) => ({
  filterTitle: {
    fontWeight: 500,
    fontSize: '0.85rem',
    color: '#9a1750',
    paddingBottom: '0.2rem',
    margin: '0.4rem 0',
    textTransform: 'uppercase',
    borderBottom: 'solid 1px #02020222',
  },
  filterContainer: {
    boxSizing: 'border-box',
    padding: ' 0.2rem',
  },
  filterSubmenus: {
    color: '#2d2444',
    fontSize: '0.85rem',
    cursor: 'pointer',
    marginBottom: '0.2rem',
    transition: '0.2s',
    '&:hover': {
      transform: 'scale(1.003)',
      color: theme.palette.primary.main,
    },
  },
  activeFilter: {
    transform: 'scale(1.002)',
    color: theme.palette.primary.main,
    position: 'relative',
    '&::before': {
      content: '"x"',
      position: 'absolute',
      right: '0',
      transform: 'scaleX(1.3)',
    },
  },
  drawer: {
    height: '100%',
    width: '17vmax',
    backgroundColor: '#F7ECEC10 !important',
    border: 'none',
  },
  drawerPaper: {
    top: '9vh',
    padding: '1rem',
    height: '100vh',
    width: '17vmax',
    backgroundColor: '#F7ECEC10 !important',
    position: 'sticky',

    borderColor: '#00000010',
  },
  accordion: {
    width: '100%',
    boxShadow: 'none',
  },
  filterHeader: {
    color: '#9a1750',
    fontSize: '0.9rem',
    fontWeight: 600,
    marginBottom: '0.31rem',
    textTransform: 'uppercase',
    letterSpacing: '2px',
  },
});

const fetchBrands = new Promise((resolve, reject) => {
  getAllBrands().then((data) => {
    if (data) {
      resolve(data);
    } else {
      reject(data);
    }
  });
});

const fetchCategories = new Promise((resolve, reject) => {
  getAllCategories().then((data) => {
    if (data) {
      resolve(data);
    } else {
      reject(data);
    }
  });
});

let debounceTimeout = null;

const AllProduct = ({ classes }) => {
  const [Products, setProducts] = useState([]);
  const [Categories, setCategories] = useState([]);
  const [Brands, setBrands] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [totalCount, setTotalCount] = useState(0);
  const [searchActive, setSearchActive] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [categoriesFilter, setCategoriesFilter, brandFilter, setBrandFilter] =
    useContext(AppContext);

  const preload = async () => {
    const datas = await Promise.all([fetchCategories, fetchBrands]);
    setCategories(datas[0]);
    setBrands(datas[1]);
  };

  useEffect(() => {
    preload();
    window.scrollTo(0, 0);
  }, []);

  const searchData = (page, pageSize, searchQuery) => {
    setLoading(true);
    searchProducts(
      categoriesFilter,
      brandFilter,
      page,
      pageSize,
      searchQuery
    ).then((data) => {
      if (data && data.error) {
        console.log(data.error);
        setLoading(false);
      } else {
        if (page === 1) {
          setProducts(data?.products);
        } else {
          setProducts([...Products, ...data?.products]);
        }
        setTotalCount(data?.totalCount);
        setLoading(false);
      }
    });
  };

  const filterData = (page, pageSize) => {
    setLoading(true);
    filterProducts(categoriesFilter, brandFilter, page, pageSize).then(
      (data) => {
        if (data && data.error) {
          console.log(data.error);
          setLoading(false);
        } else {
          if (page === 1) {
            setProducts(data?.products);
          } else {
            setProducts([...Products, ...data?.products]);
          }
          setTotalCount(data?.totalCount);
          setLoading(false);
        }
      }
    );
  };

  const addCategoryFilter = (categoryId) => {
    setPage(1);
    if (categoriesFilter.indexOf(categoryId) === -1)
      setCategoriesFilter([...categoriesFilter, categoryId]);
    else {
      setCategoriesFilter(categoriesFilter.filter((i) => i !== categoryId));
    }
  };

  const addBrandFilter = (brandId) => {
    setPage(1);
    if (brandFilter.indexOf(brandId) === -1)
      setBrandFilter([...brandFilter, brandId]);
    else {
      setBrandFilter(brandFilter.filter((i) => i !== brandId));
    }
  };

  useEffect(() => {
    setLoading(true);
    if (searchActive) {
      searchData(page, pageSize, searchText);
    } else {
      filterData(page, pageSize);
    }
  }, [page]);

  useEffect(() => {
    if (searchActive) {
      searchData(page, pageSize, searchText);
    } else {
      filterData(page, pageSize);
    }
  }, [categoriesFilter, brandFilter]);

  const searchProduct = (search) => {
    setSearchText(search);
    if (search && typeof search === 'string' && search.trim().length > 0) {
      setSearchActive(true);
      searchData(page, pageSize, search);
    } else {
      setSearchActive(false);
      filterData(page, pageSize);
    }
  };

  const debounce = (event, delay) => {
    const searchQuery = event.target.value;
    return () => {
      if (debounceTimeout) clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => {
        searchProduct(searchQuery);
      }, delay);
    };
  };

  return (
    <Base>
      <Grid container style={{ minHeight: '100vh' }}>
        <div style={{}}>
          <Drawer
            anchor='left'
            open={true}
            variant='persistent'
            className={classes.drawer}
            classes={{ paper: classes.drawerPaper }}
          >
            <div className={classes.filterContainer}>
              <div className={classes.accordion}>
                <Typography className={classes.filterHeader}>
                  Filters
                </Typography>
              </div>
            </div>
            <div className={classes.filterContainer}>
              <div className={classes.accordion}>
                <div>
                  <Typography className={classes.filterTitle}>
                    Categories
                  </Typography>
                </div>
                <div style={{ flexDirection: 'column' }}>
                  {Categories &&
                    Categories?.length > 0 &&
                    Categories.map((item) => (
                      <Typography
                        key={item._id}
                        variant='body2'
                        component='div'
                        onClick={() => addCategoryFilter(item._id)}
                        className={classNames(classes.filterSubmenus, {
                          [classes.activeFilter]:
                            categoriesFilter.indexOf(item._id) !== -1,
                        })}
                      >
                        {item.name}
                      </Typography>
                    ))}
                </div>
              </div>
            </div>
            <div className={classes.filterContainer}>
              <div className={classes.accordion}>
                <div>
                  <Typography variant='body1' className={classes.filterTitle}>
                    Brands
                  </Typography>
                </div>
                <div style={{ flexDirection: 'column' }}>
                  {Brands &&
                    Brands?.length > 0 &&
                    Brands.map((item) => (
                      <Typography
                        variant='body2'
                        key={item._id}
                        component='div'
                        className={classNames(classes.filterSubmenus, {
                          [classes.activeFilter]:
                            brandFilter.indexOf(item._id) !== -1,
                        })}
                        onClick={() => addBrandFilter(item._id)}
                      >
                        {item.name}
                      </Typography>
                    ))}
                </div>
              </div>
            </div>
          </Drawer>
        </div>
        <Grid item style={{ paddingTop: '2vw' }} xs>
          <Grid container justify='center' alignItems='center'>
            <Grid
              item
              xs={12}
              className='align-self-start'
              style={{ width: '100%' }}
            >
              <Typography className='mx-3' variant='h5'>
                Search Products
              </Typography>
              <Divider
                className='mx-3 my-2'
                style={{
                  background: 'linear-gradient(to right,#9a1750,#fef0f0)',
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              className='align-self-start px-3'
              style={{ width: '100%' }}
            >
              <TextField
                type='text'
                className='searchBar my-3'
                label='Search'
                variant='outlined'
                onChange={(event) => debounce(event, 1000)()}
              />
            </Grid>
            <Grid
              item
              xs={12}
              className='align-self-start'
              style={{ width: '100%' }}
            >
              <Typography className='mx-3' variant='h5'>
                {searchActive
                  ? 'Based on your search, we found these products.'
                  : 'Recommended Products'}
              </Typography>
              <Divider
                className='mx-3 my-2'
                style={{
                  background: 'linear-gradient(to right,#9a1750,#fef0f0)',
                }}
              />
            </Grid>
            {Loading ? (
              new Array(8).fill(0).map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={`loading-product-${index}`}
                >
                  <ProductLoadingCard />
                </Grid>
              ))
            ) : Products && Products.length > 0 ? (
              <>
                {Products.map((product) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={product._id}>
                    <NewCard product={product}></NewCard>
                  </Grid>
                ))}
              </>
            ) : (
              <Grid item xs={12} className='justify-self-start my-3'>
                <Typography variant='body1' className='text-left mx-3  '>
                  Your customised filter doesn't have any product yet, please
                  try again after some time.
                </Typography>
              </Grid>
            )}
            {Products && Products.length > 0 && page * pageSize < totalCount && (
              <Grid
                item
                xs={12}
                style={{ textAlign: 'center', marginBottom: '2rem' }}
              >
                <Button
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setPage(page + 1);
                  }}
                >
                  Load More
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Base>
  );
};

export default React.memo(withStyles(styles, { withTheme: true })(AllProduct));
