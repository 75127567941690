import React, { useState, useEffect } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import Dashboard from './Dashboard';
import OrderCard from '../core/Cards/OrderCard.js';
import { themeColor } from '../utility/ColorPallete';
import { getAllOrders } from '../core/helper/OrderHelper';
import { isAuthenticated } from '../auth/helper';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from '@material-ui/core';
import Spinner from '../common/Spinner';
import { Row, Col, Card, CardBody, CardTitle, Button } from 'reactstrap';
import { downloadOrederData } from './helper/adminapicall';

const styles = () => ({
  root: {
    textAlign: 'center',
  },
  header: {
    fontSize: '2rem',
  },
  route: {},
  highlight: {
    color: themeColor.primary,
  },
});

const Orders = (props) => {
  const { user, token } = isAuthenticated();
  const [orders, setOrders] = useState([]);
  const [errors, setErrors] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const loadAllOrders = (user, page, pageSize) => {
    getAllOrders(user._id, token, page, pageSize).then((data) => {
      if (data?.error) {
        setErrors(data?.error);
        setLoading(false);
      } else {
        setOrders(data?.orders);
        setTotalCount(data?.totalCount);
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    // preload();
    setLoading(true);
    loadAllOrders(user, page, pageSize);
  }, [page]);
  const { classes } = props;
  // const orders =
  // [
  //     {id:"122-JJF4-443-558U", name:"Book #1", dateOrdered:"27 November 2020", totalCost:2800, address:"Rahul Mahato\n Home Address\n PIN 70001", status:"Delivered", dateDelivered:"27 December 2020", products:[{name:"Book #1", price:1800, author:"Rahul Mahato"}, {name:"Book #2", price:1800, author:"Rahul Mahato"}], trackingId:"1899293y383"},
  //     {id:"122-JJF4-443-558U", name:"Book #1", dateOrdered:"27 November 2020", totalCost:2800, address:"Rahul Mahato\n Home Address\n PIN 70001", status:"Delivered", dateDelivered:"27 December 2020", products:[{name:"Book #1", price:1800, author:"Rahul Mahato"}], trackingId:"1899293y383"},
  //     {id:"122-JJF4-443-558U", name:"Book #1", dateOrdered:"27 November 2020", totalCost:2800, address:"Rahul Mahato\n Home Address\n PIN 70001", status:"Delivered", dateDelivered:"27 December 2020", products:[{name:"Book #1", price:1800, author:"Rahul Mahato"}], trackingId:"1899293y383"}
  // ];
  return (
    <Dashboard>
      <div className={classes.root}>
        {orders !== undefined && orders.length !== 0 && (
          <Typography className={classes.header}>Our Orders</Typography>
        )}
        <div className="container-fluid h-100" style={{ position: 'relative' }}>
          <div className="row text-center mb-2 justify-content-center">
            {!loading && (
              <>
                <div className="col-12 border">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell>Amt</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Phone</TableCell>
                      </TableRow>
                    </TableHead>
                    {orders !== undefined && orders.length !== 0 ? (
                      orders.map((order, index) => (
                        <TableRow key={index}>
                          <TableCell>{order.orderStatus}</TableCell>
                          <TableCell>
                            {`₹ ${parseFloat(order?.amount).toFixed(2)}`}
                          </TableCell>
                          <TableCell>
                            {`${order.createdAt.substring(
                              8,
                              10
                            )}/ ${order.createdAt.substring(
                              5,
                              7
                            )}/ ${order.createdAt.substring(0, 4)}`}
                          </TableCell>
                          <TableCell>{`${order.address.city}, ${order.address.pin}`}</TableCell>
                          <TableCell>{`${order.address.fname} ${order.address.lname}`}</TableCell>
                          <TableCell>{order.address.phone}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          padding: '3rem',
                          textAlign: 'center',
                        }}
                      >
                        <h3
                          style={{
                            color: themeColor.primary,
                            height: '19.2rem',
                          }}
                        >
                          No Orders
                        </h3>
                      </div>
                    )}
                  </Table>
                </div>
                <div className="col-12 d-flex justify-content-between">
                  {page > 1 && (
                    <span
                      className="h5 text-dark cursor-pointer"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setPage(page - 1);
                      }}
                    >
                      Previous
                    </span>
                  )}
                  {page * pageSize < totalCount && (
                    <span
                      className="h5 text-dark cursor-pointer"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setPage(page + 1);
                      }}
                    >
                      Next
                    </span>
                  )}
                </div>
              </>
            )}
            {loading && <Spinner style={{ marginTop: '3rem' }} />}
          </div>
        </div>
        <Row>
          <Col xs="12" md="12" lg="4">
            <Card>
              <CardBody>
                <CardTitle>Download Complete Order List</CardTitle>
                <Button
                  onClick={() => {
                    downloadOrederData(undefined, user._id, token);
                  }}
                >
                  Export All Orders
                </Button>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" md="12" lg="4">
            <Card>
              <CardBody>
                <CardTitle>Download Complete Order List</CardTitle>
                <Button
                  onClick={() => {
                    downloadOrederData('one-time', user._id, token);
                  }}
                >
                  Export All One Time Orders
                </Button>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" md="12" lg="4">
            <Card>
              <CardBody>
                <CardTitle>Download Complete Order List</CardTitle>
                <Button
                  onClick={() => {
                    downloadOrederData('subscription', user._id, token);
                  }}
                >
                  Export All Subscription Orders
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Dashboard>
  );
};

export default withStyles(styles, { withTheme: true })(Orders);
