import React, { useState, useEffect } from 'react';
import { API } from '../backend';
import {
  getSingleBlog,
  getLatestBlogs,
  postComment,
} from './helper/blogHelper';
import { isAuthenticated } from '../auth/helper/index';
import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoTwitter,
  IoLogoLinkedin,
  IoMdSend,
  IoMdCopy,
} from 'react-icons/io';
import '../coming_soon_css.css';
import Base from './Base';
import { Link } from 'react-router-dom';
import Spinner from '../common/Spinner';
import circle_translucent from '../img/groupIcon/circle_translucent.png';
import { themeColor } from '../utility/ColorPallete';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import { data } from 'jquery';
import { Button } from '@material-ui/core';
import { TextareaAutosize } from '@material-ui/core';

const { user } = isAuthenticated();
const EachBlog = ({ match }) => {
  const { blogId } = match.params;
  const [blog, setBlog] = useState();
  const [loading, setLoading] = useState(true);
  const [LatestBlogs, setLatestBlogs] = useState([]);
  const [copyButtonClicked, setCopyButtonClicked] = useState(false);
  const Month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const [refresh, setRefresh] = useState(true);
  const [project, setProject] = useState({
    user: user,
    name: user?.name,
    text: '',
  });
  const { text } = project;
  const handleChange = (name) => (event) => {
    setProject({
      ...project,
      [name]: event.target.value,
    });
  };
  const nloading = () => {
    setRefresh(true);
  };
  const commentonpost = (event) => {
    event.preventDefault();
    if (project.text !== '') {
      postComment(project, blogId).then((data) => {
        if (data && data.eror) {
          toast.error(data.error);
        } else {
          toast.success('Commented Successfully');
          setProject({
            ...project,
            text: '',
          });
        }
      });
    }
    setRefresh(true);
  };
  const address = `${window.location.href}`;
  const copy = () => {
    const my = document.getElementById('linked');
    my.style.visibility = 'visible';
    my.select();
    document.execCommand('Copy');
    my.style.visibility = 'hidden';
    setCopyButtonClicked(true);
  };
  const preload = (blogId) => {
    Promise.all([
      getSingleBlog(blogId).then((data) => {
        if (data) {
          setBlog(data);
          setLoading(false);
        }
      }),
      getLatestBlogs().then((data) => {
        if (data) {
          setLatestBlogs(data);
          setRefresh(false);
        }
      }),
    ]);
  };

  useEffect(() => {
    preload(blogId);
  }, [refresh]);

  useEffect(() => {
    if (copyButtonClicked) {
      setTimeout(() => {
        setCopyButtonClicked(false);
      }, 1000);
    }
  }, [copyButtonClicked]);

  if (loading) {
    return <Spinner />;
  }

  const { author } = blog;
  return (
    <Base className="p-4">
      <ToastContainer position="bottom-right" autoClose={5000} />
      <section className="blog-intro px-2 pt-2 pb-5" id="eachBlogHeader">
        <div className="container-fluid p-0 h-100">
          <div className="row py-5 justify-content-center align-item-center text-center m-0">
            <div className="col align-self-center p-3">
              <p
                className="display-4 font-weight-bold mb-5"
                style={{ color: themeColor.primary }}
              >
                <Link to="/blogs" style={{ color: themeColor.primary }}>
                  Blogs
                </Link>{' '}
                / {blog.title}
              </p>
              <p
                className="h2 font-weight-bold"
                style={{ color: '#4a4a4aee' }}
                id="eachBlogSubtitle"
              >
                <FaQuoteLeft />
                <span
                  className="px-5"
                  id="eachBlogSubtitleHeading"
                  style={{ color: themeColor.primary }}
                >
                  Happiness Is A Habit, So Is Your Skincare
                </span>
                <FaQuoteRight />
              </p>
            </div>
          </div>
        </div>
        <div
          className="container h-100 border bg-light shadow-lg"
          style={{
            borderRadius: '10px',
          }}
        >
          <div
            className="row justify-content-center align-items-center mx-0 w-100 p-5 border-left-warning"
            id="contentCardRow"
          >
            <div
              className="col-lg-8 col-sm-12 align-self-center"
              id="contentCardCol"
            >
              <img
                src={`${API}/blog/largePhoto/${blogId}`}
                alt=""
                className="img-fluid"
              />
              <p
                className="h2 font-weight-bold pt-4"
                style={{ color: themeColor.primary }}
              >
                {blog.title}
              </p>
              <p className="post-author text-muted blockquote-footer">
                {author.name}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span className="float-right ">
                  {new Date(blog.createdAt).toDateString().replace(' ', ' , ')}
                </span>
              </p>
              <hr />
              <div  dangerouslySetInnerHTML={{__html:blog.description}} className="text-muted" id="blogDescription">
              </div>
              <div>
                <Button
                  onClick={copy}
                  variant="outlined"
                  className="my-2"
                  style={{ flex: '0 0 auto' }}
                >
                  {copyButtonClicked ? 'URL Copied' : 'Copy URL'}
                </Button>
              </div>
              <hr className="mt-2" />
              <div>
                {isAuthenticated() ? (
                  <>
                    <TextareaAutosize
                      aria-label="maximum height"
                      row={6}
                      rowMax={6}
                      name={text}
                      id={text}
                      placeholder="Submit a comment"
                      value={text}
                      onChange={handleChange('text')}
                      style={{
                        width: '100%',
                        padding: '10px',
                        border: 'none',
                        borderRadius: '10px',
                      }}
                    />
                    <div>
                      <Button
                        onClick={commentonpost}
                        variant="solid"
                        className="my-2"
                      >
                        <IoMdSend
                          style={{
                            padding: '0 0.4rem',
                            width: '2rem',
                            borderRadius: '50%',
                            height: '2rem',
                            cursor: 'pointer',
                            flex: '0 0 auto',
                          }}
                        />
                        Submit
                      </Button>
                    </div>
                  </>
                ) : (
                  ''
                )}
                <input
                  id="linked"
                  type="text"
                  name={address}
                  value={address}
                  style={{
                    width: '70%',
                    padding: '5px',
                    border: 'none',
                    borderRadius: '10px',
                    visibility: 'hidden',
                  }}
                ></input>
              </div>
              {blog.comments.length === 0 ? (
                ''
              ) : (
                <h3
                  style={{ paddingTop: '1vh' }}
                  style={{ color: themeColor.primary }}
                >
                  Comments
                </h3>
              )}
              <hr></hr>
              {blog.comments.length === 0
                ? ''
                : blog.comments.map((obj, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          fontSize: '0.8rem',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <p
                            style={{ fontWeight: 'bold' }}
                            style={{ color: themeColor.primary }}
                          >
                            {obj.name && obj.name.length > 0
                              ? obj.name
                              : 'Anonymous User'}
                          </p>
                          <p>
                            {obj.date.substring(8, 10)}{' '}
                            {Month[parseInt(obj.date.substring(5, 7) - 1)]},{' '}
                            {obj.date.substring(0, 4)}
                          </p>
                        </div>
                        <div>{obj.text}</div>
                        <hr></hr>
                      </div>
                    );
                  })}
            </div>
            <div className="col-lg-4 col-sm-12 align-self-start pr-0">
              <div className="row mx-0">
                <div className="col-12 order-lg-2 mb-4">
                  <div className="about-author text-center mt-5">
                    <img
                      src={`${API}/author/authorPhoto/${author._id}`}
                      alt=""
                      className="author-thumb img-fluid img-thumbnail rounded-circle mt-4"
                    />
                    <p
                      className="lead font-weight-bold m-0"
                      style={{ color: themeColor.primary }}
                    >
                      {author.name}
                    </p>
                    <p className="text-muted m-0">{author.tag}</p>

                    <div className="social-icons">
                      {author.facebook ? (
                        <a href={author.facebook} className="dribbble">
                          {' '}
                          <IoLogoFacebook className="author-links" />
                        </a>
                      ) : null}
                      {author.instagram ? (
                        <a href={author.instagram} className="dribbble">
                          {' '}
                          <IoLogoInstagram className="author-links" />
                        </a>
                      ) : null}
                      {author.tweeter ? (
                        <a href={author.tweeter} className="dribbble">
                          {' '}
                          <IoLogoTwitter className="author-links" />
                        </a>
                      ) : null}
                      {author.linkedIn ? (
                        <a href={author.linkedIn} className="dribbble">
                          {' '}
                          <IoLogoLinkedin className="author-links" />
                        </a>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="col-12 order-lg-1 p-0">
                  <p
                    className="h4 font-weight-bold"
                    style={{ color: themeColor.primary }}
                  >
                    Latest Posts
                  </p>
                  <div className="latest-posts">
                    <div className="row mx-0 ">
                      <div className="col-sm-8 col-lg-12">
                        {LatestBlogs.length > 0
                          ? LatestBlogs.map((item) => (
                              <div className="border-left  row my-0 py-2">
                                <div
                                  className="col-sm-4 col-md-3 align-self-center p-0"
                                  style={{ width: 'auto' }}
                                >
                                  <Link to={`/blogs/details/${item._id}`}>
                                    <img
                                      src={`${API}/blog/smallPhoto/${item._id}`}
                                      alt=""
                                      className="post-side-img img-fluid rounded-circle d-inline"
                                      onClick={{ nloading }}
                                    />
                                  </Link>
                                </div>
                                <div
                                  className="col-md-9 col-sm-8 align-self-center text-left"
                                  style={{ width: 'fit-content' }}
                                >
                                  <div className="side-post-details">
                                    <h5
                                      className="font-weight-bold m-0"
                                      onClick={{ nloading }}
                                      style={{ color: themeColor.primary }}
                                    >
                                      {item.title}
                                    </h5>
                                    <p className="side-pen text-muted small lead p-0 m-0">
                                      Author - {item.author.name}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src={circle_translucent}
          alt="cursiveLogo"
          className="fadeRightAnim img-fluid"
        />
      </section>
    </Base>
  );
};

export default EachBlog;
