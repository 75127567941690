import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { isAuthenticated } from '../auth/helper';
import { getAllProducts, deleteProduct } from './helper/adminapicall';
import Dashboard from './Dashboard';
import { API } from '../backend';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from '@material-ui/core';
import Spinner from '../common/Spinner';
import { ToastContainer, toast } from 'react-toastify';

const ManageProducts = () => {
  const [products, setProducts] = useState([]);
  const { user, token } = isAuthenticated();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const preload = (page, pageSize) => {
    getAllProducts(page, pageSize).then((data) => {
      if (data)
        if (data.error) {
          console.log(data.error);
          setLoading(false);
        } else {
          setProducts(data?.products);
          setTotalCount(data?.totalCount);
          setLoading(false);
        }
    });
  };

  useEffect(() => {
    setLoading(true);
    preload(page, pageSize);
  }, [page]);

  const deleteThisProduct = (productId) => {
    deleteProduct(user._id, productId, token).then((data) => {
      if (data)
        if (data.error) {
          console.log(data.error);
        } else {
          toast.success('Product deleted successfully! ...Reloading');
          if (products?.length % pageSize === 1 && page > 1) setPage(page - 1);
          setTimeout(() => {
            preload(page - 1, pageSize); //reloading the component after deleting
          }, 2000);
        }
    });
  };

  return (
    <Dashboard>
      <ToastContainer position='bottom-right' autoClose={5000} />
      <div className='container h-100'>
        <div className='row justify-content-center align-item-center'>
          <div className='col-12'>
            <p className='h3 text-gray-800 font-weight-bold'>All Products</p>
            <hr />
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <h2 className='text-center text-dark my-3'>
              Total {products?.length} products
            </h2>
            <div className='row text-center mb-2 justify-content-center'>
              {!loading && (
                <>
                  <div className='col-12 border'>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>

                          <TableCell>Category</TableCell>
                          <TableCell>Brand</TableCell>
                        </TableRow>
                      </TableHead>
                      {products &&
                        products?.length > 0 &&
                        products.map((product, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{product.name}</TableCell>

                              <TableCell>{product.category?.name}</TableCell>
                              <TableCell>{product.brand?.name}</TableCell>
                              <TableCell>
                                <Link
                                  className='btn  text-light  w-100'
                                  style={{ background: '#3f3f44' }}
                                  to={`/admin/product/update/${product._id}`}
                                >
                                  <span className=''>Update</span>
                                </Link>
                              </TableCell>
                              <TableCell>
                                <button
                                  onClick={() => {
                                    deleteThisProduct(product._id);
                                  }}
                                  style={{ background: 'red' }}
                                  className='btn text-light w-100'
                                >
                                  Delete
                                </button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </Table>
                  </div>
                  <div className='col-12 d-flex justify-content-between'>
                    {page > 1 && (
                      <span
                        className='h5 text-dark cursor-pointer'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setPage(page - 1);
                        }}
                      >
                        Previous
                      </span>
                    )}
                    {page * pageSize < totalCount && (
                      <span
                        className='h5 text-dark cursor-pointer'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setPage(page + 1);
                        }}
                      >
                        Next
                      </span>
                    )}
                  </div>
                </>
              )}
              {loading && <Spinner style={{ marginTop: '3rem' }} />}
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default ManageProducts;
