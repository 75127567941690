import React, { Component } from 'react';

import { Link, NavLink } from 'react-router-dom';
import logo from '../img/navbar-cg1.png';
import {
  IoIosClose,
  IoIosCart,
  IoIosHome,
  IoIosBookmarks,
  IoIosPaper,
  IoIosCall,
  IoIosPerson,
} from 'react-icons/io';
import { BiMessageAltDetail } from 'react-icons/bi';
import { FaBloggerB } from 'react-icons/fa';
import { HiTemplate } from 'react-icons/hi';
import { VscPreview } from 'react-icons/vsc';
import { RiHistoryFill } from 'react-icons/ri';
import { isAuthenticated, signout } from '../auth/helper/index';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Button } from '@material-ui/core';
import { themeColor } from '../utility/ColorPallete';
class Menu extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.toggleCollapse = this.toggleCollapse.bind();
  }

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  render() {
    return (
      <nav
        className="navbar navbar-expand-lg  justify-content-between sticky-top   border-bottom"
        style={{ background: `${themeColor.primary}`, height: '5.5rem',width:'100%',position:'fixed' }}
      >
        <div className="container-fluid">
          <span className="navbar-toggler border-0">
            <GiHamburgerMenu
              size="3vh"
              color="#e3e2df"
              onClick={this.toggleCollapse}
            />
          </span>
          <div className="collapse navbar-collapse  dual-nav w-50 order-1 order-md-0 ">
            <ul className="navbar-nav ">
              <li className="nav-item ">
                <NavLink className="nav-link  " to="/" exact>
                  {/* <IoIosHome className="mr-1 my-1" size={13} /> */}
                  Home
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink className="nav-link " to="/aboutUs">
                  About Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/blogs">
                  Blogs
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link  " to="/product">
                  Our Products
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link  " to="/reviews">
                  Our Reviews
                </NavLink>
              </li>
              {isAuthenticated() && (
                <li className="nav-item">
                  <NavLink className="nav-link  " to="/orders">
                    Orders
                  </NavLink>
                </li>
              )}
            </ul>
          </div>

          <Link
            to="/"
            className="navbar-brand mx-auto d-block text-center order-0 order-md-1 w-25"
            style={{ fontSize: '3vh' }}
          >
            <img
              src={logo}
              className=""
              alt="company-logo"
              style={{ width: '12.56rem' }}
            />
          </Link>
          <div className="navbar-collapse collapse dual-nav w-50 order-2">
            <ul className="navbar-nav ml-auto align-items-center">
              {isAuthenticated() ? (
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/signin"
                    onClick={() => {
                      signout();
                    }}
                  >
                    <IoIosBookmarks className=" mr-1" size={15} />
                    Sign Out
                  </NavLink>
                </li>
              ) : (
                <li className="nav-item">
                  <NavLink className="nav-link" to="/signin">
                    <IoIosPerson size={13} /> Sign In
                  </NavLink>
                </li>
              )}
              {isAuthenticated() ? (
                <li className="nav-item ">
                  <NavLink className="nav-link" to="/cart">
                    <IoIosCart size="0.9rem" />
                  </NavLink>
                </li>
              ) : (
                ''
              )}
              <li className="nav-item">
                <NavLink className="nav-link" to="/contactUs">
                  <IoIosCall size="0.9rem" />
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/skinquiz">
                  <Button variant="contained" style={{ background: '#f3e6e8' }}>
                    <IoIosPaper className="my-1" />{' '}
                    <span
                      style={{ fontSize: '0.7rem', margin: '0.1rem 0.25rem' }}
                    >
                      TAKE SKIN QUIZ
                    </span>
                  </Button>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        {this.state.isOpen ? (
          <div
            style={{
              background: '#EFC9BE77',
              position: 'fixed',
              top: 0,
              left: 0,
              height: '100vh',
              width: '100vw',
            }}
          >
            <div className="" id="sidebar">
              <div className="text-right p-2">
                <IoIosClose
                  onClick={this.toggleCollapse}
                  color="#FFFFFFAB"
                  size="7vw"
                />
              </div>
              <ul className="navbar-nav ml-auto">
                <li className="nav-item ">
                  <NavLink
                    exact
                    className="nav-link"
                    to="/"
                    style={{ fontSize: '1rem' }}
                  >
                    <IoIosHome className="mb-1 mx-2" />
                    Home
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink
                    className="nav-link"
                    to="/aboutUs"
                    style={{ fontSize: '1rem' }}
                  >
                    <BiMessageAltDetail className="mb-1 mx-2" />
                    About Us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/blogs"
                    style={{ fontSize: '1rem' }}
                  >
                    <FaBloggerB className="mb-1 mx-2" /> Blogs
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/product"
                    style={{ fontSize: '1rem' }}
                  >
                    <HiTemplate className="mb-1 mx-2" /> Our Products
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/reviews"
                    style={{ fontSize: '1rem' }}
                  >
                    <VscPreview className="mb-1 mx-2" /> Our Reviews
                  </NavLink>
                </li>
                {isAuthenticated() && (
                  <li className="nav-item">
                    <NavLink
                      className="nav-link  "
                      to="/orders"
                      style={{ fontSize: '1rem' }}
                    >
                      <RiHistoryFill className="mb-1 mx-2" />
                      Orders
                    </NavLink>
                  </li>
                )}
                {isAuthenticated() ? (
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      to="/signin"
                      style={{ fontSize: '1rem' }}
                      onClick={() => {
                        signout();
                      }}
                    >
                      <IoIosBookmarks className="mb-1 mx-2" />
                      SignOut
                    </NavLink>
                  </li>
                ) : (
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      to="/signin"
                      style={{ fontSize: '1rem' }}
                    >
                      <IoIosBookmarks className="mb-1 mx-2" /> Signin
                    </NavLink>
                  </li>
                )}
                {isAuthenticated() ? (
                  <li className="nav-item ">
                    <NavLink
                      className="nav-link"
                      to="/cart"
                      style={{ fontSize: '1rem' }}
                    >
                      <IoIosCart className="mb-1 mx-2" />
                      Cart
                    </NavLink>
                  </li>
                ) : (
                  ''
                )}
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/contactUs"
                    style={{ fontSize: '1rem' }}
                  >
                    <IoIosCall className="mb-1 mx-2" />
                    Contact Us
                  </NavLink>
                </li>
              </ul>
              <NavLink className="nav-link" to="/skinquiz">
                <Button
                  variant="contained"
                  style={{ background: '#f3e6e8', fontSize: '1rem' }}
                >
                  <IoIosPaper className="mb-1 mr-2" /> TAKE SKIN QUIZ
                </Button>
              </NavLink>
            </div>
          </div>
        ) : null}
      </nav>
    );
  }
}

export default Menu;
