import React from 'react'

const UpdateCategory = ()=> {
    return (
        <div>
            <h1 className="text-white">Hi</h1>
        </div>
    )
}

export default UpdateCategory;