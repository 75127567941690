import React from 'react';
import Base from './Base';
import DermatologistSVG from '../img/dermatologist.svg';
import SkincareSVG from '../img/skincare.svg';
import QualityAssurance from '../img/qualityAssurance.svg';
import Research from '../img/research.svg';
import BeautyTreatmentSVG from '../img/beauty-treatment.svg';
import ourStory from '../img/OurStory.png';
import { Grid, Typography } from '@material-ui/core';
import groupLogo from '../img/groupIcon/logo_cropped.png';
import circle_translucent from '../img/groupIcon/circle_translucent.png';
import circleOgLogo from '../img/1navbar-cg.png';
import dictionaryData from '../common/ingredientDictionary.json';
import { GiChemicalDrop, GiMolecule } from 'react-icons/gi';
import { GoBeaker } from 'react-icons/go';
import { FaLeaf, FaDog } from 'react-icons/fa';
import { themeColor } from '../utility/ColorPallete';
import img1 from '../img/homeIcon/1.png';
import img2 from '../img/homeIcon/2.png';
import img3 from '../img/homeIcon/3.png';
import img4 from '../img/homeIcon/4.png';
import img5 from '../img/homeIcon/5.png';
import img6 from '../img/homeIcon/6.png';

const theme = {
  safetyIcon: {
    width: '8rem',
    fontSize: '5rem',
    color: '#222326',
  },
};

const AboutUs = () => {
  const [ingredients, setIngredients] = React.useState(
    dictionaryData.dictionary
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Base>
      <Grid
        container
        alignItems='flex-end'
        justify='space-evenly'
        className='p-5 p-sm-2 my-5'
      >
        <Grid item xs={7} md={4}>
          <img
            src={ourStory}
            alt='.'
            width='100%'
            className='fadeRightAnim about-who-container'
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <img
            src={circle_translucent}
            alt='cursiveLogo'
            className='fadeRightAnim img-fluid'
          />
          <hr
            className=' w-50 bg-yellow-primary'
            style={{ marginBottom: '3rem', height: '1.5rem' }}
          />
          <Typography variant='body1' className='fadeLeftAnim'>
            <h3 style={{ color: themeColor.primary }}>Vision</h3>
            <p className='text-justify'>
              At Circle Skincare, our ambition is to become the most trusted
              personalized skincare brand and offer “Just What Your Skn Needs”. 
            </p>
            <hr />
            <h3 style={{ color: themeColor.primary }}>Mission</h3>
            <p className='text-justify'>
              We aspire to enrich customer satisfaction by providing innovative
              products that cater to their personalized needs. <hr />
            </p>
            <h3 style={{ color: themeColor.primary }}>Value</h3>
            <p className='text-justify'>
              We aim to provide effective skincare products fitting our
              customers’ needs and skin type. We firmly believe in our vision,
              “Just What Your Skin Needs”, and abide by it, thereby offering
              customers a range of result-oriented, tailor-made products to
              cater to the exact needs of their skin.
            </p>{' '}
            <hr />
          </Typography>
        </Grid>
        <Grid item xs={12} md={10} className='fadeLeftAnim text-sm-justify'>
          <h3 style={{ color: themeColor.primary }}>About Us</h3>
          <p className='text-justify'>
            Your skin has a story to tell, and here at Circle Skincare, we are
            all ears for your skincare journey and every specific need of your
            skin. Circle Skincare is a personalized skincare brand, designed to
            meet the specific needs of your skin. We assess the skin issues
            based on machine learning model(s) backed by a team of
            dermatologists and offer products & advice exactly as per your skin
            needs.
          </p>{' '}
          <p className='text-justify'>
            We assess the skin and hair care needs of every customer to provide
            easy and faster solutions. Our dermatologists advise our customers
            about each product and its usage. 
          </p>{' '}
          <p className='text-justify'>
            The concept of personalized skin care was borne out of the idea of
            how skincare was such hype, but still, a majority of the population
            opts for mass-produced products. We offer a skincare regime kit,
            meeting the exact needs of your skin, where we incorporate the
            goodness of active ingredients and dermatologists' advice. Our long
            list of personalized skincare products includes face wash,
            moisturizers, sunscreen creams, night cream, and many more products
            targeted to meet the specific needs of every individual.
            <hr />
          </p>
        </Grid>
      </Grid>

      <section className=' bg-black-faded relDisplay'>
        <div className=' py-5'>
          <Grid
            container
            alignItems='center'
            className='p-lg-5 w-100 m-0'
            justify='center'
            spacing={3}
          >
            <Grid item xs={12}>
              <h1 className='font-weight-bold h1 txt-faded-primary text-center'>
                Why Circle Skincare?
              </h1>
              <hr
                className='mx-auto w-25 bg-faded-primary'
                style={{ marginBottom: '3rem', height: '0.178rem' }}
              />
            </Grid>

            <Grid item xs={12}>
              <div className='px-0 pb-5 mx-0 w-100'>
                <Grid
                  container
                  justify='center'
                  alignItems='center'
                  spacing={2}
                  className='pb-5'
                >
                  <Grid item xs={12} md={4}>
                    <div className='card-features'>
                      <div>
                        <img src={SkincareSVG} alt='..' />
                      </div>
                      <p className='txt-black-faded text-justify mb-0'>
                        Ensures Product Efficacy And Formulation Safety
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className='card-features'>
                      <div>
                        <img src={QualityAssurance} alt='derma' />
                      </div>

                      <p className='txt-black-faded text-justify mb-0'>
                        Manufactured and Delivered Under Strict Safety And
                        Quality Standards
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className='card-features'>
                      <div>
                        <img src={Research} alt='derma' />
                      </div>

                      <p className='txt-black-faded text-justify mb-0'>
                        Personalized Regimen Recommended by Dermatologists
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className='card-features'>
                      <div>
                        <img src={BeautyTreatmentSVG} alt='derma' />
                      </div>

                      <p className='txt-black-faded text-justify mb-0'>
                        Focuses On Sustained Solution For Exact Skin Needs
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className='card-features'>
                      <div>
                        <img src={DermatologistSVG} alt='derma' />
                      </div>
                      <p className='txt-black-faded text-justify mb-0'>
                        Delivers Just What Your Skin Needs
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
        <img
          src={groupLogo}
          alt='groupIcon'
          className='fadeRightAnim img-fluid absoluteImageBottom px-4 w-100'
        />
      </section>
      <section className='safety'>
        <div
          className='container text-center pb-5'
          style={{ maxWidth: 'inherit' }}
        >
          <div className='row mx-0 w-100 justify-content-center align-item-center py-4'>
            <div className='col-12 py-5'>
              <p className='h1 txt-black-faded font-weight-bold'>
                Your{' '}
                <span style={{ color: `${themeColor.primaryLight}` }}>
                  Aesthetics
                </span>{' '}
                 and Planet's{' '}
                <span style={{ color: `${themeColor.primaryLight}` }}>
                  Safety
                </span>{' '}
                - Our Priority
              </p>
            </div>
            {/* <div className="col-1"></div> */}
            <div className='col-lg-2 col-md-4 col-6 p-3'>
              <img src={img1} style={theme.safetyIcon} />
            </div>
            <div className='col-lg-2 col-md-4 col-6 p-3'>
              <img src={img2} style={theme.safetyIcon} />
            </div>
            <div className='col-lg-2 col-md-4 col-6 p-3'>
              <img src={img3} style={theme.safetyIcon} />
            </div>
            <div className='col-lg-2 col-md-4 col-6 p-3'>
              <img src={img4} style={theme.safetyIcon} />
            </div>
            <div className='col-lg-2 col-md-4 col-6 p-3'>
              <img src={img5} style={theme.safetyIcon} />
            </div>
            <div className='col-lg-2 col-md-4 col-6 p-3'>
              <img src={img6} style={theme.safetyIcon} />
            </div>
            {/* <div
              className='col-lg-2 col-md-4 col-6 p-3'
              style={{ opacity: '0.75' }}
            >
              <GoBeaker style={theme.safetyIcon} />
              <p
                className='h6 pt-3'
                style={{ color: `${themeColor.primaryLight}`, opacity: '1' }}
              >
                No Phthalates
              </p>
            </div>
            <div
              className='col-lg-2 col-md-4 col-6 p-3'
              style={{ opacity: '0.75' }}
            >
              <FaLeaf style={theme.safetyIcon} />
              <p
                className='h6 pt-3'
                style={{ color: `${themeColor.primaryLight}`, opacity: '1' }}
              >
                No Paraben
              </p>
            </div>
            <div
              className='col-lg-2 col-md-4 col-6 p-3'
              style={{ opacity: '0.75' }}
            >
              <GiMolecule style={theme.safetyIcon} />
              <p
                className='h6 pt-3'
                style={{ color: `${themeColor.primaryLight}`, opacity: '1' }}
              >
                No Formaldehyde
              </p>
            </div>
            <div
              className='col-lg-2 col-md-4 col-6 p-3'
              style={{ opacity: '0.75' }}
            >
              <FaDog style={theme.safetyIcon} />
              <p
                className='h6 pt-3'
                style={{ color: `${themeColor.primaryLight}`, opacity: '1' }}
              >
                No Animal Harmed
              </p>
            </div>
            <div className="col-1"></div> */}
          </div>
        </div>
      </section>

      {/* <section className='pt-4' style={{ background: '#F2F1EF' }}>
        <div className='container-fluid mx-0 pt-5 pb-3'>
          <div className='row w-100 mx-0 py-4'>
            <div className='col-12 text-center px-0'>
              <h1 className='font-weight-bold h1 txt-faded-primary text-center'>
                The Complete Ingredients Journal
              </h1>
              <hr
                className='mx-auto w-25 bg-faded-primary'
                style={{ marginBottom: '3rem', height: '0.178rem' }}
              />
              <div className='container'>
                <div className='row'>
                  <div className='col-12  txt-faded-primary'>
                    <p className='pt-4 pb-2 text-justify font-weight-bolder'>
                      Please note - all the listed ingredients might not be
                      added to your formulation as your unique blend will have
                      only those ingredients which are meticulously picked by
                      our scientists - in the right concentration, that is
                      perfect for your hair needs.
                    </p>
                    <h5 className='font-weight-bold'>
                      - Meticulously Picked For Your Unique Hair -
                    </h5>
                    <img
                      src={circleOgLogo}
                      alt='cursiveLogo'
                      className='fadeRightAnim img-fluid py-3'
                      style={{ width: '22.23rem' }}
                    />
                  </div>
                </div>
              </div>
              <div className='container-fluid mx-0 pt-4'>
                <div className='row w-100 m-0'>
                  {ingredients.map((e) => (
                    <div className='col-md-4 text-center p-3 '>
                      <h5 className='font-weight-bold txt-faded-primary '>
                        {e.name}
                      </h5>
                      <div className='font-weight-bolder dictionaryEffects'>
                        {e.effect.map((chip) => (
                          <span className='effectsChip'>{chip}</span>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid mx-0'>
          <div className='row w-100 mx-0 py-4'>
            <div className='col-12 text-right px-0'>
              <img
                src={circle_translucent}
                alt='groupIcon'
                className='fadeRightAnim img-fluid px-4'
                style={{ width: '22.24rem' }}
              />
            </div>
          </div>
        </div>
      </section> */}
    </Base>
  );
};

export default AboutUs;
