import React from 'react';
import '../admin-dashboard.css';
import logo from '../../img/navbar-cg1.png';

const AdminRender = () => {
  return (
    <div>
      <div className="container-fluid" style={{ height: '100vh' }}>
        <div className="row text-center d-flex h-100">
          <div className="col-12 align-self-center">
            <img src={logo} alt="Circle Logo" style={{ width: '40rem' }} />
            <div className=" font-weight-bold text-warning text-uppercase mb-1">
              Dashboard
            </div>
            <div className="h2 mb-0 font-weight-bold text-gray-800">
              Welcome Admin!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminRender;
