import React, { useState } from 'react';
import Base from '../core/Base';
import { Link, useHistory } from 'react-router-dom';
import { signup, sendOtp } from '../auth/helper';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import signupBg from '../img/signup.png';
import SigninComponent from './SigninComponent';

const styles = (theme) => ({
  root: {
    // minHeight: '40rem',
    margin: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    backgroundImage: `url(${signupBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#FFF',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(2, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: '35rem',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

const Signup = ({ classes, setAuth = (f) => f, auth }) => {
  const [values, setValues] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
    error: '',
    otp: '',
    success: false,
  });
  const [signedIn, setsignedIn] = useState(false);
  // const [addotp, setAddotp] = useState(false);
  const history = useHistory();
  //To access above use values.name values.email or destructure it
  const { name, email, phone, otp, password, error, success } = values;

  //change values as the user gives input (higher order function)
  const handleChange = (key) => (event) => {
    //key is the field we need to change
    //Using setvalues to set the actual value ... spread operator to load all value keys
    setValues({
      ...values,
      error: false,
      [key]: event.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    signup({ name, email, password, phone }).then((data) => {
      if (data) {
        if (data.success) {
          toast.success('You have signed up successfully!');
          setsignedIn(true);
        } else {
          setValues({ ...values, error: data.error, success: false });
          toast.error('Sign Up Failed');
        }
      }
    });
  };

  const successMessage = () => {
    return (
      <div
        className="alert alert-success"
        style={{ display: success ? '' : 'none' }}
      >
        New Account was successfully. Please{' '}
        <Link to="/signin">Login here</Link>
      </div>
    );
  };

  const errorMessage = () => {
    return (
      <div
        className="alert alert-danger"
        style={{ display: error ? '' : 'none' }}
      >
        {error}
      </div>
    );
  };

  return signedIn ? (
    <>
      <SigninComponent
        setSign={setsignedIn}
        sign={signedIn}
        setAuth={setAuth}
        auth={auth}
      />
    </>
  ) : (
    <Grid container component="main" className={classes.root}>
      {/* <CssBaseline /> */}
      {/* <Grid item xs={false} sm={4} md={7} className={classes.image} /> */}
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          {/* <Avatar className={classes.avatar}> */}
          {/* <LockOutlinedIcon /> */}
          {/* </Avatar> */}
          <Typography component="h1" variant="h5">
            Please Login/Signup first.
          </Typography>
          {successMessage()}
          {errorMessage()}
          <ToastContainer position="bottom-right" autoClose={5000} />
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              type="text"
              value={name}
              onChange={handleChange('name')}
              autoFocus
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              type="email"
              value={email}
              onChange={handleChange('email')}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={handleChange('password')}
              autoComplete="current-password"
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="phone"
              label="Phone"
              name="phonee"
              autoComplete="phone"
              type="number"
              value={phone}
              onChange={handleChange('phone')}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={onSubmit}
            >
              Signup
            </Button>
            <Grid container>
              <Grid item>
                <Link
                  onClick={() => {
                    setsignedIn(true);
                  }}
                  variant="body2"
                >
                  {'Already have an account? Sign In'}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>{/* <Copyright /> */}</Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

// export default Signup;
export default withStyles(styles, { withTheme: true })(Signup);
