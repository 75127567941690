import React, { useState } from 'react';

const AppContext = React.createContext(undefined);

function AppStateProvider({ children }) {
  const [categoriesFilter, setCategoriesFilter] = useState([]);
  const [brandFilter, setBrandFilter] = useState([]);

  return (
    <AppContext.Provider
      value={[
        categoriesFilter,
        setCategoriesFilter,
        brandFilter,
        setBrandFilter,
      ]}
    >
      {children}
    </AppContext.Provider>
  );
}

export { AppContext, AppStateProvider };
