import React, { useState, useEffect } from 'react';
import '../styles.css';
import Base from './Base';
import { getAllBlogs } from './helper/blogHelper';
import BlogCard from './BlogCard';
import { themeColor } from '../utility/ColorPallete';
import circle_translucent from '../img/groupIcon/Group_4_logo.png';
import { FaPenNib, FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import Spinner from '../common/Spinner';
import { Typography, Button } from '@material-ui/core';

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [errors, setErrors] = useState(false);
  const [isloading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const loadAllBlogs = (page, pageSize) => {
    getAllBlogs(page, pageSize).then((data) => {
      if (data)
        if (data.error) {
          setErrors(data.error);
          setIsLoading(false);
        } else {
          setBlogs(data?.blogs);
          setTotalCount(data?.totalCount);
          setIsLoading(false);
        }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    loadAllBlogs(page, pageSize);
  }, [page]);

  return (
    <>
      {isloading && <Spinner />}
      {!isloading && (
        <Base className="container">
          <div
            className="row justify-content-center-align-item-center text-center mx-0"
            id="blogBanner"
            style={{ color: `${themeColor.primary}` }}
          >
            <div className="col align-self-center px-3 pb-5">
              <img
                src={circle_translucent}
                alt="cursiveLogo"
                className="fadeRightAnim img-fluid"
                style={{ width: '30rem' }}
              />
              <hr
                className="mx-auto w-25 bg-yellow-primary"
                style={{ marginBottom: '3rem', height: '0.176rem' }}
              />
              <p
                className="display-4 text-center font-weight-bold pt-3 mb-3"
                style={{
                  color: `${themeColor.secondary}`,
                  fontWeight: 600,
                  fontSize: '2.67rem',
                }}
              >
                <FaPenNib /> Blogs
              </p>
              <p
                className="h2 font-weight-bold"
                style={{ color: `${themeColor.secondary}`, width: '100vw' }}
              >
                <div
                  className="row"
                  style={{ color: `${themeColor.secondary}`, width: '100%' }}
                >
                  <div className="col-3 text-md-right">
                    <FaQuoteLeft />
                  </div>
                  <div className="col-6 p-0">
                    Beauty Begins The Moment You Decide To Be Yourself
                  </div>
                  <div className="col-3 text-md-left">
                    <FaQuoteRight />
                  </div>
                </div>
              </p>
            </div>
          </div>
          <div className=" text-center bg-light text-dark p-2">
            <div className="row mx-0 py-4 p-1">
              {blogs?.length > 0 &&
                blogs.map((blogs, index) => {
                  return <BlogCard blogs={blogs} key={index} />;
                })}
              {blogs?.length === 0 && (
                <Typography variant="h5" color="primary">
                  No Blogs Available
                </Typography>
              )}
            </div>
          </div>
          {page * pageSize < totalCount && (
            <div className=" text-center bg-light text-dark p-2">
              <div className="row mx-0 py-4 p-1">
                <div
                  item
                  xs={12}
                  style={{ textAlign: 'center', marginBottom: '2rem' }}
                >
                  <Button
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setPage(page + 1);
                    }}
                  >
                    Load More
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Base>
      )}
    </>
  );
};
export default Blog;
