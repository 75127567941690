import React, { Component } from 'react';
import Spinner from './common/Spinner';
import Routes from './Routes';

export default class App extends Component {
  constructor() {
    super();
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return <Spinner />;
    }
    return <Routes />;
  }
}
